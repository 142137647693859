import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getCollectionDocs, uploadFile, createCollectionDoc, updateCollectionDoc, getCollectionDoc, getEventDocs } from "../utils/firebase";
import FormInput from "../components/form-input";
import {Loading, Modal, ModalConfirm} from "../components/modal";
import { UserContext } from "../context/user-context";
import { PackageItem } from "../components/package-item";
import FormTextArea from "../components/form-textarea";
import FormSelect from "../components/form-select";
import { format } from "date-fns";

export const SystemLogs = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("SystemLogs", "created", "asc");
            if (response === "success") {
                for (const res of result) {
                    const temp = [];
                    for (const id of res.events) {
                        const [resp, resu] = await getCollectionDoc("Events", id);
                        if (resp === "success") {
                            const dat = resu.data();
                            dat["id"] = id;
                            temp.push(dat);
                        }
                    }
                    res["data"] = temp;
                }
                setLogs(result);
                return;
            }
        };
        get();
    }, []);

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.SystemLogs}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                        </div>
                    </div>
                    {logs.map((item) => {
                        const {data, created, events, id} = item;
                        return (
                            <div className="columns mb-5" key={id}>
                                <div className="column is-4">
                                    <p className="is-size-5 has-text-weight-bold mb-1">{format(created.toDate(), "MMMM dd, yyyy h:mma")}</p>
                                    <span>Cancelled {events.length} events</span>
                                </div>

                                <div className="column is-8">
                                    {data.map((item, index) => {
                                        const {partner: {businessName}, venue: {name}, end, start, status, type, id} = item;
                                        const title = `${type} at ${businessName}`;

                                        return (
                                            <div key={`event_${index}`} className="columns card px-2 py-3 is-vcentered mb-5">
                                                <div className="column is-10">
                                                    <span className={`tag ${status === "Refunded" ? "with-background-primary has-text-white" : "is-warning"}`}>{status}</span>
                                                    <p className="is-size-5 has-text-weight-bold mt-2">{title}</p>
                                                    <p className="is-size-6 mt-3">Event Hall: {name}</p>
                                                    <p className="is-size-6">Date: {format(start.toDate(), "MMMM dd, yyyy h:mma")} - {format(end.toDate(), "MMMM dd, yyyy h:mma")}</p>
                                                </div>
                                                <div className="column is-2 has-text-right"><Button type="button" additionalClasses="is-pulled-right" onClick={() => navigate("/event/" + id)}>View</Button></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Fragment>
    )
}