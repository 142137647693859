import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getCollectionDocs, uploadFile, createCollectionDoc, updateCollectionDoc, getCollectionDoc } from "../utils/firebase";
import FormInput from "../components/form-input";
import {Loading, Modal, ModalConfirm} from "../components/modal";
import { UserContext } from "../context/user-context";
import { HallItem } from "../components/hall-item";
import FormTextArea from "../components/form-textarea";
import FormSelect from "../components/form-select";
import { where } from "firebase/firestore";

export const Halls = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [halls, setHalls] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Halls", "name", "asc", [where("partnerId", "==", currentUser.uid)]);
            if (response === "success") {
                setHalls(result);
                return;
            }
            setModal(result.message);
        };
        if (currentUser) {
            get();
        }
    }, [currentUser]);

    const onCreateHandler = async() => {
        navigate("/halls/new");
    };

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.EventHall}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            <Button type="button" additionalClasses="is-medium has-text-white with-background-primary" onClick={onCreateHandler}>Create</Button>
                        </div>
                    </div>
                    <div className="columns is-multiline mt-6">
                        {halls.map((item) => {
                            return <HallItem key={item.id} item={item} additionalClasses="column is-desktop is-3" onClickHandler={() => navigate(`/halls/${item.id}`)}/>
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const defaultFormFields = {
    name: '',
    photoUrl: '',
    status: 'Active',
    description: '',
    rate: 0,
    pax: 0,
}

export const HallNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, photoUrl, status, description, rate, pax } = formFields;
    const [modal, setModal] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [imageFileUrl, setImageFileUrl] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDoc("Halls", id);
            if (response === "success") {
                if (result && result.exists) {
                    setFormFields(result.data());
                    return;
                }
    
                setModal("Document does not exist..");
                return;
            }
            setModal(result.message);
        }
        if (id) {
            get();
        }
    }, []);

    useEffect(() => {
        if (imageFile) {
            setImageFileUrl(window.URL.createObjectURL(imageFile));
        }
    }, [imageFile]);

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        const data = {
            ...formFields,
            rate: parseFloat(rate),
            pax: parseInt(pax),
        };
        if (imageFile) {
            const [response, result] = await uploadFile("Halls", imageFile);
            if (response === "success") {
                data["photoUrl"] = result;
            } else {
                setLoading(false);
                setModal(result.message);
                return;
            }
        }

        const [response, result] = id ? await updateCollectionDoc("Halls", id, data) : await createCollectionDoc("Halls", data);
        setLoading(false);
        if (response === "success") {
            navigate("/halls");
            return;
        }
        setModal(result.message);
    }

    const handleFileChange = (event) => {
        const { files } = event.target;
        if (!files[0]) {
            return;
        }

        setImageFile(files[0]);
    }

    const onDeleteHandler = async() => {
        setModalConfirm(false);
        setLoading(true);

        const [response, result] = await updateCollectionDoc("Halls", id, {deleted: true});
        setLoading(false);
        if (response === "success") {
            navigate("/halls");
            return;
        }
        setModal(result.message);
    }

    const onHideHandler = async() => {
        setLoading(true);

        const newStatus = status === "Hidden" ? "Active" : "Hidden";
        const [response, result] = await updateCollectionDoc("Halls", id, {status: newStatus});
        setLoading(false);
        if (response === "success") {
            setFormFields({...formFields, status: newStatus});
            return;
        }
        setModal(result.message);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {loading ? <Loading/> : ""}
            {modalConfirm ? <ModalConfirm message={Enums.DeletePackage} yes="Proceed" no="Cancel" onNoHandler={() => setModalConfirm(false)} onYesHandler={onDeleteHandler} danger={true}/> : ""}
            <div className="column is-12">
                <div className="columns is-vcentered mt-2 mb-6">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{id ? Enums.UpdateEventHall : Enums.NewEventHall}</h1>
                        <span>{status}</span>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        {id ? <Fragment>
                            <Button type="button" additionalClasses="is-medium mr-3" onClick={onHideHandler}>{status === "Hidden" ? Enums.Unhide : Enums.Hide}</Button>
                            <Button type="button" additionalClasses="is-medium is-danger" onClick={() => setModalConfirm(true)}>{Enums.Delete}</Button>
                        </Fragment> : ""}
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='columns'>
                        <div className="column is-4">
                            <figure className="image is-3by4 is-clickable">
                                <img src={imageFile && imageFileUrl ? imageFileUrl : photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"} alt="Image"/>
                                <input className="file-input is-clickable" type="file" onChange={handleFileChange} accept= "image/*"/>
                            </figure>
                        </div>
                        <div className="column is-8">
                            <div className="columns is-multiline">
                                <FormInput type="text" required id="name" value={name} onChange={handleChange} label="Event Hall *" additionalClasses="column is-12"/>
                                <FormInput type="number" min="1" required id="pax" value={pax} onChange={handleChange} label="Maximum Pax *" additionalClasses="column is-6"/>
                                <FormInput type="number" min="0" step=".01" required id="rate" value={rate} onChange={handleChange} label="Rate per Hour *" additionalClasses="column is-6"/>
                                <FormTextArea type="text" required id="description" value={description} onChange={handleChange} label="Description" additionalClasses="column is-12"/>
                                <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block mt-5 mb-6">{id ? Enums.SaveChanges : Enums.Create}</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}