import { Fragment, useEffect, useState } from "react";
import { Enums } from "../utils/enum";
import { createCollectionDoc, getCollectionDoc, getCollectionDocs, updateCollectionDoc } from "../utils/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, Modal, ModalConfirm } from "../components/modal";
import Button from "../components/button";
import FormInput from "../components/form-input";

export const Categories = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Categories", "title", "asc");
            if (response === "success") {
                setCategories(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    const onCreateHandler = () => {
        navigate("/categories/new");
    }

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Categories}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            <Button type="button" additionalClasses="is-medium has-text-white with-background-primary" onClick={onCreateHandler}>Create</Button>
                        </div>
                    </div>
                    <div className="mt-6 columns is-multiline">
                        {categories.map((item) => {
                            const {types, id, title} = item;
                            return (
                                <div className="column is-4 is-desktop is-clickable" key={id} onClick={() => navigate(`/categories/${id}`)}>
                                    <div className="item py-5 px-5">
                                        <p className="is-size-4 has-text-weight-bold mb-4">
                                            {title}
                                        </p>

                                        {types.map((type, index) => {
                                            return <div key={`${id}_${index}`} className="py-3">{type}</div>;
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const defaultFormFields = {
    title: '',
    types: [''],
}
export const CategoryNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { title, types } = formFields;
    const [modal, setModal] = useState("");
    const [modalConfirm, setModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDoc("Categories", id);
            if (response === "success") {
                if (result && result.exists) {
                    setFormFields(result.data());
                    return;
                }
    
                setModal("Document does not exist..");
                return;
            }
            setModal(result.message);
        }
        if (id) {
            get();
        }
    }, []);

    const handleTitleChange = (event) => {
        const { value } = event.target;

        setFormFields({...formFields, title: value});
    }

    const handleTypeChange = (event, index) => {
        const { value } = event.target;
        types[index] = value;

        setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const [response, result] = id ? await updateCollectionDoc("Categories", id, formFields) : await createCollectionDoc("Categories", formFields);
        setLoading(false);
        if (response === "success") {
            navigate("/categories");
            return;
        }
        setModal(result.message);
    }

    const onAddHandler = () => {
        types.push("");
        setFormFields({...formFields});
    }

    const onDeleteHandler = async() => {
        setModalConfirm(false);
        setLoading(true);

        const [response, result] = await updateCollectionDoc("Categories", id, {deleted: true});
        setLoading(false);
        if (response === "success") {
            navigate("/categories");
            return;
        }
        setModal(result.message);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {loading ? <Loading/> : ""}
            {modalConfirm ? <ModalConfirm message={Enums.DeleteCategory} yes="Proceed" no="Cancel" onNoHandler={() => setModalConfirm(false)} onYesHandler={onDeleteHandler} danger={true}/> : ""}
            <div className="column is-12">
                <div className="columns is-vcentered mt-2 mb-6">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{id ? Enums.UpdateCategory : Enums.NewCategory}</h1>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        {id ? <Button type="button" additionalClasses="is-medium is-danger" onClick={() => setModalConfirm(true)}>{Enums.Delete}</Button> : ""}
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='columns is-multiline fix-gap'>
                        <FormInput type="text" required value={title} onChange={handleTitleChange} label="Title *" additionalClasses="column is-12"/>
                        {types.map((type, index) => {
                            return <FormInput key={index} type="text" onRemoveHandler={() => {types.splice(index, 1); setFormFields({...formFields})}} required value={type} onChange={(e) => handleTypeChange(e, index)} label="Type *" additionalClasses="column is-6"/>
                        })}
                        <div className="column is-6" style={{marginTop: 21 +"px"}}><Button type="button" additionalClasses="is-fullwidth is-medium" onClick={onAddHandler}>{Enums.AddType}</Button></div>
                    </div>
                        <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block mt-5 mb-6">{id ? Enums.SaveChanges : Enums.Create}</Button>
                </form>
            </div>
        </div>
    )
}