import { Fragment, useEffect, useState } from 'react';
import { rateFormatter } from '../utils/utils';

export const PackageItem = ({item, additionalClasses, onClickHandler, showStatus = true, hours = 0, ellipsize = false}) => {
    const { photoUrl, name, status, description, rate, price, pax, business, hall, type, category} = item;
    let addition = 0;
    if (hours > 4) {
        addition = (hours - 4) * rate;
    }

    return (
        <div className={`${additionalClasses}`}>
            <div className='columns card px-2 py-3 is-clickable' onClick={onClickHandler}>
                <div className='column is-3-desktop'>
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                </div>
                <div className='column is-9-desktop'>
                    {showStatus ? <Fragment>
                        <span className="tag is-success is-light mb-3 mr-3">{status}</span>
                        <span className="tag is-success is-light mb-3 mr-3">{category}</span>
                        <span className="tag is-success is-light mb-3 mr-3">{type}</span>
                    </Fragment> : ""}
                    <span className="tag is-success is-light mb-3 mr-3">{business}</span>
                    <span className="tag is-success is-light mb-3 mr-3">{hall}</span>
                    <span className="tag is-success is-light mb-3">Max {pax} Pax</span>
                    <p className="is-size-3 mb-4 has-text-weight-bold ">{name}</p>
                    <p className="is-size-5 mb-2">P {rateFormatter(price + addition)}</p>
                    <p className="is-size-6">{hours == 0 ? `Additional P ${rateFormatter(rate)}/hour when event exceeds 4 hours.` : ""}</p>
                    <p className={`is-size-6 my-4 ${ellipsize ? "v-ellipsize" : ""}`} style={{whiteSpace: "pre-line"}}>{description}</p>
                </div>
            </div>
        </div>
    )
}