import { resetPassword } from '../utils/firebase';
import { useState } from 'react';
import FormInput from '../components/form-input';
import Button from '../components/button';
import { Enums } from '../utils/enum'
import {Modal} from '../components/modal';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [modal, setModal] = useState("");

    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        await resetPassword(email);
        setEmail("");
        setModal("Password reset link successfully sent.");
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="column is-offset-4-desktop is-4-desktop">
                <h2 className='is-size-2 has-text-weight-bold mt-6 is-uppercase'>{Enums.ForgotPassword}</h2>
                <p className='block mb-6'>{Enums.ForgotPasswordText}</p>
                <form onSubmit={handleSubmit}>
                    <FormInput type="email" required id="email" value={email} onChange={handleChange} label="Email Address" additionalClasses="block"/>
                    <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block">{Enums.SubmitButtonText}</Button>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;