import { rateFormatter } from '../utils/utils';

export const HostItem = ({item, additionalClasses, onClickHandler, showStatus = true, hours = 1}) => {
    const { photoUrl, name, rate, status } = item;

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler}>
            <article className="media is-align-content-center item">
                <div className="media-content">
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                    <div className="content is-clipped pt-5 pb-5 px-5">
                        {showStatus ? <span className="tag is-success is-light mb-3">{status}</span> : ""}
                        <p className="has-text-weight-bold is-size-4 mb-2">{name}</p>
                        <p className="is-size-6">Php {rateFormatter(rate * hours)}</p>
                    </div>
                </div>
            </article>
        </div>
    )
}