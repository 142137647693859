import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getUserDocs, createPartnerDoc, createPartner, deletePartner } from "../utils/firebase";
import { AccountItem } from "../components/account-item";
import FormInput from "../components/form-input";
import {Loading, Modal} from "../components/modal";

export const Partners = () => {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        async function get() {
            const docs = await getUserDocs("partner");
            setAccounts(docs);
        };
        get();
    }, []);

    const onClickHandler = async() => {
        navigate("/partners/new");
    };

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <div className="columns is-vcentered mt-2 mb-6">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Partners}</h1>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        <Button type="button" additionalClasses="is-medium has-text-white with-background-primary" onClick={onClickHandler}>Create</Button>
                    </div>
                </div>
                <div className="columns is-multiline mt-6">
                    {accounts.map((account) => {
                            return <AccountItem key={account.id} account={account} additionalClasses="column is-desktop is-6" onClickHandler={() => navigate("/partners/" + account.id)}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const defaultFormFields = {
    emailAddress: '',
    password: '',
    type: 'partner',
    businessName: '',
    deleted: false,
}
export const PartnerNew = () => {
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { emailAddress, password, businessName} = formFields;
    const [modal, setModal] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password.length < 6) {
            setModal("Password should be atleast 6 characters");
            return;
        }

        setLoading(true);
        try {
            const {data: [response, result]} = await createPartner({
                email: emailAddress,
                password: password,
                displayName: businessName,
                disabled: false,
                emailVerified: false,
            });
            if (response === "error") {
                setLoading(false);
                setModal(result.message);
                return;
            }

            const data = {
                ...formFields,
                id: result.uid,
            };
            delete data.password;
            delete data.confirmPassword;
            const {data: [response2, result2]} = await createPartnerDoc(data);

            if (response2 === "error") {
                await deletePartner({id: result.uid});
                setLoading(false);
                setModal(result.message);
                return;
            }

            setLoading(false);
            navigate('/partners');
        } catch (error) {
            setLoading(false);
            if (error.code == "auth/email-already-in-use") {
                setModal("Email address already in use");
                return;
            }

            setModal("signup error:", error.message);
        }
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {loading ? <Loading/> : ""}
            <div className="column is-12">
                <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2 mb-6">{Enums.NewPartner}</h1>
                <form onSubmit={handleSubmit}>
                    <div className='columns is-multiline fix-gap'>
                        <FormInput required type="text" id="businessName" value={businessName} onChange={handleChange} label="Business Name *" additionalClasses="column is-12"/>
                        <FormInput required type="email" id="emailAddress" value={emailAddress} onChange={handleChange} label="Email Address *" additionalClasses="column is-6"/>
                        <FormInput required type={show ? "text": "password"} id="password" value={password} onChange={handleChange} label="Password *" additionalClasses="column is-6"/>
                        <div className="column is-6"/>
                        <div className="columns is-6">
                            <div className="group is-inline-block ml-4" onClick={() => setShow(!show)}>
                                <input type="checkbox" checked={show} name="checkbox"/>
                                <label className='ml-2'>Show Password</label>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block mt-5">{Enums.Create}</Button>
                </form>
            </div>
        </div>
    )
}