import { Fragment, useContext, useEffect, useState } from "react";
import { Enums } from "../utils/enum";
import { UserContext } from "../context/user-context";

export const Unauthorized = () => {
    const { currentUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);

    const callback = async() => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
    };

    useEffect(() => {
        callback();
    }, []);
    return (

        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered" style={{minHeight: currentUser ? "100vh" : `calc(100vh - 310px)`}}>
            <div className="column is-6 has-text-centered">
                {isLoading ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> :
                <Fragment>
                    <h1 className="is-size-2 has-large-text has-text-weight-bold with-text-primary">{Enums.Unauthorized}</h1>
                    <p className="is-size-4">{Enums.UnauthorizedText}</p>
                </Fragment>}
            </div>
        </div>
    )
}