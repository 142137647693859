import logo from '../logo.png';
import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Enums } from "../utils/enum"

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    document.body.parentElement.classList.remove("is-clipped");
  }, []);

  const onMenuToggle = () => {
    setToggle(!toggle);
  }

  const onNavigate = (link) => {
    navigate(link);
    setToggle(false);
  }

  return (
    <Fragment>
      <div style={{minHeight: `calc(100vh - 168px)`}}>
        <nav className="navbar mt-3 px-5 mb-6" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" onClick={() => onNavigate("/")}>
              {/* <img src={logo} className='mr-6'/> */}
              <div>
                <h1 className="with-text-primary"><span className="is-size-1 is-uppercase has-text-weight-bold">{Enums.AppName}</span>
                <span className='is-size-7 is-uppercase is-pulled-right mt-4 ml-3' style={{lineHeight: 14 + "px"}}>Event<br/>Management<br/>System<br/></span>
                </h1>
              </div>
            </a>
            <a onClick={onMenuToggle} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className={`navbar-menu ${toggle ? "is-active" : ""}`}>
              <div className="navbar-end">
                <a className={`navbar-item hoverable is-uppercase has-text-weight-medium ${pathname === "/" ? "active" : ""}`} onClick={() => onNavigate("/")}>Home</a>
                <a className={`navbar-item hoverable is-uppercase has-text-weight-medium ${pathname === "/gallery" ? "active" : ""}`} onClick={() => onNavigate("/gallery")}>Gallery</a>
                <a className={`navbar-item hoverable is-uppercase has-text-weight-medium ${pathname === "/services" ? "active" : ""}`} onClick={() => onNavigate("/services")}>Services</a>
                <a className={`navbar-item hoverable is-uppercase has-text-weight-medium ${pathname === "/contact" ? "active" : ""}`} onClick={() => onNavigate("/contact")}>Contact</a>
                <div className="navbar-item">
                  <div className="buttons">
                    <a className="button with-background-primary has-text-white is-uppercase has-text-weight-medium" onClick={() => onNavigate(currentUser ? "/dashboard" : "/signin")}>{currentUser ? Enums.Dashboard : Enums.SignIn}</a>
                  </div>
              </div>
            </div>
          </div>
        </nav>
        <Outlet/>
      </div>

      <footer className="mt-6 footer with-background-primary has-text-white px-6 py-5">
        <div className="columns py-2">
            <div className="column is-6 has-text-centered-touch">
              All rights reserved.
            </div>
            <div className="column has-text-centered-touch has-text-right-desktop">
              Visit us on : Facebook | Youtube | Tiktok
            </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Navigation;
