import { Fragment, useState } from 'react';
import Button from '../components/button';
import FormInput from '../components/form-input';
import { Enums } from '../utils/enum';
import { updateUserPassword } from '../utils/firebase';

const defaultFormFields = {
    password: '',
    confirmPassword: '',
}

export const ChangePassword = () => {
    const [show, setShow] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {password, confirmPassword} = formFields;
    const [modalCreate, setModalCreate] = useState("");
    const [modal, setModal] = useState("");

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("Password do not match");
        } else {
            setModalCreate("loading");
            const res = await updateUserPassword(password);
            setModalCreate("");

            if (res === "success") {
                resetFormFields();
                setModal("You have successfully updated your password.");
            } else {
                if (res === "Firebase: Error (auth/requires-recent-login).") {
                    setModal("For safety and security, you must first re-login before you can change your password.");
                } else {
                    setModal(res);
                }
            }
        }
    }

    return (
        <Fragment>
            {modal !== "" ? <div className="modal custom-modal has-text-centered is-active">
                <div className="modal-background" onClick={() => setModal("")}></div>
                <div className="modal-content">
                    <header className="modal-card-head pt-6 is-borderless">
                        <p className="modal-card-title">{modal}</p>
                    </header>
                    <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    </footer>
                </div>
            </div> : ""}

            {modalCreate !== "" ? <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
                <div className="modal-content" style={{width: "400px"}}>
                    <header className="modal-card-head pt-6 is-borderless px-6">
                    </header>
                    <section className="modal-card-body px-6 has-text-centered">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </section>
                    <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                    </footer>
                </div>
            </div> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.ChangePassword}</h1>
                    
                    <form onSubmit={handleSubmit} className='mt-6'>
                        <FormInput type={show ? "text": "password"} required name="password" value={password} onChange={handleChange} label="New Password" additionalClasses="block"/>
                        <FormInput type={show ? "text": "password"} required name="confirmPassword" value={confirmPassword} onChange={handleChange} label="Confirm Password"/>
                        <div className="group is-inline-block mt-3" onClick={() => setShow(!show)}>
                            <input type="checkbox" checked={show} name="checkbox"/>
                            <label className='ml-2'>Show Password</label>
                        </div>
                        <div className="mt-5 has-text-right">
                            <Button type="submit" className="with-background-primary has-text-white button is-medium">{Enums.SaveChanges}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}