import { useState } from 'react';
import FormInput from '../components/form-input';
import Button from '../components/button';
import FormSelect from '../components/form-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { createAuthUserWithEmailAndPassword, createUserDocument } from '../utils/firebase';
import {Modal} from '../components/modal';
import { Enums } from '../utils/enum';

const defaultFormFields = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    type: 'client',
    deleted: false,
    address: '',
}

const SignUp = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { firstName, lastName, emailAddress, password, confirmPassword, type, address} = formFields;
    const [modal, setModal] = useState("");

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setModal("Password mismatch");
            return;
        } else if (password.length < 6) {
            setModal("Password should be atleast 6 characters");
            return;
        }

        try {
            delete formFields.confirmPassword;
                    
            const { user } = await createAuthUserWithEmailAndPassword(emailAddress, password);
            await createUserDocument(user, {...formFields});

            if (state) {
                onNavigate("/overview");
                return;
            }
            onNavigate('/dashboard');
        } catch (error) {
            if (error.code == "auth/email-already-in-use") {
                setModal("Email address already in use");
                return;
            }

            setModal("signup error:", error.message);
        }
    }

    const onNavigate = (path) => {
        if (state) {
            navigate(path, {replace: true, state: {...state}});
            return;
        }
        navigate(path);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-centered">
        {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="column is-4-desktop">
                <h2 className='is-size-2 has-text-weight-bold mt-6 is-uppercase'>{Enums.SignUp}</h2>
                <p className='block mb-5 pb-3'>{Enums.SignUpText}</p>
                <form onSubmit={handleSubmit}>
                    <div className='columns is-multiline fix-gap'>
                        <FormInput type="text" required id="firstName" value={firstName} onChange={handleChange} label="First Name *" additionalClasses="column is-6"/>
                        <FormInput type="text" required id="lastName" value={lastName} onChange={handleChange} label="Last Name *" additionalClasses="column is-6"/>
                        <FormInput required type="email" id="emailAddress" value={emailAddress} onChange={handleChange} label="Email Address *" additionalClasses="column is-12"/>
                        <FormInput required type="text" id="address" value={address} onChange={handleChange} label="Address *" additionalClasses="column is-12"/>
                        <FormInput required type="password" id="password" value={password} onChange={handleChange} label="Password *" additionalClasses="column is-6"/>
                        <FormInput required type="password" id="confirmPassword" value={confirmPassword} onChange={handleChange} label="Confirm Password *" additionalClasses="column is-6"/>
                    </div>
                    <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block mt-3">{Enums.SignUpButtonText}</Button>
                </form>
                <p className='block mt-6 has-text-centered'>Already have an account? <a onClick={() => {onNavigate("/signin")}}>{Enums.SignIn}.</a></p>
            </div>
        </div>

    
    )
}

export default SignUp;