import { useContext, Fragment, useState, useEffect } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import FormInput from '../components/form-input';
import { updateUserDocument, uploadFile } from '../utils/firebase';

const defaultFormFields = {
    firstName: '',
    lastName: '',
    photoUrl: '',
    businessName: '',
    type: '',
    address: '',
    contact: '',
    videoName: '',
    videoUrl: '',
    rate: 0,
}

export const Profile = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const {firstName, lastName, emailAddress, photoUrl, businessName, type} = currentUser ? currentUser.data : defaultFormFields;
    const isAdmin = type === "admin";
    const isClient = type === "client";

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.Profile}</h1>
                <div className="columns is-centered">
                    <div className="column is-3">
                        <figure className="image mt-6 is-rounded is-128x128" style={{marginRight: "auto", marginLeft: "auto"}}>
                            <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                        </figure>
                    </div>
                </div>
                <h1 className="has-text-centered is-size-4 has-large-text has-text-weight-bold mt-4">{isAdmin || isClient ? `${firstName} ${lastName}` : businessName}</h1>
                <p className="has-text-centered is-size-5 mt-2">{emailAddress}</p>

                <div className="has-text-centered mt-5 mb-6">
                    <Button onClick={() => navigate("/profile/update-profile")} type="button" additionalClasses="is-medium">
                        {Enums.UpdateProfile}
                    </Button>
                    <Button onClick={() => navigate("/profile/change-password")} type="button" additionalClasses="is-medium ml-3 is-danger">
                        {Enums.ChangePassword}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const ProfileUpdate = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState(currentUser ? currentUser.data : defaultFormFields);
    const {firstName, lastName, photoUrl, businessName, type, contact, address, videoName, videoUrl} = formFields;
    const [modalCreate, setModalCreate] = useState("");
    const [modal, setModal] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const isAdmin = type === "admin";
    const isClient = type === "client";
    const [imageFileUrl, setImageFileUrl] = useState("");
    const [videoFileUrl, setVideoFileUrl] = useState("");

    useEffect(() => {
        if (imageFile) {
            setImageFileUrl(window.URL.createObjectURL(imageFile));
        }
    }, [imageFile]);

    useEffect(() => {
        if (videoFile) {
            setVideoFileUrl(window.URL.createObjectURL(videoFile));
            setFormFields({...formFields, videoName: videoFile.name});
        }
    }, [videoFile]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!imageFile && !videoFile &&
            currentUser.data.firstName === firstName &&
            currentUser.data.lastName === lastName &&
            currentUser.data.businessName === businessName &&
            currentUser.data.address === address &&
            currentUser.data.contact === contact) {
                setModal("No changes to save..");
            return;
        }

        setModalCreate("loading");
        let data;

        if (isAdmin || isClient) {
            data = {firstName, lastName, address};
        } else {
            data = {businessName, contact, address};
        }
        if (imageFile) {
            const [response, result] = await uploadFile(`Users/${currentUser.uid}`, imageFile);
            if (response === "success") {
                data["photoUrl"] = result;
            } else {
                setModalCreate("");
                alert(result);
                return;
            }
        }

        if (videoFile) {
            const [response, result] = await uploadFile(`Users/${currentUser.uid}`, videoFile);
            if (response === "success") {
                data["videoUrl"] = result;
                data["videoName"] = videoName;
            } else {
                setModalCreate("");
                alert(result);
                return;
            }
        }

        const res = await updateUserDocument(currentUser.uid, data);
        if (res === "success") {
            setModalCreate("");
            setModal("You have succesfully updated your profile");
            setCurrentUser({...currentUser, data: {...currentUser.data, ...data}});
        } else {
            setModal(res);
        }
    }

    const handleFileChange = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }

        console.log(id);
        if (id === "video") {
            setVideoFile(files[0]);
            return;
        }
        setImageFile(files[0]);
    }

    return (
        <Fragment>
            {modal !== "" ? <div className="modal custom-modal has-text-centered is-active">
                <div className="modal-background" onClick={() => setModal("")}></div>
                <div className="modal-content">
                    <header className="modal-card-head pt-6 is-borderless">
                        <p className="modal-card-title">{modal}</p>
                    </header>
                    <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    </footer>
                </div>
            </div> : ""}

            {modalCreate !== "" ? <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
                <div className="modal-content" style={{width: "400px"}}>
                    <header className="modal-card-head pt-6 is-borderless px-6">
                    </header>
                    <section className="modal-card-body px-6 has-text-centered">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </section>
                    <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                    </footer>
                </div>
            </div> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.UpdateProfile}</h1>
                    <div className="columns is-centered">
                        <div className="column is-3">
                            <figure className="image mt-6 is-rounded is-128x128">
                                <img src={imageFile && imageFileUrl ? imageFileUrl : photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                <input className="file-input is-clickable" type="file" onChange={handleFileChange} accept="image/*"/>
                            </figure>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className='mt-6 columns is-multiline'>
                        {isAdmin || isClient ? <Fragment>
                            <FormInput type="text" required name="firstName" value={firstName} onChange={handleChange} label="First Name" additionalClasses="column is-6"/>
                            <FormInput type="text" required name="lastName" value={lastName} onChange={handleChange} label="Last Name" additionalClasses="column is-6"/>
                            {isClient ? <FormInput type="text" required name="address" value={address} onChange={handleChange} label="Address" additionalClasses="column is-12"/>
                            : ""}
                        </Fragment> : <Fragment>
                            <FormInput type="text" required name="businessName" value={businessName} onChange={handleChange} label="Business Name" additionalClasses="column is-12"/>
                            <FormInput type="text" required name="address" value={address} onChange={handleChange} label="Address" additionalClasses="column is-6"/>
                            <FormInput type="text" required name="contact" value={contact} onChange={handleChange} label="Contact" additionalClasses="column is-6"/>
                            <div className="column is-12 mt-2">
                                <label>Promotional Video</label>
                                <div className="file has-name is-fullwidth">
                                    <label className="file-label">
                                        <input className="file-input input is-medium" id="video" type="file" onChange={handleFileChange} accept="video/*"/>
                                        <span className="file-cta">
                                        <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                        </span>
                                        <span className="file-label">
                                            Choose a file…
                                        </span>
                                        </span>
                                        <span className="file-name">
                                            {videoName ? videoName : "No File Selected"}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </Fragment>}
                        <div className="mt-5 has-text-right column is-12 mb-6">
                            <Button type="submit" className="with-background-primary has-text-white button is-medium">{Enums.SaveChanges}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}