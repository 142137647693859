import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { createPartnerDoc, createPartner, deletePartner, getCollectionDocs, uploadFile, createCollectionDoc, updateCollectionDoc, getCollectionDoc, deleteCollectionDoc, getInvoices, getRefunded } from "../utils/firebase";
import { SlideshowItem } from "../components/slideshow-item";
import FormInput from "../components/form-input";
import {Loading, Modal, ModalConfirm} from "../components/modal";
import FormSelect from "../components/form-select";
import { format } from "date-fns";
import { rateFormatter } from "../utils/utils";
import { Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend, 
    CategoryScale,
    LinearScale,
    BarElement,
);

const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthData = {
    labels : monthLabels,
    datasets: [
      {
        label: ' Sales',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

export const Sales = () => {
    const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const [modal, setModal] = useState("");
    const [bookingData, setBookingData] = useState(monthData);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState("");

    useEffect(() => {
        const now = new Date();
        const cur = now.getFullYear();
        const temp = [];
        for (let i = 2023; i <= cur + 1; i++) {
            temp.push(i);
        }
        setYears(temp);
        setYear(cur.toString());
    }, []);

    useEffect(() => {
        async function get() {
            const res = await getInvoices(year);
            const result = await getRefunded(year);
            setSales([...res, ...result]);
        };
        if (year !== "") {
            get();
        }
    }, [year]);

    useEffect(() => {
        const booData = [];

        for (const month of monthLabels) {
            const tempSales = sales.filter((sale) => sale.created.toDate().toString().includes(month));
            let total = 0;
            for (const temp of tempSales) {
                total += temp.amount;
            }
            booData.push(total);
        }
    
        setBookingData({
            labels : monthLabels,
            datasets: [
              {
                label: ' Sales',
                data: booData,
                backgroundColor: 'rgba(136, 225, 58, 1)',
              },
            ],
          });
    }, [sales]);

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Sales}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            <FormSelect options={years} type="number" required id="year" value={year} onChange={(e) => setYear(e.target.value)}/>
                        </div>
                    </div>
                    <div className="column is-12">
                            <Bar data={bookingData} />
                        </div>
                        
                        <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.Sales}</h2></div>
                        {sales.map((sale, index) => {
                            const {eventId, paymentMethod, payerEmail, description, amount, created} = sale;
                                return  (
                                    <div className="columns card is-multiline px-5 mb-5 py-5 is-vcentered" key={index}>
                                        <div className="column is-3">{format(created.toDate(), "MMMM dd, yyyy h:mma")}</div>
                                        <div className="column is-5"><p className="has-text-weight-bold is-size-4">{description}</p>{payerEmail}</div>
                                        <div className="column is-2"><p className="has-text-weight-bold is-size-4">P {rateFormatter(amount)}</p>{paymentMethod}</div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile"><Button type="button" onClick={() => navigate("/event/" + eventId)}>View Event</Button></div>
                                    </div>
                                )
                            })
                        }
                </div>
            </div>
        </Fragment>
    )
}