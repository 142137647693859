import { rateFormatter } from "../utils/utils";

export const VenueItem = ({item, additionalClasses, onClickHandler}) => {
    const { photoUrl, businessName, address, rate } = item;

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler}>
            <article className="media is-align-content-center item">
                <div className="media-content">
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                    <div className="content is-clipped pt-5 pb-5 px-5">
                        <p className="has-text-weight-bold is-size-4 mb-2">{businessName}</p>
                        {/* <p className="is-size-5">P {rateFormatter(rate)} / hour</p> */}
                        <p className="is-size-6">{address}</p>
                    </div>
                </div>
            </article>
        </div>
    )
}