
const FormInput = ({ onRemoveHandler, label, additionalClasses, error, ...otherProps }) => {
    return (
        <div className={`input-container ${additionalClasses ? additionalClasses : ""}`}>
        {label && (
            <label>{label}</label>
        )}
        <input {...otherProps} className={`input is-medium ${error ? "is-danger" : ""}`}/>
        {error && (
            <label className="has-text-danger">{error}</label>
        )}
        {onRemoveHandler ? <button type="button" className="delete is-medium" onClick={onRemoveHandler}/> : ""}
        </div>
    );
}

export default FormInput;