import './App.css';
import 'bulma/css/bulma.min.css';
import { useContext, useEffect } from 'react';
import { UserContext } from './context/user-context';
import { Routes, Route, useLocation } from "react-router-dom";
import Navigation from './routes/navigation';
import {Home, Gallery, Services, Contact} from './routes/home';
import SignIn from './routes/sign-in';
import ForgotPassword from './routes/forgot-password';
import SignUp from './routes/sign-up';
import SideNavigation from './routes/side-navigation';
import { Dashboard } from './routes/dashboard';
import { Browse, BrowseVenue } from './routes/browse-page';
import { Events } from './routes/events';
import { Event } from './routes/event';
import { Reserve, StepType, StepCategory, StepVenue, StepPackage, StepDate, StepTime, StepOptions, StepHost, StepOverview } from './routes/reserve';
import { Categories, CategoryNew } from './routes/categories-page';
import { Unauthorized } from './routes/unauthorized';
import { EventCreation } from './routes/event-creation';
import { Profile, ProfileUpdate } from './routes/profile-page';
import { ChangePassword } from './routes/change-password';
import { Clients, ClientProfile } from './routes/clients-page';
import { PartnerNew, Partners } from './routes/partners-page';
import { HostNew, Hosts } from './routes/hosts-page';
import { Calendar } from './routes/calendar-page';
import { PackageNew, Packages } from './routes/packages-page';
import { Analytics } from './routes/analytics-page';
import { Catering, CateringNew } from './routes/catering-page';
import { SlideShowNew, Slideshows } from './routes/slideshow-page';
import { HallNew, Halls } from './routes/halls-page';
import 'bulma-tooltip'
import { Sales } from './routes/sales-page';
import { SystemLogs } from './routes/system-logs-page';
import { Reports } from './routes/reports-page';

function App() {
  const { currentUser } = useContext(UserContext);
  const isAdmin = currentUser && currentUser.data.type === "admin";
  const isClient = currentUser && currentUser.data.type === "client";
  const isPartner = currentUser && currentUser.data.type === "partner";
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={<Home/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/signup" element={currentUser ? "" : <SignUp/>}/>
        <Route path="/signin" element={currentUser ? "" : <SignIn/>}/>
        <Route path="/forgot-password" element={currentUser ? "" : <ForgotPassword/>}/>

        <Route path="/reserve" element={isAdmin || isPartner ? <Unauthorized/> : <StepCategory/>}/>
        <Route path="/type"  element={<StepType/>}/>
        <Route path="/venue"  element={<StepVenue/>}/>
        <Route path="/date"  element={<StepDate/>}/>
        <Route path="/time"  element={<StepTime/>}/>
        <Route path="/food"  element={<StepPackage/>}/>
        <Route path="/custom"  element={<StepOptions/>}/>
        <Route path="/host"  element={<StepHost/>}/>
        <Route path="/overview"  element={<StepOverview/>}/>
      </Route>

      <Route path="/browse" element={<Navigation/>}>
        <Route index element={<Browse/>}/>
        <Route path="/browse/:id" element={<BrowseVenue/>}/>
      </Route>

      <Route path="/sales" element={<SideNavigation/>}>
        <Route index element={isAdmin ? <Sales/> : <Unauthorized/>}/>
      </Route>

      <Route path="/system-logs" element={<SideNavigation/>}>
        <Route index element={isAdmin ? <SystemLogs/> : <Unauthorized/>}/>
      </Route>

      <Route path="/reports" element={<SideNavigation/>}>
        <Route index element={isAdmin ? <Reports/> : <Unauthorized/>}/>
      </Route>

      <Route path="/dashboard" element={currentUser ? <SideNavigation/> : <Navigation/>}>
        <Route index element={currentUser ? <Dashboard/> : <Unauthorized/>}/>
        <Route path="/dashboard/events" element={isAdmin ? <Events/> : <Unauthorized/>}/>
        <Route path="/dashboard/events/new" element={isAdmin ? <EventCreation/> : <Unauthorized/>}/>
      </Route>

      <Route path="/profile" element={currentUser ? <SideNavigation/> : <Navigation/>}>
        <Route index element={currentUser ? <Profile/> : <Unauthorized/>}/>
        <Route path="/profile/change-password" element={currentUser ? <ChangePassword/> : <Unauthorized/>}/>
        <Route path="/profile/update-profile" element={currentUser ? <ProfileUpdate/> : <Unauthorized/>}/>
      </Route>

      <Route path="/clients" element={currentUser ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Clients/> : <Unauthorized/>}/>
        <Route path="/clients/:userId" element={currentUser ? <ClientProfile/> : <Unauthorized/>}/>
      </Route>

      <Route path="/partners" element={isAdmin ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Partners/> : <Unauthorized/>}/>
        <Route path="/partners/new" element={isAdmin ? <PartnerNew/> : <Unauthorized/>}/>
        <Route path="/partners/:userId" element={isAdmin ? <ClientProfile/> : <Unauthorized/>}/>
      </Route>

      <Route path="/hosts" element={isAdmin ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Hosts/> : <Unauthorized/>}/>
        <Route path="/hosts/new" element={isAdmin ? <HostNew/> : <Unauthorized/>}/>
        <Route path="/hosts/:id" element={isAdmin ? <HostNew/> : <Unauthorized/>}/>
      </Route>

      <Route path="/packages" element={isAdmin ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Packages/> : <Unauthorized/>}/>
        <Route path="/packages/new" element={isAdmin ? <PackageNew/> : <Unauthorized/>}/>
        <Route path="/packages/:id" element={isAdmin ? <PackageNew/> : <Unauthorized/>}/>
      </Route>

      <Route path="/slideshow" element={isAdmin ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Slideshows/> : <Unauthorized/>}/>
        <Route path="/slideshow/new" element={isAdmin ? <SlideShowNew/> : <Unauthorized/>}/>
        <Route path="/slideshow/:id" element={isAdmin ? <SlideShowNew/> : <Unauthorized/>}/>
      </Route>

      <Route path="/catering" element={isPartner ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isPartner ? <Catering/> : <Unauthorized/>}/>
        <Route path="/catering/new" element={isPartner ? <CateringNew/> : <Unauthorized/>}/>
        <Route path="/catering/:id" element={isPartner ? <CateringNew/> : <Unauthorized/>}/>
      </Route>

      <Route path="/halls" element={isPartner ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isPartner ? <Halls/> : <Unauthorized/>}/>
        <Route path="/halls/new" element={isPartner ? <HallNew/> : <Unauthorized/>}/>
        <Route path="/halls/:id" element={isPartner ? <HallNew/> : <Unauthorized/>}/>
      </Route>


      <Route path="/categories" element={isAdmin ? <SideNavigation/> : <Navigation/>}>
        <Route index element={isAdmin ? <Categories/> : <Unauthorized/>}/>
        <Route path="/categories/new" element={isAdmin ? <CategoryNew/> : <Unauthorized/>}/>
        <Route path="/categories/:id" element={isAdmin ? <CategoryNew/> : <Unauthorized/>}/>
      </Route>

      <Route path="/calendar" element={!isClient ? <SideNavigation/> : <Navigation/>}>
        <Route index element={!isClient ? <Calendar/> : <Unauthorized/>}/>
      </Route>

      <Route path="/analytics" element={!isClient ? <SideNavigation/> : <Navigation/>}>
        <Route index element={!isClient ? <Analytics/> : <Unauthorized/>}/>
      </Route>

      <Route path="/event" element={currentUser ? <SideNavigation/> : <Navigation/>}>
        <Route path="/event/:id" element={currentUser ? <Event/> : <Unauthorized/>}/>
      </Route>
    </Routes>
  );
}

export default App;
