export const Enums = {
    AppName: "ALM",

    // home
    HomeTitle: "Make your Dream Party, a REALITY!",
    GalleryTitle: "Memories you can make.",
    ServicesTitle: "Unforgettable events tailored by YOU!",
    ServicesText: "We offer customizable event packages to suit your needs.",
    ContactTitle: "Contact us!",
    ContactText: "Feel free to send us a message or suggestions.",
    ReserveText: "Reserve your slot in just few easy steps.",
    ReserveButtonText: "Reserve Now",
    Reserve: "Reserve",
    SendButtonText: "Send",

    // sign-in, signup
    SignIn: "Sign In",
    SignInButtonText: "Sign in",
    SignInText: "Type in your email address & password",
    ForgotPassword: "Forgot Password",
    ForgotPasswordButtonText: "Forgot password?",
    ForgotPasswordText: "Type in your email address below to receive your password reset link.",
    SubmitButtonText: "Submit",
    SignUp: "Sign Up",
    SignUpText: "Fill in all required fields (*)",
    SignUpButtonText: "Sign up",

    // dashboard
    Unauthorized: "Unauthorized",
    UnauthorizedText: "You are not authorized to view this page.",
    Browse: "Browse",
    Homepage: "Homepage",
    Dashboard: "Dashboard",
    Analytics: "Analytics",
    Slideshow: "Slideshow",
    Sales: "Sales",
    SystemLogs: "System Logs",
    Reports: "Reports",
    Packages: "Packages",
    EventHall: "Event Halls",
    NewEventHall: "New Event Hall",
    UpdateEventHall: "Update Event Hall",
    Catering: "Catering",
    NewMenu: "New Menu",
    UpdateMenu: "Update Menu",
    NewPackage: "New Package",
    UpdatePackage: "Update Package",
    Account: "Account",
    ChangePassword: "Change Password",
    SubmitPricing: "Submit Pricing",
    BanUser: "Ban User",
    UnbanUser: "Unban User",
    Profile: "Profile",
    UpdateProfile: "Update Profile",
    AccountManagement: "Account Management",
    EventManagement: "Event Management",
    ReportMonitoring: "Report Monitoring",
    Notifications: "Notifications",
    Events: "Events",
    SignOut: "Sign Out",
    AddButtonText: "Add",
    ManageButtonText: "Manage",
    EventPackage: "Event Package",
    CreateEventButtonText: "New Event",
    BookEventButtonText: "Book an Event",
    SaveChanges: "Save Changes",
    Categories: "Categories",
    Types: "Types",
    Calendar: "Calendar",
    Clients: "Clients",
    Partners: "Partners",
    General: "General",
    NewPartner: "New Partner",
    Create: "Create",
    Hosts: "Hosts",
    NewHost: "New Host",
    UpdateHost: "Update Host",
    NewSlideshow: "New Slideshow",
    UpdateSlideshow: "Update Slideshow",
    AddType: "Add Type",
    NewCategory: "New Category",
    UpdateCategory: "Update Category",
    Delete: "Delete",
    DeleteCategory: "Would you like to delete category?",
    DeleteHost: "Would you like to delete host?",
    DeletePartner: "Would you like to delete partner?",
    DeletePackage: "Would you like to delete package?",
    Hide: "Hide",
    Unhide: "Unhide",
    Venue: "Venue",
    Venues: "Venues",
    Food: "Food",
    AddNumberOfHours: "Add number of Hours",
    AddNumberOfGuests: "Add number of Guests",
    AddGuest: "Add a Guest",
    Guests: "Guests",
}