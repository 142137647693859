
const FormSelect = ({ label, options, additionalClasses, error, ...otherProps }) => {
    return (
        <div className={`${additionalClasses ? additionalClasses : ""} select is-medium ${error ? "is-danger" : ""}`}>
                {label && (
                    <label>{label}</label>
                )}
            <select {...otherProps}>
                <option disabled>Select</option>
                {options.map((option) => (
                    <option key={option}>{option}</option>
                ))}
            </select>
            {error && (
                <label className="has-text-danger">{error}</label>
            )}
        </div>
    );
}

export default FormSelect;