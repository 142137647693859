import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getCollectionDocs, getUserDoc } from "../utils/firebase";
import {Modal} from "../components/modal";
import { where } from "firebase/firestore";
import { VenueItem } from "../components/venue-item";
import { HostItem } from "../components/host-item";
import { PackageItem } from "../components/package-item";
import { CateringItem } from "../components/catering-item";
import ReactPlayer from "react-player";
import { rateFormatter } from "../utils/utils";
import { HallItem } from "../components/hall-item";

export const Browse = () => {
    const navigate = useNavigate();
    const [hosts, setHosts] = useState([]);
    const [venues, setVenues] = useState([]);
    const [modal, setModal] = useState("");
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Users", "businessName", "asc", [where("type", "==", "partner")]);
            if (response === "success") {
                setVenues(result);
                return;
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Packages", "name", "asc");
            if (response === "success") {
                setPackages(result);
                return;
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Hosts", "name", "asc");
            if (response === "success") {
                setHosts(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    const onClickHandler = (id) => {
        navigate("/browse/" + id);
    };

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered my-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Packages}</h1>
                        </div>
                    </div>
                    <div className="columns is-multiline my-6">
                        {packages.map((item) => {
                            return <PackageItem ellipsize={true} key={item.id} item={item} additionalClasses="column is-6-desktop" showStatus={false}/>
                        })}
                    </div>
                    
                    <div className="columns is-vcentered my-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Venues}</h1>
                        </div>
                    </div>
                    <div className="columns is-multiline mt-6">
                        {venues.map((venue) => {
                            return <VenueItem key={venue.id} item={venue} additionalClasses="column is-3-desktop is-6" onClickHandler={() => onClickHandler(venue.id)}/>
                        })}
                        </div>
                    <div className="columns is-vcentered my-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Hosts}</h1>
                        </div>
                    </div>
                    <div className="columns is-multiline my-6">
                        {hosts.map((item) => {
                            return <HostItem key={item.id} item={item} additionalClasses="column is-desktop is-3" showStatus={false}/>
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const defaultVenue = {
    photoUrl: '',
    businessName: '',
    emailAddress: '',
    contact: '',
    address: '',
    videoUrl: '',
    rate: 0
}

export const BrowseVenue = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [catering, setCatering] = useState([]);
    const [modal, setModal] = useState("");
    const [venue, setVenue] = useState(defaultVenue);
    const [venues, setVenues] = useState([]);
    const {photoUrl, businessName, emailAddress, contact, address, videoUrl, rate} = venue;

    useEffect(() => {
        async function get() {
            const [response, result] = await getUserDoc(id);
            if (response === "success") {
                setVenue(result);
                return;
            }
            setModal("Something went wrong");
        }
        
        if (id) {
            get();
        }
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Halls", "name", "asc", [where("partnerId", "==", id)]);
            if (response === "success") {
                setVenues(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Catering", "name", "asc", [where("partnerId", "==", id)]);
            if (response === "success") {
                setCatering(result);
                return;
            }
        };
        if (id) {
            get();
        }
    }, []);

    const onClickHandler = (id) => {
        navigate("/browse/" + id);
    };

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {videoUrl ? <ReactPlayer url={videoUrl} width="100%" height="auto" loop="true" playing="true"/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-10-desktop">

                    <div className="columns is-centered">
                        <div className="column is-3">
                            <figure className="image mt-6 is-rounded is-128x128" style={{marginRight: "auto", marginLeft: "auto"}}>
                                <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                            </figure>
                        </div>
                    </div>
                    <h1 className="has-text-centered is-size-4 has-large-text has-text-weight-bold mt-4">{businessName}</h1>
                    <p className="has-text-centered is-size-5 mt-2">{emailAddress}</p>
                    <p className="has-text-centered is-size-5 mt-2">{address}</p>
                    <p className="has-text-centered is-size-5 mt-5">
                        <Button type="button" additionalClasses="is-large with-background-primary has-text-white" onClick={() => navigate("/reserve")}>
                            Book now for only P {rateFormatter(rate)} per hour!
                        </Button>
                    </p>
                    
                    {venues.length > 0 ? <div className="columns is-vcentered my-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.EventHall}</h1>
                        </div>
                    </div> : ""}
                    <div className="columns is-multiline my-6">
                        {venues.map((venue) => {
                            return <HallItem key={venue.id} item={venue} additionalClasses="column is-3-desktop is-6"/>
                        })}
                        </div>
                        
                    {catering.length > 0 ? <div className="columns is-vcentered my-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Catering}</h1>
                        </div>
                    </div> : ""}
                    <div className="columns is-multiline mt-6">
                        {catering.map((item) => {
                            return <CateringItem key={item.id} item={item} additionalClasses="column is-desktop is-3" showStatus={false}/>
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}