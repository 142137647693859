import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Enums } from "../utils/enum";
import FormSelect from "../components/form-select";
import FormInput from "../components/form-input";
import Button from "../components/button";
import { endAt, startAt, where } from "firebase/firestore";
import customer_report from '../assets/customer_report.xlsx'
import sales_report from '../assets/sales_report.xlsx'
import { getCollectionDocs, getInvoices, getInvoicesReport, getRefunded, getRefundedReport } from "../utils/firebase";
import { Loading, Modal } from "../components/modal";
import ExcelJS from 'exceljs';
import { format } from "date-fns";
import { rateFormatter } from '../utils/utils';

export const Reports = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [type, setType] = useState("Select");
    const [error1, setError1] = useState("");
    const [error2, setError2] = useState("");
    const [error3, setError3] = useState("");
    const [now, setNow] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState("");

    const onDownload = async() => {
        if (type === "Select") {
            setError1("Required field");
            return;
        }

        if (startDate === "") {
            setError2("Required field");
            return;
        }

        if (endDate === "") {
            setError3("Required field");
            return;
        }

        setLoading(true);
        let result;
        const start = new Date(startDate);
        const end = new Date(endDate);

        const now = new Date();
        const borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };

          const workbook = new ExcelJS.Workbook();

          if (type === "Customers Report") {
            const [resp, resu] = await getCollectionDocs("Users", "created", "asc", [where("type", "==", "client"), endAt(end), startAt(start)]);
            if (resp === "error") {
                setLoading(false);
                setModal(resu);
                return;
            }
            result = [...resu];

            let blob = await fetch(customer_report).then(r => r.blob());
            await workbook.xlsx.load(blob);

            const sheet = workbook.getWorksheet("Sheet1");

            sheet.getCell("A1").value = type;
            sheet.getCell("A2").value = `${format(start, "MMMM dd, yyyy")} - ${format(end, "MMMM dd, yyyy")}`;
            sheet.getCell("A1").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A2").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A3").value = "No.";
            sheet.getCell("B3").value = "Customer Name";
            sheet.getCell("C3").value = "Date Registered";

            let i = 0;

            for (const res of result) {
                const row = i + 4;
                sheet.insertRow(row, [i + 1, res.firstName + " " + res.lastName, format(res.created.toDate(), "MMMM dd, yyyy")], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 1 ? 'center' : 'left', wrapText: true };
                });
                i++;
            }

        } else if (type === "Sales Report") {
            const invoices = await getInvoicesReport(start, end);
            const refunded = await getRefundedReport(start, end);
            const sales = [...invoices, ...refunded];
            sales.sort((a, b) => a.created.toDate().getTime() - b.created.toDate().getTime());
            result = [...sales];

            let blob = await fetch(sales_report).then(r => r.blob());
            await workbook.xlsx.load(blob);

            const sheet = workbook.getWorksheet("Sheet1");

            sheet.getCell("A1").value = type;
            sheet.getCell("A2").value = `${format(start, "MMMM dd, yyyy")} - ${format(end, "MMMM dd, yyyy")}`;
            sheet.getCell("A1").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A2").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A3").value = "No.";
            sheet.getCell("B3").value = "Date";
            sheet.getCell("C3").value = "Mode";
            sheet.getCell("D3").value = "Amount";

            let i = 0;
            let total = 0;

            for (const res of result) {
                const row = i + 4;
                total += res.amount;
                sheet.insertRow(row, [i + 1, format(res.created.toDate(), "MMMM dd, yyyy"), res.paymentMethod, `P ${rateFormatter(res.amount)}`], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 1 ? 'center' : colNumber === 4 ? 'right' : 'left', wrapText: true };
                });
                i++;
            }

            const lastRow = i + 4;
            sheet.mergeCells(lastRow, 1, lastRow, 3);
            sheet.getCell(`A${lastRow}`).value = "TOTAL";
            sheet.getCell(`A${lastRow}`).alignment = {vertical: 'middle', horizontal: 'left'};
            sheet.getCell(`A${lastRow}`).border = borderStyles;
            sheet.getCell(`A${lastRow}`).font.bold = true;

            sheet.getCell(`D${lastRow}`).value = `P ${rateFormatter(total)}`;
            sheet.getCell(`D${lastRow}`).alignment = {vertical: 'middle', horizontal: 'right'};
            sheet.getCell(`D${lastRow}`).border = borderStyles;
            sheet.getCell(`D${lastRow}`).font.bold = true;
        } else if (type === "Event Trends Report") {
            const [resp, resu] = await getCollectionDocs("Events", "start", "asc", [where("status", "in", ["Completed", "Paid", "Partial", "Unpaid"]), endAt(end), startAt(start)]);
            if (resp === "error") {
                setLoading(false);
                setModal(resu);
                return;
            }
            result = [...resu];

            let blob = await fetch(sales_report).then(r => r.blob());
            await workbook.xlsx.load(blob);

            const sheet = workbook.getWorksheet("Sheet1");

            sheet.getCell("A1").value = type;
            sheet.getCell("A2").value = `${format(start, "MMMM dd, yyyy")} - ${format(end, "MMMM dd, yyyy")}`;
            sheet.getCell("A1").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A2").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A3").value = "No.";
            sheet.getCell("B3").value = "Created";
            sheet.getCell("C3").value = "Category";
            sheet.getCell("D3").value = "Type";

            let i = 0;
            let categories = new Set();
            let types = new Set();
            let cats = [];
            let typs = [];

            for (const res of result) {
                const cat = res.category.title;
                categories.add(cat);
                cats.push(cat);
                types.add(res.type);
                typs.push(res.type);
                const row = i + 4;
                sheet.insertRow(row, [i + 1, format(res.start.toDate(), "MMMM dd, yyyy"), cat, res.type], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 1 ? 'center' : 'left', wrapText: true };
                });
                i++;
            }

            const sortedCats = [];
            for (const cat of categories) {
                const total = cats.filter((c) => c === cat).length;
                sortedCats.push({"category": cat, total});
            }
            sortedCats.sort(function(a,b) {
                return b.total - a.total;
            });

            const sortedTyps = [];
            for (const typ of types) {
                const total = typs.filter((c) => c === typ).length;
                sortedTyps.push({"type": typ, total});
            }
            sortedTyps.sort(function(a,b) {
                return b.total - a.total;
            });

            sheet.insertRow(i + 4, ["", "", "", ""], 'o+');
            i++;

            sheet.insertRow(i + 4, ["Categories", "", "", "No. of Events"], 'o+');
            sheet.getRow(i + 4).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                cell.border = borderStyles;
                cell.alignment = { vertical: 'middle', horizontal: colNumber === 4 ? 'center' : 'left', wrapText: true };
                cell.font.bold = true;
            });
            sheet.mergeCells(i + 4, 1, i + 4, 3);
            i++;

            for (const sorted of sortedCats) {
                const {total, category} = sorted;

                const row = i + 4;
                sheet.insertRow(row, [category, "", "", total], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 4 ? 'center' : 'left', wrapText: true };
                });
                sheet.mergeCells(row, 1, row, 3);
                i++;
            }
            sheet.insertRow(i + 4, ["", "", "", ""], 'o+');
            i++;

            sheet.insertRow(i + 4, ["Types", "", "", "No. of Events"], 'o+');
            sheet.getRow(i + 4).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                cell.border = borderStyles;
                cell.alignment = { vertical: 'middle', horizontal: colNumber === 4 ? 'center' : 'left', wrapText: true };
                cell.font.bold = true;
            });
            sheet.mergeCells(i + 4, 1, i + 4, 3);
            i++;

            for (const sorted of sortedTyps) {
                const {total, type} = sorted;

                const row = i + 4;
                sheet.insertRow(row, [type, "", "", total], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 4 ? 'center' : 'left', wrapText: true };
                });
                sheet.mergeCells(row, 1, row, 3);
                i++;
            }
        } else {
            const [resp, resu]  = await getCollectionDocs("Events", "start", "asc", [where("status", "in", ["Cancelled", "Refunded"]), endAt(end), startAt(start)]);
            if (resp === "error") {
                setLoading(false);
                setModal(resu);
                return;
            }
            result = [...resu];

            let blob = await fetch(sales_report).then(r => r.blob());
            await workbook.xlsx.load(blob);

            const sheet = workbook.getWorksheet("Sheet1");

            sheet.getCell("A1").value = type;
            sheet.getCell("A2").value = `${format(start, "MMMM dd, yyyy")} - ${format(end, "MMMM dd, yyyy")}`;
            sheet.getCell("A1").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A2").alignment = {vertical: 'middle', horizontal: 'center'};
            sheet.getCell("A3").value = "No.";
            sheet.getCell("B3").value = "Cancelled Date";
            sheet.getCell("C3").value = "Reason";
            sheet.getCell("D3").value = "Client";

            let i = 0;

            for (const res of result) {
                const row = i + 4;
                sheet.insertRow(row, [i + 1, format(res.updated.toDate(), "MMMM dd, yyyy"), res.reason, res.title], 'o+');
                sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                    cell.border = borderStyles;
                    cell.alignment = { vertical: 'middle', horizontal: colNumber === 1 ? 'center' : 'left', wrapText: true };
                });
                i++;
            }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
          new Blob([buffer]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${type.replace(" ", "_")}_${now.getTime()}.xlsx`,
        );
    
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false);
    }

    return (
        <Fragment>
        {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {loading ? <Loading/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Reports}</h1>
                    </div>

                    <div className="columns is-centered">
                        <div className="column is-8 mt-6">
                            <FormSelect error={error1} additionalClasses="is-fullwidth" options={["Sales Report", "Event Trends Report", "Cancellation Report", "Customers Report"]} type="text" required value={type} onChange={(e) => {setType(e.target.value); setError1("")}}/>
                        </div>
                    </div>
                    
                    <div className="columns is-centered mt-4">
                        <div className="column is-4">
                            <FormInput max={now.toISOString().split("T")[0]} error={error2} type="date" required value={startDate} onChange={(e) => {setStartDate(e.target.value); setError2("")}} label="Start *"/>
                        </div>
                        
                        <div className="column is-4">
                            <FormInput min={startDate != "" ? new Date(startDate).toISOString().split("T")[0] : null} error={error3} type="date" required value={endDate} onChange={(e) => {setEndDate(e.target.value); setError3("")}} label="End *"/>
                        </div>
                    </div>

                    <div className="columns is-centered mt-4">
                        <div className="column is-8">
                            <Button onClick={onDownload} type="button" additionalClasses="is-medium is-fullwidth has-text-white with-background-primary">Download Report</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}