import { Fragment, useEffect, useState, useContext } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate } from "react-router-dom";
import { Enums } from "../utils/enum";
import { createCollectionDoc, deleteCollectionDoc, getCollectionDocs, updateCollectionDoc } from "../utils/firebase";
import {Loading, Modal, ModalConfirm, ModalInput} from "../components/modal";
import MyCalendar from "../components/calendar";
import { where } from "firebase/firestore";
import { dateOverlaps } from "../utils/utils";
import { format } from "date-fns";
import { HallItem } from "../components/hall-item";

export const Calendar = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [events, setEvents] = useState([]);
    const isPartner = currentUser && currentUser.data.type === "partner";
    const [date, setDate] = useState(new Date());
    const [tempEvents,  setTempEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [modalConfirm, setModalConfirm] = useState("");
    const [unavailable, setUnavailable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(false);
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [load, setLoad] = useState(false);
    const [unavailableItem, setUnavailableItem] = useState(null);
    const [hall, setHall] = useState(false);
    const [venue, setVenue] = useState(null);
    const [venues, setVenues] = useState(null);

    useEffect(() => {
      async function get() {
          const array = [where("status", "in", ["Pending", "Unpaid", "Partial", "Paid", "Completed"])];
          if (isPartner) {
            array.push(where("partnerId", "==", currentUser.uid));
          }
          const [response, result] = await getCollectionDocs("Events", "created", "asc", array);
          if (response === "success") {
              const temp = [];
              for (let res of result) {
                const {build, title, id} = res;
                for (const b of build) {
                    const {start, end} = b;
                    const event = {start: start.toDate(), end: end.toDate(), id, title};
                    temp.push(event);
                }
              }
              setEvents(temp);
          }
          setLoad(true);
      };
      if (currentUser) {
        get();
      }
  }, [currentUser]);

  useEffect(() => {
    async function get() {
        const [response, result] = await getCollectionDocs("Halls", "name", "asc", [where("partnerId", "==", currentUser.uid)]);
        if (response === "success") {
            setVenues(result);
            return;
        }
        setModal(result.message);
    };
    if (currentUser) {
      get();
    }
}, [currentUser]);

  useEffect(() => {
    async function get() {
        const [response, result] = await getCollectionDocs("Unavailable", "created", "asc", [where("partnerId", "==", currentUser.uid)]);
        if (response === "success") {
            const temp = [];
            for (let res of result) {
              const {start, end, id, value, venueId} = res;
              const event = {start: start.toDate(), end: end.toDate(), id, title: "Unavailable", value, venueId};
              temp.push(event);
            }
            setEvents([...events, ...temp]);
            return;
        }
    };
    if (load) {
      get();
    }
}, [load]);

  const onSelectEvent = (event) => {
    const {title, start, end, id, value, venueId} = event;
    if (title === "Unavailable") {
      const index = venues.map(eve => eve.id).indexOf(venueId);
      if (index > -1) {
        setVenue(venues[index]);
      } else {
        setVenue({name: "Unknown"});
      }
      setUnavailableItem(id);
      setValue(value);
      setStart(start);
      setEnd(end);
      setUnavailable(true);
      return;
    } 
    navigate(`/event/${event.id}`);
  }

  const onSelectDate = (slotInfo) => {
    if (!isPartner) {
      return;
    }
    const {start} = slotInfo;
    if (date.getTime() > start.getTime()) {
        return;
    }

    const end = new Date(start);
    end.setDate(end.getDate() + 1);

    if (start.getTime() > date.getTime()) {
        const temp = [];
        for (const event of events) {
            const isAfterStart = event.start.getTime() >= start.getTime();
            const isBeforeEnd = event.start.getTime() < end.getTime();
            if (isAfterStart && isBeforeEnd) {
                temp.push(event);
            }
        }
        setTempEvents(temp);
    }

    setSelectedDate(start);
    setHall(true);
  }

  const onHallSelect = (venue) => {
    const temp = tempEvents.filter((event) => event.venueId === venue.id);
    console.log(temp);
    setTempEvents(temp);
    setVenue(venue);
    setHall(false);
    setTime(true)
  }
  
  const onSelectTime = (slotInfo) => {
    const {start, end, slots} = slotInfo;
    for (const event of tempEvents) {
        const tempStart = new Date(event.start);
        tempStart.setSeconds(1);
        const tempEnd = new Date(event.end);
        tempEnd.setHours(tempEnd.getHours() - 1);
        tempEnd.setMinutes(59);
        tempEnd.setSeconds(59);

        if (dateOverlaps(start, end, tempStart, tempEnd)) {
            setModal("Overlapping event found, select a different time.");
            return;
        }
    }

    setTime(false);
    setStart(start);
    setEnd(end);
    setModalConfirm(`You have selected ${format(start, "MMMM dd, yyyy h:mma")} - ${format(end, "h:mma")}, would you like to proceed?`);
  }

  const onUnavailable = async() => {
    setUnavailable(false);
    setLoading(true);

    if (unavailableItem == null) {
      const [response, result] = await createCollectionDoc("Unavailable", {start, end, value, venueId: venue.id});
      if (response === "success") {
        const event = {start, end, id: result.id, title: "Unavailable", venueId: venue.id, value};
        setEvents([...events, event]);
        setValue("");
        setVenue(null);
        setStart(null);
        setEnd(null);
        setLoading(false);
        return;
      }
      setError(result.message);
    } else {
      const [response, result] = await updateCollectionDoc("Unavailable", unavailableItem, {start, end, value});
      if (response === "success") {
        const index = events.map(eve => eve.id).indexOf(unavailableItem);
        events[index] = {start, end, id: unavailableItem, title: "Unavailable", value, venueId: venue.id};
        setEvents([...events]);
        setUnavailableItem(null);
        setValue("");
        setVenue(null);
        setStart(null);
        setEnd(null);
        setLoading(false);
        return;
      }
      setError(result.message);
    }

    setLoading(false);
    setUnavailable(true);
  }

  const onRemoveHandler = async() => {
    setUnavailable(false);
    setLoading(true);

    const [response, result] = await deleteCollectionDoc("Unavailable", unavailableItem);
    setLoading(false);
    if (response === "success") {
      const index = events.map(eve => eve.id).indexOf(unavailableItem);
      events.splice(index, 1);
      setEvents(events);
      setUnavailableItem(null);
      return;
    }
    setUnavailable(true);
    setModal(result.message);
  }

    return (
        <Fragment>
          {loading ? <Loading/> : ""}
          {unavailable ? <ModalInput onRemoveHandler={unavailableItem != null ? onRemoveHandler : null} remove="Delete" error={error} value={value} onChangeHandler={(e) => {setValue(e.target.value); if (error != "") {setError("")}}} message={`Reserve ${venue.name}`} text={`${format(start, "MMMM dd, yyyy h:mma")} - ${format(end, "h:mma")}`} placeholder="Note" cancel={unavailableItem != null ? "Cancel" : "Go Back"} save="Save" onCancelHandler={unavailableItem != null ? () => {setValue(""); setUnavailable(false); setUnavailableItem(null)} : () => {setVenue(null); setUnavailable(false); setHall(true)}} onSaveHandler={onUnavailable}/> : ""}
           {modalConfirm ? <ModalConfirm message={modalConfirm} yes="Yes" no="Go Back" onNoHandler={() => {setStart(null); setEnd(null); setModalConfirm(""); setTime(true)}} onYesHandler={() => {setModalConfirm(""); setUnavailable(true)}}/> : ""}
            
            {time ? <div className="modal is-active">
              <div className="modal-background"></div>
              <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                  <header className="modal-card-head">
                      <p className="modal-card-title has-text-weight-bold">Time</p>
                      <button className="delete is-medium" aria-label="close" onClick={() => {setSelectedDate(null); setTime(false)}}></button>
                  </header>
                  <section className="modal-card-body has-text-centered custom-calendar hidden-button hidden-header">
                      <MyCalendar height={1200} events={tempEvents} views={['day']} onSelectSlot={onSelectTime} date={selectedDate}/>
                  </section>
                  <footer className="modal-card-foot">
                  </footer>
              </div>
            </div> : ""}

            {hall ? <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-weight-bold">Event Hall</p>
                        <button className="delete is-medium" aria-label="close" onClick={() => {setHall(false)}}></button>
                    </header>
                    <section className="modal-card-body has-text-centered custom-calendar hidden-button">
                        <div className="columns is-multiline">
                        {venues.map((venue) => {
                            return <HallItem key={venue.id} item={venue} showStatus={false} additionalClasses="column is-3-desktop is-6" onClickHandler={() => onHallSelect(venue)}/>
                        })}
                        </div>
                    </section>
                    <footer className="modal-card-foot has-text-right is-block">
                    </footer>
                </div>
            </div> : ""}

            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2 mb-6">{Enums.Calendar}</h1>
                    <div className="mt-6 custom-calendar">
                        <MyCalendar events={events} onSelectEvent={onSelectEvent} onSelectSlot={onSelectDate}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}