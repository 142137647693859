import { Fragment } from 'react';
import { rateFormatter } from '../utils/utils';

export const HallItem = ({item, additionalClasses, onClickHandler, showStatus = true}) => {
    const { photoUrl, name, rate, status, pax, partner} = item;

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler}>
            <article className="media is-align-content-center item">
                <div className="media-content">
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                    <div className="content is-clipped pt-5 pb-5 px-5">
                        {showStatus ? <span className="tag is-success is-light mb-3">{status}</span> : ""}
                        <span className="tag is-success is-light">Max {pax} Pax</span>

                        <p className="has-text-weight-bold is-size-4 mb-2">{name}</p>
                        <p className="is-size-6">P {rateFormatter(rate)} per Hour</p>
                        
                        {partner ? <Fragment>
                            <p className="has-text-weight-bold mt-4 mb-0 with-text-primary">{partner.businessName}</p>
                            <p>{partner.address}</p>
                        </Fragment> : ""}
                    </div>
                </div>
            </article>
        </div>
    )
}