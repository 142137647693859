import FormInput from "./form-input"

export const Modal = ({ modal, setModal }) => {
    return (
        <div className="modal custom-modal has-text-centered is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">{modal}</p>
                </header>
                <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    <button className="button" onClick={() => {setModal("")}}>OK</button>
                </footer>
            </div>
        </div>
    )
}

export const Loading = () => {
    return (
        <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content" style={{width: "400px"}}>
                <header className="modal-card-head pt-6 is-borderless px-6">
                </header>
                <section className="modal-card-body px-6 has-text-centered">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                </footer>
            </div>
        </div>
    )
}

export const ModalInput = ({message, value, cancel, save, error, onChangeHandler, onCancelHandler, onSaveHandler, placeholder = "", text, onRemoveHandler = null, remove}) => {
    return (
        <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className={`modal-card-head pt-6 is-borderless px-6 ${text ? "pb-0" : "pb-4"}`}>
                    <p className="modal-card-title has-text-weight-bold">{message}</p>
                </header>
                <section className="modal-card-body px-6">
                    {text ? <p className="pb-5">{text}</p> : ""}
                    <FormInput type="text" required value={value} onChange={onChangeHandler} error={error} placeholder={placeholder}/>
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                    {onRemoveHandler ? <button className="button is-danger is-pulled-left" onClick={onRemoveHandler}>{remove}</button> : ""}
                    <button className="button" onClick={onCancelHandler}>{cancel}</button>
                    <button className="button is-success" onClick={onSaveHandler}>{save}</button>
                </footer>
            </div>
        </div>
    )
}

export const ModalConfirm = ({message, yes, no, onYesHandler, onNoHandler, danger}) => {
    return (
        <div className="modal custom-modal has-text-centered is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">{message}</p>
                </header>
                <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    <button className="button" onClick={onNoHandler}>{no}</button>
                    <button className={`button ${danger ? "is-danger" : "is-success"}`} onClick={onYesHandler}>{yes}</button>
                </footer>
            </div>
        </div>
    )
}