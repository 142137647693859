import { signInUserWithEmailAndPassword } from '../utils/firebase';
import { useState } from 'react';
import FormInput from '../components/form-input';
import Button from '../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import {Modal} from '../components/modal';
import { Enums } from '../utils/enum'

const defaultFormFields = {
    email: '',
    password: '',
}

const SignIn = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password} = formFields;
    const [modal, setModal] = useState("");

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await signInUserWithEmailAndPassword(email, password);
            resetFormFields();
            
            if (state) {
                onNavigate("/overview");
                return;
            }
            onNavigate('/dashboard');
        } catch (error) {
            switch(error.code) {
                case "auth/wrong-password":
                    setModal("Incorrect password");
                    break;
                case "auth/user-not-found":
                    setModal("User not found");
                    break;
                default:
                    console.log("Sign in failed: ", error.message);
            }
        }
    }

    const onNavigate = (path) => {
        if (state) {
            navigate(path, {replace: true, state: {...state}});
            return;
        }
        navigate(path);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-centered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="column is-4-desktop">
            {state ? <div className="tile notification is-danger">Sign in to continue..</div> : ""}
                <h2 className='is-size-2 has-text-weight-bold mt-6 is-uppercase'>{Enums.SignIn}</h2>
                <p className='block mb-6'>{Enums.SignInText}</p>
                <form onSubmit={handleSubmit}>
                    <FormInput type="email" required id="email" value={email} onChange={handleChange} label="Email Address" additionalClasses="block"/>
                    <FormInput type="password" required id="password" value={password} onChange={handleChange} label="Password"/> 
                    <p className='has-text-right block mt-2'><a onClick={() => {onNavigate("/forgot-password")}}>{Enums.ForgotPasswordButtonText}</a></p>
                    <Button type="submit" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth">{Enums.SignInButtonText}</Button>
                </form>
                <p className='block mt-6 has-text-centered'>Don't have an account? <a onClick={() => {onNavigate("/signup")}}>{Enums.SignUp}.</a></p>
            </div>
        </div>
    )
}

export default SignIn;