import { useState, useRef, Fragment, useEffect } from "react";
import HomeImage from "../assets/img/home_image.png"
import GalleryImage from "../assets/img/gallery_image.png"
import Button from '../components/button';
import FormInput from "../components/form-input";
import FormTextArea from "../components/form-textarea";
import { Enums } from "../utils/enum";
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { Modal } from "../components/modal";
import { Carousel } from "react-responsive-carousel";
import { SlideshowItem } from "../components/slideshow-item";
import { getCollectionDocs } from "../utils/firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Browse } from "./browse-page";

export const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-4-desktop has-text-centered-touch">
                <img src={HomeImage} width="550"/>
            </div>
            <div className="column is-6-desktop has-text-centered-touch">
              <h1 className="is-size-2 has-large-text has-text-weight-bold with-text-primary">{Enums.HomeTitle}</h1>
              <p className='is-size-3-desktop is-size-4-touch mt-5'>{Enums.ReserveText}</p>
              <Button type="button" onClick={() => navigate("/reserve")} additionalClasses="is-rounded mt-6 is-large with-background-primary has-text-weight-medium has-text-white block">{Enums.ReserveButtonText}</Button>
            </div>
        </div>
    )
}

export const Gallery = () => {
    const navigate = useNavigate();
    const [slideshow, setSlideshow] = useState([]);
    const [categories, setCategories] = useState([]);
    const [first, setFirst] = useState(-1);
    const [second, setSecond] = useState(-1);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Categories", "title", "asc");
            if (response === "success") {
                setCategories(result);
                const firstRnd = Math.floor(Math.random() * result.length);
                let secondRnd = firstRnd;
                while(firstRnd === secondRnd) {
                    secondRnd = Math.floor(Math.random() * result.length);
                }
                setFirst(firstRnd);
                setSecond(secondRnd);
                return;
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Slideshows", "date", "asc");
            if (response === "success") {
                setSlideshow(result);
                return;
            }
        };
        get();
    }, []);

    return (
        <div className="px-5 columns is-desktop is-vcentered is-centered">
            <div className="column is-5-desktop has-text-centered-touch">
              <h1 className="is-size-2 has-large-text has-text-weight-bold with-text-primary">{Enums.GalleryTitle}</h1>
              <p className='is-size-3-desktop is-size-4-touch mt-5'>{Enums.ReserveText}</p>
              <Button type="button" onClick={() => navigate("/reserve")} additionalClasses="is-rounded mt-6 is-large with-background-primary has-text-weight-medium has-text-white block">{Enums.ReserveButtonText}</Button>
            </div>
            <div className="column is-5-desktop">
                <div className="columns">
                    <div className="column is-6">
                        <Carousel infiniteLoop={true}>
                            {slideshow.filter((slide) => slide.category === categories[first].title).map((item) => {
                                    return <SlideshowItem key={item.id} item={item}/>
                                })
                            }
                        </Carousel>
                    </div>

                    <div className="column is-6">
                        <Carousel infiniteLoop={true}>
                            {slideshow.filter((slide) => slide.category === categories[second].title).map((item) => {
                                    return <SlideshowItem key={item.id} item={item}/>
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Services = () => {
    const navigate = useNavigate();

    return (
        <div className="px-5 columns is-desktop is-vcentered is-centered is-multiline">
            <div className="column is-4-desktop has-text-centered-touch">
              <h1 className="is-size-2 has-large-text has-text-weight-bold with-text-primary">{Enums.ServicesTitle}</h1>
              <p className='is-size-3-desktop is-size-4-touch mt-5'>{Enums.ServicesText}</p>
              <Button type="button" onClick={() => navigate("/reserve")} additionalClasses="is-rounded mt-6 is-large with-background-primary has-text-weight-medium has-text-white block">{Enums.ReserveButtonText}</Button>
            </div>
            <div className="column is-offset-1-desktop is-5-desktop">
                <div className="tile is-ancestor">
                    <div className="tile is-parent">
                    <article className="tile is-child notification with-background-primary has-text-white content">
                        <p className="is-size-3 has-text-weight-bold mt-5">Event Management</p>
                        <ul className="is-size-4 mb-6">
                            <li className="block">We offer venue for as low as P1,500</li>
                            <li className="block">Choose your Host for your special event</li>
                            <li className="block">We also cater food and beverage for your event</li>
                            <li className="block">We offer different kind of events such as wedding, birthday, and seminar</li>
                            <li className="block">Partial or Full payment first to secure your booking</li>
                        </ul>
                    </article>
                    </div>
                </div>
            </div>

            <div className="column is-10-desktop mt-6">
                <Browse/>
            </div>
        </div>
    )
}

const defaultFormFields = {
    name: '',
    email: '',
    message: '',
}

export const Contact = () => {
    const form = useRef();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, email, message } = formFields;
    const [modal, setModal] = useState("");
    const handleChange = (event) => {

        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        emailjs.sendForm('service_56qqnix', 'template_6qbotdq', form.current, '7m9aQ6HgaIo5rq7t9')
        .then((result) => {
            setModal("Message has been delivered successfully.");
            setFormFields(defaultFormFields);
        }, (error) => {
            setModal(error.text);
        });
    }

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-centered">
                <div className="column is-4-desktop has-text-centered-touch">
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className="columns is-multiline fix-gap">
                            <FormInput type="text" required name="name" value={name} onChange={handleChange} label="Full Name" additionalClasses="column is-12"/>
                            <FormInput type="email" required name="email" value={email} onChange={handleChange} label="Email Address" additionalClasses="column is-12"/>
                            <FormTextArea type="text" required name="message" value={message} onChange={handleChange} label="Message" additionalClasses="column is-12"/>
                        </div>
                        <Button type="submit" additionalClasses="mt-3 is-medium with-background-primary has-text-white block is-fullwidth">{Enums.SendButtonText}</Button>
                    </form>
                </div>
                <div className="column is-5-desktop is-offset-1-desktop">
                    <h1 className="is-size-2 has-large-text has-text-weight-bold with-text-primary">{Enums.ContactTitle}</h1>
                    <p className='is-size-3-desktop is-size-4-touch mt-5'>{Enums.ContactText}</p>

                    <div className="tile is-ancestor mt-5">
                        <div className="tile is-parent">
                        <article className="tile is-child notification with-background-primary has-text-white">
                            <div className="columns is-multiline is-size-5 py-5">
                                <p className="column is-4 has-text-weight-bold">Email Address</p>
                                <p className="column is-8">almeventandtalents@gmail.com</p>
                                <p className="column is-4 has-text-weight-bold">Address</p>
                                <p className="column is-8">3rd floor, Balane Building, Daet, Camarines Norte</p>
                                <p className="column is-4 has-text-weight-bold">Mobile No.</p>
                                <p className="column is-8">0998-888-0409</p>
                            </div>
                        </article>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}