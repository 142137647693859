import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getCollectionDocs, uploadFile, createCollectionDoc, updateCollectionDoc, getCollectionDoc, getUserDocs } from "../utils/firebase";
import FormInput from "../components/form-input";
import {Loading, Modal, ModalConfirm} from "../components/modal";
import { UserContext } from "../context/user-context";
import { PackageItem } from "../components/package-item";
import FormTextArea from "../components/form-textarea";
import FormSelect from "../components/form-select";
import { where } from "firebase/firestore";

export const Packages = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Packages", "name", "asc");
            if (response === "success") {
                setPackages(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    const onCreateHandler = async() => {
        navigate("/packages/new");
    };

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Packages}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            <Button type="button" additionalClasses="is-medium has-text-white with-background-primary" onClick={onCreateHandler}>Create</Button>
                        </div>
                    </div>
                    <div className="columns is-multiline mt-6">
                        {packages.map((item) => {
                                return <PackageItem ellipsize={true} key={item.id} item={item} additionalClasses="column is-6-desktop is-12" onClickHandler={() => navigate(`/packages/${item.id}`)}/>
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const defaultFormFields = {
    name: '',
    price: 0,
    rate: 0,
    pax: 0,
    photoUrl: '',
    status: 'Active',
    description: '',
    category: 'Select',
    type: 'Select',
    partnerId: '',
    venueId: '',
    business: 'Select',
    hall: 'Select',
}

const defaultErrors = ["", ""]
export const PackageNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, photoUrl, status, description, type, category, price, rate, pax, partnerId, venueId, business, hall } = formFields;
    const [modal, setModal] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [imageFileUrl, setImageFileUrl] = useState("");
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState(defaultErrors);
    const [types, setTypes] = useState([]);
    const [docLoaded, setDocLoaded] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [venues, setVenues] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [halls, setHalls] = useState([]);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Categories", "title", "asc");
            if (response === "success") {
                setCategories(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    useEffect(() => {
        const index = categories.map(cat => cat.title).indexOf(category);
        setTypes(index > -1 ? categories[index].types : []);
    }, [categories, docLoaded]);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDoc("Packages", id);
            if (response === "success") {
                if (result && result.exists) {
                    setFormFields(result.data());
                    setDocLoaded(true);
                    return;
                }
    
                setModal("Document does not exist..");
                return;
            }
            setModal(result.message);
        }
        if (id) {
            get();
        }
    }, []);

    useEffect(() => {
        async function get() {
            const docs = await getUserDocs("partner");
            const temp = [];
            for (const res of docs) {
                temp.push(res.businessName);
            }
            setAccounts(docs);
            setBusinesses(temp);
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Halls", "name", "asc", [where("partnerId", "==", partnerId)]);
            if (response === "success") {
                const temp = [];
                for (const res of result) {
                    temp.push(res.name);
                }
                setVenues(result);
                setHalls(temp);
            }
        };
        if (partnerId) {
            get();
        }
    }, [partnerId]);

    useEffect(() => {
        if (imageFile) {
            setImageFileUrl(window.URL.createObjectURL(imageFile));
        }
    }, [imageFile]);

    const handleChange = (event) => {
        const { id, value } = event.target;

        if (id === "category") {
            if (errors[0]) {
                errors[0] = "";
                setErrors([...errors]);
            }
            const index = categories.map(cat => cat.title).indexOf(value);
            setTypes(index > -1 ? categories[index].types : []);
        }

        if (id === "type") {
            if (errors[1]) {
                errors[1] = "";
                setErrors([...errors]);
            }
        }

        if (id === "business") {
            const index = accounts.map(account => account.businessName).indexOf(value);
            setFormFields({...formFields, "hall": "Select", "venueId": "", "business": value, partnerId: accounts[index].id});
            return;
        }

        if (id === "hall") {
            const index = venues.map(venue => venue.name).indexOf(value);
            setFormFields({...formFields, "hall": value, "venueId": venues[index].id, "pax": venues[index].pax});
            return;
        }
        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (category === "Select" || type === "Select" || business === "Select" || hall === "Select") {
            const errorText = "Field is required";
            setErrors([category === "Select" ? errorText : "", type === "Select" ? errorText : "", business === "Select" ? errorText : "", hall === "Select" ? errorText : ""]);
            return;
        }

        setLoading(true);
        const data = {
            ...formFields,
            price: parseFloat(price),
            rate: parseFloat(rate),
            pax: parseFloat(pax),
        };
        if (imageFile) {
            const [response, result] = await uploadFile("Packages", imageFile);
            if (response === "success") {
                data["photoUrl"] = result;
            } else {
                setLoading(false);
                setModal(result.message);
                return;
            }
        }

        const [response, result] = id ? await updateCollectionDoc("Packages", id, data) : await createCollectionDoc("Packages", data);
        setLoading(false);
        if (response === "success") {
            navigate("/packages");
            return;
        }
        setModal(result.message);
    }

    const handleFileChange = (event) => {
        const { files } = event.target;
        if (!files[0]) {
            return;
        }

        setImageFile(files[0]);
    }

    const onDeleteHandler = async() => {
        setModalConfirm(false);
        setLoading(true);

        const [response, result] = await updateCollectionDoc("Packages", id, {deleted: true});
        setLoading(false);
        if (response === "success") {
            navigate("/packages");
            return;
        }
        setModal(result.message);
    }

    const onHideHandler = async() => {
        setLoading(true);

        const newStatus = status === "Hidden" ? "Active" : "Hidden";
        const [response, result] = await updateCollectionDoc("Packages", id, {status: newStatus});
        setLoading(false);
        if (response === "success") {
            setFormFields({...formFields, status: newStatus});
            return;
        }
        setModal(result.message);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {loading ? <Loading/> : ""}
            {modalConfirm ? <ModalConfirm message={Enums.DeletePackage} yes="Proceed" no="Cancel" onNoHandler={() => setModalConfirm(false)} onYesHandler={onDeleteHandler} danger={true}/> : ""}
            <div className="column is-12">
                <div className="columns is-vcentered mt-2 mb-6">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{id ? Enums.UpdatePackage : Enums.NewPackage}</h1>
                        <span>{status}</span>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        {id ? <Fragment>
                            <Button type="button" additionalClasses="is-medium mr-3" onClick={onHideHandler}>{status === "Hidden" ? Enums.Unhide : Enums.Hide}</Button>
                            <Button type="button" additionalClasses="is-medium is-danger" onClick={() => setModalConfirm(true)}>{Enums.Delete}</Button>
                        </Fragment> : ""}
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='columns'>
                        <div className="column is-4">
                            <figure className="image is-3by4 is-clickable">
                                <img src={imageFile && imageFileUrl ? imageFileUrl : photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"} alt="Image"/>
                                <input className="file-input is-clickable" type="file" onChange={handleFileChange} accept= "image/*"/>
                            </figure>
                        </div>
                        <div className="column is-8">
                            <div className="columns is-multiline">
                                <FormSelect error={errors[2]} options={businesses} type="text" required id="business" value={business} onChange={handleChange} label="Event Place *" additionalClasses="column is-6"/>
                                <FormSelect error={errors[3]} options={halls} type="text" required id="hall" value={hall} onChange={handleChange} label="Hall/Room *" additionalClasses="column is-6"/>
                                <FormInput type="text" required id="name" value={name} onChange={handleChange} label="Package *" additionalClasses="column is-12"/>
                                <FormSelect error={errors[0]} options={[...categories.map(cat => cat.title)]} type="text" required id="category" value={category} onChange={handleChange} label="Category *" additionalClasses="column is-6"/>
                                <FormSelect error={errors[1]} options={types} type="text" required id="type" value={type} onChange={handleChange} label="Type *" additionalClasses="column is-6"/>
                                <FormInput disabled type="number" min="0" required id="pax" value={pax} label="Maximum Pax *" additionalClasses="column is-4"/>
                                <FormInput type="number" step=".01" min="0" required id="price" value={price} onChange={handleChange} label="Total Price *" additionalClasses="column is-4"/>
                                <FormInput type="number" step=".01" min="0" required id="rate" value={rate} onChange={handleChange} label="Extension Rate per Hour *" additionalClasses="column is-4"/>
                                <FormTextArea type="text" required id="description" value={description} onChange={handleChange} label="Description" additionalClasses="column is-12"/>
                                <Button type="submit" additionalClasses="is-fullwidth is-medium with-background-primary has-text-white block mt-5 mb-6">{id ? Enums.SaveChanges : Enums.Create}</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}