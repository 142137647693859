import { Fragment, useEffect, useState, useContext } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate } from "react-router-dom";
import { Enums } from "../utils/enum";
import { getCollectionDocs, getUserDocs, getYearEvents } from "../utils/firebase";
import {Modal} from "../components/modal";
import { endBefore, startAfter, where } from "firebase/firestore";
import FormInput from "../components/form-input";
import { format } from "date-fns";
import { Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import FormSelect from "../components/form-select";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend, 
    CategoryScale,
    LinearScale,
    BarElement,
);

const data = {
    labels: [],
    datasets: [
    {
        label: ' Booked Events',
        data: [],
        backgroundColor: [],
    },
    ],
};

const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthData = {
  labels : monthLabels,
  datasets: [
    {
      label: ' Booked Events',
      data: [],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

  export const Analytics = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [events, setEvents] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [catering, setCatering] = useState([]);
    const [venues, setVenues] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const isPartner = currentUser && currentUser.data.type === "partner";
    const [packages, setPackages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryData, setCategoryData] = useState(data);
    const [typeData, setTypeData] = useState(data);
    const [bookingData, setBookingData] = useState(monthData);
    const [displayAllHall, setDisplayAllHall] = useState(false);
    const [displayAllCatering, setDisplayAllCatering] = useState(false);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState("");

    useEffect(() => {
        const now = new Date();
        const cur = now.getFullYear();
        const temp = [];
        for (let i = 2023; i <= cur + 1; i++) {
            temp.push(i);
        }
        setYears(temp);
        setYear(cur.toString());
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Categories", "title", "asc");
            if (response === "success") {
                setCategories(result);
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const docs = await getUserDocs("partner");
            setAccounts(docs);
        };
        if (!isPartner) {
            get();
        }
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Packages", "name", "asc");
            if (response === "success") {
                setPackages(result);
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Halls", "name", "asc", isPartner ? [where("partnerId", "==", currentUser.uid)] : []);
            if (response === "success") {
                setVenues(result);
            }
        };
        if (currentUser) {
            get();
        }
    }, [currentUser]);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Catering", "name", "asc", isPartner ? [where("partnerId", "==", currentUser.uid)] : []);
            if (response === "success") {
                setCatering(result);
            }
        };
        if (currentUser) {
            get();
        }
    }, [currentUser]);
    

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Hosts", "name", "asc");
            if (response === "success") {
                setHosts(result);
            }
        };
        get();
    }, []);

    useEffect(() => {
      async function get() {
          const array = [where("status", "in", ["Pending", "Unpaid", "Partial", "Paid", "Completed"])];
          if (isPartner) {
            array.push(where("partnerId", "==", currentUser.uid));
          }
          const [response, result] = await getYearEvents(year, array);
          if (response === "success") {
                setEvents(result);
          }
      };
      if (currentUser && year !== "") {
        get();
      }
  }, [currentUser, year]);

  useEffect(() => {
    const categorySet = new Set();
    const typeSet = new Set();
    const catData = [];
    const typData = [];
    const booData = [];

    const builds = [];
    const talents = [];
    const dates = [];
    const foods = [];
    const packs = [];

    for (const event of events) {
        const {category: {title}, type, build} = event;
        categorySet.add(title);
        typeSet.add(type);
        builds.push(build);
    }

    for (const category of categorySet) {
        const temp = events.filter((event) => event.category.title === category);
        let size = 0;
        for (const event of temp) {
            size += event.build.length;
        }
        catData.push(size);
    }

    for (const type of typeSet) {
        const temp = events.filter((event) => event.type === type);
        let size = 0;
        for (const event of temp) {
            size += event.build.length;
        }
        typData.push(size);
    }


    for (const build of builds) {
        for (const b of build) {
            dates.push(b.start.toDate());
            talents.push(...b.talents);
            foods.push(...b.foodItems);
            if (b.packageItem != null) {
                packs.push(b.packageItem);
            }
        }
    }

    for (const month of monthLabels) {
        const size = dates.filter((date) => date.toString().includes(month)).length;
        booData.push(size);
    }

    setBookingData({
        labels : monthLabels,
        datasets: [
          {
            label: ' Booked Events',
            data: booData,
            backgroundColor: 'rgba(136, 225, 58, 1)',
          },
        ],
      });

    setCategoryData({
        labels: Array.from(categorySet),
        datasets: [{
            label: ' Booked Events',
            data: catData,
            backgroundColor: [
                'rgba(117, 66, 254, 1)',
                'rgba(195, 33, 204, 1)',
                'rgba(249, 85, 0, 1)',
                'rgba(253, 190, 0, 1)',
                'rgba(136, 225, 58, 1)',
                'rgba(1, 129, 129, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
            ],
        }],
    });

    setTypeData({
        labels: Array.from(typeSet),
        datasets: [{
            label: ' Booked Events',
            data: typData,
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(1, 129, 129, 1)',
                'rgba(136, 225, 58, 1)',
                'rgba(253, 190, 0, 1)',
                'rgba(249, 85, 0, 1)',
                'rgba(195, 33, 204, 1)',
                'rgba(117, 66, 254, 1)',
            ],
        }],
    })

        for (const host of hosts) {
            const e = talents.filter((talent) => talent.id === host.id);
            host["total"] = e.length;
        }
        
        for (const pack of packages) {
            const e = packs.filter((p) => p.id === pack.id);
            pack["total"] = e.length;
        }
        
        for (const cat of catering) {
            const e = foods.filter((food) => food.id === cat.id);
            cat["total"] = e.length;
        }

        for (const account of accounts) {
            const temp = events.filter((event) => event.partnerId === account.id);
            let size = 0;
            for (const event of temp) {
                size += event.build.length;
            }
            account["total"] = size;
        }

        for (const venue of venues) {
            const temp = events.filter((event) => event.venueId === venue.id);
            let size = 0;
            for (const event of temp) {
                size += event.build.length;
            }
            venue["total"] = size;
        }

        const newHost = hosts.sort((a, b) => {
            return b.total - a.total;
        });

        const newAccount = accounts.sort((a, b) => {
            return b.total - a.total;
        });

        const newCatering = catering.sort((a, b) => {
            return b.total - a.total;
        });

        const newPackages = packages.sort((a, b) => {
            return b.total - a.total;
        });

        const newVenues = venues.sort((a, b) => {
            return b.total - a.total;
        });

        setPackages(newPackages);
        setCatering(newCatering);
        setHosts(newHost);
        setAccounts(newAccount);
        setVenues(newVenues);
  }, [events]);

    return (
        <Fragment>
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Analytics}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            <FormSelect options={years} type="number" required id="year" value={year} onChange={(e) => setYear(e.target.value)}/>
                        </div>
                    </div>
                    <div className="mt-6 columns is-multiline mb-6">
                        <div className="column is-12">
                            <Bar data={bookingData} />
                        </div>
                        {events.length > 0 ? <div className="column is-12 mt-6">
                            <div className="columns">
                                <div className="column is-6-desktop is-12">
                                    <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mb-5">{Enums.Categories}</h2></div>
                                        <div className="columns is-centered">
                                            <div className="column is-8-desktop is-12">
                                            <Doughnut data={categoryData} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-6-desktop is-12">
                                    <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mb-5">{Enums.Types}</h2></div>
                                        <div className="columns is-centered">
                                            <div className="column is-8-desktop is-12">
                                            <Doughnut data={typeData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}

                    <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.Packages}</h2></div>
                        {packages.map((item, index) => {
                            const {id, name, photoUrl, total} = item;
                            return (
                                <div className="card column is-12 px-5 mb-3 py-5" key={`host_${index}`}>
                                <figure className={`is-rounded image is-64x64 is-pulled-left mr-4 ${index < 3 ? "mt-4" : "mt-2"}`}>
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                                    <div className="py-5">
                                        <h2 className="is-size-5 has-text-weight-semibold">
                                            {name}
                                            <span className="tag is-medium is-pulled-right">{total ? total : 0}</span>
                                        </h2>
                                            {index < 3 ? <span className="tag is-success is-light">Rank {index + 1}</span> : ""}
                                    </div>
                                </div>
                            )
                        })}

                        <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.EventHall}</h2></div>
                        {venues.map((item, index) => {
                            if (index > 4 && !displayAllHall) {
                                return;
                            }
                            const {id, name, photoUrl, total, partnerId} = item;
                            const i = accounts.map(account => account.id).indexOf(partnerId);
                            return (
                                <div className="card column is-12 px-5 mb-3 py-5" key={`host_${index}`}>
                                <figure className={`is-rounded image is-64x64 is-pulled-left mr-4 ${index < 3 ? "mt-4" : "mt-2"}`}>
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                                    <div className="py-5">
                                        <span className="is-size-5 has-text-weight-semibold">{name}</span>
                                        {index < 3 ? <span className="tag is-success is-light ml-3">Rank {index + 1}</span> : ""}
                                        <span className="tag is-medium is-pulled-right">{total ? total : 0}</span>
                                        {i > -1 ? <p>{accounts[i].businessName}</p> : ""}
                                    </div>
                                </div>
                            )
                        })}
                        {venues.length > 5 ? <div className="column is-12 has-text-weight-semibold with-text-primary has-text-centered is-clickable py-5" onClick={() => setDisplayAllHall(!displayAllHall)}>{!displayAllHall ? "See All" : "See Less"}</div> : ""}

                        <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.Catering}</h2></div>
                        {catering.map((item, index) => {
                            if (index > 4 && !displayAllCatering) {
                                return;
                            }
                            const {id, name, photoUrl, total, partnerId} = item;
                            const i = accounts.map(account => account.id).indexOf(partnerId);
                            return (
                                <div className="card column is-12 px-5 mb-3 py-5" key={`host_${index}`}>
                                <figure className={`is-rounded image is-64x64 is-pulled-left mr-4 ${index < 3 ? "mt-4" : "mt-2"}`}>
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                                    <div className="py-5">
                                    <span className="is-size-5 has-text-weight-semibold">{name}</span>
                                        {index < 3 ? <span className="tag is-success is-light ml-3">Rank {index + 1}</span> : ""}
                                        <span className="tag is-medium is-pulled-right">{total ? total : 0}</span>
                                        {i > -1 ? <p>{accounts[i].businessName}</p> : ""}
                                    </div>
                                </div>
                            )
                        })}
                        {catering.length > 5 ? <div className="column is-12 has-text-weight-semibold with-text-primary has-text-centered is-clickable py-5" onClick={() => setDisplayAllCatering(!displayAllCatering)}>{!displayAllCatering ? "See All" : "See Less"}</div> : ""}

                        <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.Hosts}</h2></div>
                        {hosts.map((item, index) => {
                            const {id, name, photoUrl, total} = item;
                            return (
                                <div className="card column is-12 px-5 mb-3 py-5" key={`host_${index}`}>
                                <figure className={`is-rounded image is-64x64 is-pulled-left mr-4 ${index < 3 ? "mt-4" : "mt-2"}`}>
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                                    <div className="py-5">
                                        <h2 className="is-size-5 has-text-weight-semibold">
                                            {name}
                                            <span className="tag is-medium is-pulled-right">{total ? total : 0}</span>
                                        </h2>
                                            {index < 3 ? <span className="tag is-success is-light">Rank {index + 1}</span> : ""}
                                    </div>
                                </div>
                            )
                        })}

                        {!isPartner ? <Fragment>
                        <div className="column is-12"><h2 className="is-size-3 has-text-weight-semibold mt-6 mb-5">{Enums.Partners}</h2></div>
                            {accounts.map((item, index) => {
                                const {id, businessName, photoUrl, total} = item;
                                return (
                                    <div className="card column is-12 px-5 mb-3 py-5" key={`account_${index}`}>
                                    <figure className={`is-rounded image is-64x64 is-pulled-left mr-4 ${index < 3 ? "mt-4" : "mt-2"}`}>
                                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                    </figure>
                                        <div className="py-5">
                                            <h2 className="is-size-5 has-text-weight-semibold">
                                                {businessName}
                                                <span className="tag is-medium is-pulled-right">{total ? total : 0}</span>
                                            </h2>
                                                {index < 3 ? <span className="tag is-success is-light">Rank {index + 1}</span> : ""}
                                        </div>
                                    </div>
                                )
                            })}
                        </Fragment> : ""}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}