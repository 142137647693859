import { rateFormatter } from '../utils/utils';

export const CateringItem = ({item, additionalClasses, onClickHandler, showStatus = true, vertical = true}) => {
    const { photoUrl, name, price, status, pax, category } = item;

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler}>
            {vertical ? <article className="media is-align-content-center item">
                <div className="media-content">
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                    <div className="content is-clipped pt-5 pb-5 px-5">
                        {showStatus ? <span className="tag is-success is-light mr-3">{status}</span> : ""}
                        <span className="tag is-success is-light">{category}</span>
                        <span className="tag is-success is-light ml-3">{pax} Pax</span>
                        <p className="has-text-weight-bold is-size-4 mb-2 mt-3">{name}</p>
                        <p className="is-size-6">P {rateFormatter(price)}</p>
                    </div>
                </div>
            </article> : <div className='columns card px-2 py-3 has-text-left'>
                <div className='column is-3-desktop'>
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                    </figure>
                </div>
                <div className='column is-9-desktop'>
                    {showStatus ? <span className="tag is-success is-light mr-3">{status}</span> : ""}
                    <span className="tag is-success is-light">{category}</span>
                    <span className="tag is-success is-light ml-3">{pax} Pax</span>
                    <p className="has-text-weight-bold is-size-4 mb-2 mt-3">{name}</p>
                    <p className="is-size-6">P {rateFormatter(price)}</p>
                </div>
            </div>}
        </div>
    )
}