import { useEffect, useState } from "react";
import { rateFormatter } from '../utils/utils';

export const EventItem = ({event, additionalClasses, onClickHandler}) => {
    const { imageFileUrl, theme, type, venue, rate, category, _package} = event;
    const [finalRate, setFinalRate] = useState("");

    useEffect(() => {
        setFinalRate(rateFormatter(rate));
    }, []);

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler ? () => onClickHandler(event) : () => {}}>
            <article className="media is-align-content-center item">
                <div className="media-content">
                    <figure className="image is-3by4 is-fullwidth">
                        <img src={imageFileUrl ? imageFileUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                        <span className='package has-text-weight-bold has-text-white'>{_package}<span className='arrow-right'></span></span>
                    </figure>
                    <div className="content is-clipped pt-5 pb-5 px-5">
                        <span className="tag is-success is-light mr-2">{type}</span>
                        <span className="tag is-success is-light mr-2">{category}</span>
                        <span className="tag is-success is-light mr-2">{theme}</span>
                        <p className="has-text-weight-bold is-size-4 mb-2 mt-3">{venue}</p>
                        <p className="is-size-6">Php {finalRate}</p>
                    </div>
                </div>
            </article>
        </div>
    )
}