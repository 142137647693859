import { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { Enums } from "../utils/enum";
import { getUserDoc, updateUserDoc, getUserDocs, deletePartnerDoc, deletePartner } from "../utils/firebase";
import { AccountItem } from "../components/account-item";
import { Loading, ModalConfirm } from "../components/modal";

const defaultUser = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    photoUrl: "",
    banned: false,
}
export const ClientProfile = () => {
    const navigate = useNavigate();
    const {userId} = useParams();
    const [user, setUser] = useState(defaultUser);
    const {firstName, lastName, emailAddress, photoUrl, banned, businessName, type, id} = user;
    const [modalCreate, setModalCreate] = useState("");
    const [modal, setModal] = useState("");
    const [userExists, setUserExists] = useState(false);
    const [events, setEvents] = useState([]);
    const isPartner = type === "partner";
    const [modalConfirm, setModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDeleteHandler = async() => {
        setModalConfirm(false)
        setLoading(true);

        const {data: [response, result]} = await deletePartnerDoc({id: id});
        if (response === "success") {
            await deletePartner({id: id});
            setLoading(false);
            navigate("/partners");
            return;
        }
        setLoading(false);
        setModal(result);
    }

    useEffect(() => {
        async function get() {
            const [response, result] = await getUserDoc(userId);
            if (response === "success") {
                setUserExists(true);
                setUser(result);

                // const docs = await getUserEventDocs(userId);
                // setEvents(docs);
            } else {
                setModal(result);
            }
        }
        
        if (userId) {
            get();
        } else {
            setModalCreate("");
        }
    }, []);

    const onBanHandler = async() => {
        if (!userExists) {
            return;
        }

        setModalCreate("loading");
        const res = await updateUserDoc(userId, {banned: !banned});
        setModalCreate("");
        if (res === "success") {
            setUser({...user, banned: !banned});
        } else {
            setModal(res);
        }
    }

    return (
        <Fragment>
            {loading ? <Loading/> : ""}
            {modalConfirm ? <ModalConfirm message={Enums.DeletePartner} yes="Yes" no="No" onNoHandler={() => setModalConfirm(false)} onYesHandler={onDeleteHandler} danger={true}/> : ""}
            {modal !== "" ? <div className="modal custom-modal has-text-centered is-active">
                <div className="modal-background" onClick={() => setModal("")}></div>
                <div className="modal-content">
                    <header className="modal-card-head pt-6 is-borderless">
                        <p className="modal-card-title">{modal}</p>
                    </header>
                    <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    </footer>
                </div>
            </div> : ""}

            {modalCreate !== "" ? <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
                <div className="modal-content" style={{width: "400px"}}>
                    <header className="modal-card-head pt-6 is-borderless px-6">
                    </header>
                    <section className="modal-card-body px-6 has-text-centered">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </section>
                    <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                    </footer>
                </div>
            </div> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.Profile}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            {isPartner ? <Button type="button" additionalClasses="is-medium is-danger" onClick={() => setModalConfirm(true)}>Delete</Button> : ""}
                        </div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-3">
                            <figure className="image mt-6 is-rounded is-128x128" style={{marginRight: "auto", marginLeft: "auto"}}>
                                <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                            </figure>
                        </div>
                    </div>
                    <h1 className="has-text-centered is-size-4 has-large-text has-text-weight-bold mt-4">{isPartner ? businessName : `${firstName} ${lastName}`}</h1>
                    <p className="has-text-centered is-size-5 mt-2">{emailAddress}</p>

                    {/* <div className="has-text-centered mt-6">
                        <Button onClick={onBanHandler} type="button" additionalClasses={`is-medium ${banned ? "with-background-primary has-text-white" : "is-danger"}`}>
                            {banned ? Enums.UnbanUser : Enums.BanUser}
                        </Button>
                    </div> */}

                    {/* {events.length > 0 ?
                    <table className="table is-fullwidth is-hoverable mt-6 is-bordered is-size-5 mb-6">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Event Name</th>
                            <th>Venue</th>
                            <th>Rate</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {events.map((event, index) => {
                            const { imageFileUrl, theme, type, venue, rate, category, _package, description, id, name, status, created, uid } = event;
                            let finalRate = "Pending";
                            if (rate) {
                                const tempRate = (Math.round(parseFloat(rate) * 100) / 100).toFixed(2);
                                const parts = tempRate.toString().split(".");
                                finalRate = "Php " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
                            }
                            return (
                                <tr key={id}>
                                    <td>{index + 1}</td>
                                    <td>{name}</td>
                                    <td>
                                        <div className="mb-3">{venue}</div>
                                        <span className="tag is-success is-light mr-2">{type}</span>
                                        <span className="tag is-success is-light mr-2">{category}</span>
                                        <span className="tag is-success is-light mr-2">{theme}</span>
                                    </td>
                                    <td>{rate ? finalRate : <span className="is-italic">{finalRate}</span>}</td>
                                    <td>{format(created.toDate(), "MMMM dd, yyyy h:mma")}</td>
                                    <td>{status === "Paid" ? <span className="tag is-medium with-background-primary has-text-white">{status}</span> :
                                    status === "Unpaid" ? <span className="tag is-medium is-danger">{status}</span> :
                                    status === "Partial" ? <span className="tag is-medium is-warning">{status}</span> :
                                    <span className="tag is-medium">{status}</span>}</td>
                                    <td className="has-text-centered">
                                        <Button type="button" onClick={() => navigate(`/event/${id}`)}>View</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table> : ""} */}
                </div>
            </div>
        </Fragment>
    )
}

export const Clients = () => {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        async function get() {
            const docs = await getUserDocs("client");
            setAccounts(docs);
        };
        get();
    }, []);

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.Clients}</h1>
                <div className="columns is-multiline mt-6">
                    {accounts.map((account) => {
                            return <AccountItem key={account.id} account={account} additionalClasses="column is-desktop is-6" onClickHandler={() => navigate("/clients/" + account.id)}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}