import { Fragment, useContext, useEffect, useState } from "react";
import HomeImage from "../assets/img/home_image.png"
import GalleryImage from "../assets/img/gallery_image.png"
import Button from '../components/button';
import FormInput from "../components/form-input";
import FormTextArea from "../components/form-textarea";
import { Enums } from "../utils/enum";
import { createEventDoc, deleteEventDoc, getCollectionDocs, updateCollectionDoc, updateEventDoc, uploadFile } from "../utils/firebase";
import FormSelect from "../components/form-select";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/user-context";

const defaultEventFields = (event) => {
    return {
        rate: {
            value: event ? event.rate : '0',
            error: '',
        },
        type: {
            value: event ? event.type : 'Select',
            error: '',
        },
        category: {
            value: event ? event.category : 'Select',
            error: '',
        },
        _package: {
            value: event ? event["_package"] : 'Select',
            error: '',
        },
        theme: {
            value: event ? event.theme : 'Select',
            error: '',
        },
        venue: {
            value: event ? event.venue : 'Select',
            error: '',
        },
        description: {
            value: event ? event.description : '',
            error: '',
        },
    }
}

const defaultImageFields = {
    imageFile: null,
    imageFileName: '',
    imageFileUrl: '',
}

export const EventCreation = () => {
    const { state } = useLocation();
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [types, setTypes] = useState(state ? state.types : []);
    const [categories, setCategories] = useState(state ? state.categories : []);
    const [packages, setPackages] = useState(state ? state.packages : []);
    const [themes, setThemes] = useState(state ? state.themes : []);
    const [venues, setVenues] = useState(state ? state.venues : []);
    const [modalCreate, setModalCreate] = useState(state ? "" : "loading");
    const [formFields, setFormFields] = useState(defaultEventFields(state ? state.event : null));
    const [imageFields, setImageFields] = useState(defaultImageFields);
    const { rate, type, category, _package, theme, venue, description } = formFields;
    const { imageFile, imageFileName, imageFileUrl} = imageFields;
    const [modalDelete, setModalDelete] = useState("");

    useEffect(() => {
        async function get() {
            // setTypes(await getCollectionDocs("Types"));
            // setCategories(await getCollectionDocs("Categories"));
            // setPackages(await getCollectionDocs("Packages"));
            // setThemes(await getCollectionDocs("Themes"));
            // setVenues(await getCollectionDocs("Venues"));
            setModalCreate("");
        };
        if (!state) {
            get();
        }
    }, []);

    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: {value: value, error: ''}});
    }

    const handleFileChange = (event) => {
        const { id, files } = event.target;
        if (!files[0]) {
            return;
        }

        setImageFields({...imageFields, imageFile: files[0]});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (type.value === "Select") {
            type.error = "Required field.";
            setFormFields({...formFields, type: {...type}});
            return;
        }

        if (category.value === "Select") {
            category.error = "Required field.";
            setFormFields({...formFields, category: {...category}});
            return;
        }

        if (_package.value === "Select") {
            _package.error = "Required field.";
            setFormFields({...formFields, _package: {..._package}});
            return;
        }

        if (theme.value === "Select") {
            theme.error = "Required field.";
            setFormFields({...formFields, theme: {...theme}});
            return;
        }

        if (venue.value === "Select") {
            venue.error = "Required field.";
            setFormFields({...formFields, venue: {...venue}});
            return;
        }

        setModalCreate("loading");

        const data = {};
        if (imageFile) {
            data["imageFileName"] = imageFile.name;
            const [response, result] = await uploadFile(currentUser.uid, imageFile);
            if (response === "success") {
                data["imageFileUrl"] = result;
            } else {
                setModalCreate("");
                alert(result);
                return;
            }
        }
        const keys = Object.keys(formFields);
        for (let i = 0; i < keys.length; i++) {
            const field = keys[i];
            data[field] = formFields[field].value;
        }

        let response;
        if (state && state.event) {
            response = await updateEventDoc(state.event.id, data);
        } else {
            response = await createEventDoc(data);
        }
        if (response === "success") {
            navigate("/dashboard/events");
            return;
        }
        setModalCreate("");
        alert(response);
    }

    const onDeleteHandler = async() => {
        setModalDelete("");
        setModalCreate("loading");
        const res = await deleteEventDoc(state.event.id);
        setModalCreate("");
        if (res === "success") {
            navigate("/dashboard/events");
        } else {
            alert(res);
        }
    }

    return (
        <Fragment>
            {modalDelete !== "" ? <div className="modal custom-modal has-text-centered is-active">
                <div className="modal-background"></div>
                <div className="modal-content">
                    <header className="modal-card-head pt-6 is-borderless">
                        <p className="modal-card-title">{modalDelete}</p>
                    </header>
                    <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                        <button className="button" onClick={() => setModalDelete("")}>No</button>
                        <button className="button is-danger" onClick={onDeleteHandler}>Yes</button>
                    </footer>
                </div>
            </div> : ""}

            {modalCreate !== "" ? <div className="modal custom-modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content" style={{width: "400px"}}>
                    <header className="modal-card-head pt-6 is-borderless px-6">
                    </header>
                    <section className="modal-card-body px-6 has-text-centered">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </section>
                    <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                    </footer>
                </div>
            </div> : ""}
            <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
                <div className="column is-12">
                    <div className="columns is-vcentered mt-2 mb-6">
                        <div className="column is-6 p-0">
                            <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary">{Enums.EventPackage}</h1>
                        </div>
                        <div className="column is-6 p-0 has-text-right">
                            {state && state.event ? <Button type="button" additionalClasses="is-medium is-danger" onClick={() => {setModalDelete("Would you like to delete event package?")}}>Delete</Button> : ""}
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='columns is-multiline'>
                            <div className="column is-12">
                                <label>Image</label>
                                <div className="file has-name is-fullwidth">
                                    <label className="file-label">
                                        <input className="file-input" type="file" onChange={handleFileChange}
                                        accept= "image/*"/>
                                        <span className="file-cta">
                                        <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                        </span>
                                        <span className="file-label">
                                            Choose a file…
                                        </span>
                                        </span>
                                        <span className="file-name">
                                            {imageFile ? imageFile.name : imageFileName}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <FormSelect error={type.error} options={[...types.map(item => item.title)]} type="text" required id="type" value={type.value} onChange={handleChange} label="Type" additionalClasses="column is-6"/>
                            <FormSelect error={category.error} options={[...categories.map(item => item.title)]} type="text" required id="category" value={category.value} onChange={handleChange} label="Category" additionalClasses="column is-6"/>
                            <FormSelect error={_package.error} options={[...packages.map(item => item.title)]} type="text" required id="_package" value={_package.value} onChange={handleChange} label="Package" additionalClasses="column is-6"/>
                            <FormSelect error={theme.error} options={[...themes.map(item => item.title)]} type="text" required id="theme" value={theme.value} onChange={handleChange} label="Theme" additionalClasses="column is-6"/>
                            <FormSelect error={venue.error} options={[...venues.map(item => item.title)]} type="text" required id="venue" value={venue.value} onChange={handleChange} label="Venue" additionalClasses="column is-6"/>
                            <FormInput type="number" min="0" step=".01" required id="rate" value={rate.value} onChange={handleChange} label="Rate" additionalClasses="column is-6"/>
                            <FormTextArea type="text" required id="description" value={description.value} onChange={handleChange} label="Description" additionalClasses="column is-12"/>
                            <Button type="submit" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth my-5">{state && state.event ? Enums.SaveChanges : "Create"}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}