import { Fragment, useContext, useEffect, useRef, useState } from "react";
import Button from '../components/button';
import FormInput from '../components/form-input';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getInvoice, updateEventDoc, createEventInvoiceDoc, getEventInvoiceDocs, getCollectionDoc, updateCollectionDoc, createInvoice, getUserDoc, sendEmail, uploadReceipt, expireInvoice } from "../utils/firebase";
import { UserContext } from "../context/user-context";
import { format } from "date-fns";
import { formatTime, rateFormatter } from "../utils/utils";
import { AccountItem } from "../components/account-item";
import { Enums } from "../utils/enum";
import QRCode from 'qrcode'
import { Configuration, EmailsApi } from '@elasticemail/elasticemail-client-ts-axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import {ModalConfirm} from "../components/modal";
import { serverTimestamp } from "firebase/firestore";
import html2canvas from "html2canvas";
import {Margin, Resolution} from 'react-to-pdf';
import generatePDF from 'react-to-pdf';

const defaultEvent = {
    start: 0,
    end: 0,
    category: {
        title: "",
    },
    venue: {
        name: "",
        description: "",
        photoUrl: "",
        id: "",
        partnerId: "",
    },
    build: [],
    halfInvoice: "",
    fullInvoice: "",
    finalInvoice: "",
    status: "",
    rate: 0,
    guests: [],
    partner: {
        businessName: "",
        address: "",
        photoUrl: "",
    },
    refund: null,
    pax: "",
    created: 0,
}

export const Event = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const targetRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const { currentUser } = useContext(UserContext);
    const [modalCreate, setModalCreate] = useState("");
    const [event, setEvent] = useState(defaultEvent);
    const {category: {title}, type, venue: {name: hallName, description, photoUrl: venuePhotoUrl, rate: venueRate, pax: venuePax}, build,
    halfInvoice, fullInvoice, finalInvoice, status, rate, guests, partner: {businessName, address, photoUrl}, start, end, refund, pax, created} = event;
    const [isLoading, setIsLoading] = useState(true);
    const [modalDelete, setModalDelete] = useState("");
    const [modal, setModal] = useState("");
    const [invoices, setInvoices] = useState([]);
    const [modalInvoice, setModalInvoice] = useState(false);
    const [modalCancel, setModalCancel] = useState("");
    const [modalComplete, setModalComplete] = useState("");
    const [modalRefund, setModalRefund] = useState(false);
    const isPartner = currentUser && currentUser.data.type === "partner";
    const isClient = currentUser && currentUser.data.type === "client";
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [user, setUser] = useState(null);
    const [showUser, setShowUser] = useState(false);
    const [before, setBefore] = useState(new Date());
    const [reference, setReference] = useState("");
    const [refError, setRefError] = useState("");
    const [mode, setMode] = useState("");
    const [modeError, setModeError] = useState("");
    const [refundAmount, setRefundAmount] = useState(0);
    const [refundAmountError, setRefundAmountError] = useState("");
    const [showDetails, setShowDetails] = useState(false);
    const [pay, setPay] = useState(false);

    // SENDS EMAIL TO USER
    const send = async(user) => {
        if (user == null) {
            return;
        }
        // TODO update message to include amount
        // TODO replace with the client data
        const {emailAddress, firstName, lastName} = user;
        const qrCode = await QRCode.toDataURL("http://localhost:3000/event/" + id);
        
        const [response, result] = await uploadReceipt(id, qrCode);
        if (response === "success") {
            const name = `${firstName} ${lastName}`;
            const config = new Configuration({
                apiKey: "AEB9104A7DC33F32FEBD841BD96CD7E497E4E28B823F9290A8F40D5B5624A1EF1E53978D81C372FA07BB72352DFAEAC4"
            });
            const emailsApi = new EmailsApi(config);
    
            const emailMessageData = {
                Recipients: [
                  { 
                    Email: emailAddress,
                    Fields: {
                      name: name,
                    }
                  }
                ],
                Content: {
                  Body: [
                    {
                      ContentType: "HTML",
                      Charset: "utf-8",
                      Content: `<div>Hi ${firstName} ${lastName}!<br/><br/>Thank you for booking with us!<br/><br/>Scan the QR Code to view your event:</div><img src=${result} alt='receipt'></img>`
                    },
                  ],
                  From: "marianomanahan1@gmail.com",
                  Subject: "Event Reservation",
                }
              };
    
              const sendBulkEmails = (emailMessageData) => {
                emailsApi.emailsPost(emailMessageData).then(() => {
                    // do nothing
                }).catch((error) => {
                    console.error(error);
                });
              };
               
              sendBulkEmails(emailMessageData);
        } else {
            console.log(result);
        }
    }

    // SETS INVOICES TO NULL WHEN THEY EXPIRE
    const checkInvoice = async(payment, res, cancelInvoice) => {
        const {status} = res;
        const field = `${payment}Invoice`;
        const {data: [response, result]} = await getInvoice({id: res[field]});
        if (response === "success") {
            if (result.status === "EXPIRED") {
                await updateEventDoc(id, {[field]: null});
                setEvent({...res, [field]: null});
            }

            if (result.status === "PAID") {
                let tempStatus = "Paid";
                if (payment === "half") {
                    tempStatus = "Partial";
                }

                if (cancelInvoice) {
                    await expireInvoice({invoiceId: cancelInvoice});
                }
                
                if (status === "Unpaid" || (status === "Partial" && tempStatus === "Paid")) {
                    await updateEventDoc(id, {status: tempStatus});
                    result["eventId"] = id;
                    await createEventInvoiceDoc(id, result);
                    const tempResu = {...res};
                    tempResu["status"] = tempStatus;
                    setEvent({...tempResu});
                }
            }
        }
    }

    useEffect(() => {
        async function get() {
            const [resp, resu] = await getCollectionDoc("Events", id);
            if (resp === "success") {
                if (!resu.data()) {
                    navigate("/dashboard?redirected");
                    return;
                }
                const {halfInvoice, finalInvoice, fullInvoice, status, deleted, clientId, partnerId, start} = resu.data();

                if ((isPartner && partnerId !== currentUser.uid) || (isClient && clientId !== currentUser.uid)) {
                    setModal("You are not authorized to view this page.");
                    return;
                }

                if (deleted) {
                    setModal("Event has been deleted.");
                    return;
                }
                setEvent(resu.data());
                const tempDate = start.toDate();
                tempDate.setDate(tempDate.getDate() - 7);
                setBefore(tempDate);


                
                const [response, result] = await getUserDoc(clientId);
                let user = null;
                if (response === "success") {
                    setUser(result);
                    user = result;
                }
                setShowUser(true);
        
                const paramsPayment = searchParams.get("payment");
                const paramsStatus = searchParams.get("status");
                if (paramsStatus === "success") {
                    const field = `${paramsPayment}Invoice`;
                    const invoiceId = resu.data()[field];
                    if (invoiceId) {
                        const {data: [response, result]} = await getInvoice({id: invoiceId});
                        if (response === "success") {
                            if (result.status === "PAID") {
                                let tempStatus = "Paid";
                                if (paramsPayment === "half") {
                                    tempStatus = "Partial";
                                }
                                
                                if (status === "Unpaid" || (status === "Partial" && tempStatus === "Paid")) {
                                    await updateEventDoc(id, {status: tempStatus});
                                    result["eventId"] = id;
                                    await createEventInvoiceDoc(id, result);
                                    const tempResu = {...resu.data()};
                                    tempResu["status"] = tempStatus;
                                    setEvent({...tempResu});
                                    setModal("Payment Success!");
                                    // send(user);
                                }
                            }
                        } else {
                            alert(result);
                        }
                    }
                }
                
                setModalCreate("");

                if (status === "Unpaid") {
                    if (fullInvoice) {
                        await checkInvoice("full", resu.data(), halfInvoice);
                    }
                    if (halfInvoice) {
                        await checkInvoice("half", resu.data(), fullInvoice);
                    }
                }

                if (finalInvoice && status === "Partial") {
                    await checkInvoice("final", resu.data(), null);
                }

                setIsLoading(false);
            } else {
                setModal(resu.message);
            }
        };
        if (currentUser) {
            get();
        }
    }, [currentUser]);

    const onClickHandler = async(payment) => {
        setPay(false);
        const paymentUrl = "https://checkout-staging.xendit.co/v2/";
        const baseRedirect = `${window.location.href}?payment=${payment}`;
        const data = {
            "externalId" : id,
            "description" : `${type} at ${businessName}`,
            "currency" : "PHP",
            "reminderTime" : 1,
            "shouldSendEmail": true,
            "payerEmail": user.emailAddress,
            "successRedirectUrl": `${baseRedirect}&status=success`,
            "failureRedirectUrl": `${baseRedirect}&status=failure`,
        }

        if (payment === "half") {
            data["amount"] = rate / 2;

            if (halfInvoice) {
                window.location.href = paymentUrl + halfInvoice;
                return;
            }
        }

        if (payment === "final") {
            data["amount"] = rate / 2;

            if (finalInvoice) {
                window.location.href = paymentUrl + finalInvoice;
                return;
            }
        }
        
        if (payment === "full") {
            data["amount"] = rate;

            if (fullInvoice) {
                window.location.href = paymentUrl + fullInvoice;
                return;
            }
        }

        setModalCreate("loading");
        const {data: [response, result]} = await createInvoice(data);
        if (response === "success") {
            const {invoiceUrl, id: invoiceId} = result;
            const data = {
                [`${payment}Invoice`]: invoiceId,
            };
            const [resp, resu] = await updateCollectionDoc("Events", id, data);
            setModalCreate("");
            if (resp === "success") {
                window.location.href = invoiceUrl;
            } else {
                alert(resu);
            }
        } else {
            setModalCreate("");
            alert(result.errorMessage);
        }
    };

    const onViewHandler = async() => {
        if (invoices.length === 0) {
            setModalCreate("loading");
            const res = await getEventInvoiceDocs(id);
            setInvoices(res);
            setModalCreate("");
        }
        setModalInvoice(true);
    }

    const onDeleteHandler = async() => {
        setModalDelete("");
        setModalCreate("loading");
        const [response, result] = await updateCollectionDoc("Events", id, {deleted: true});
        setModalCreate("");
        if (response === "success") {
            navigate("/dashboard");
            return;
        }
        setModal(result);
    }

    const onUpdateHandler = () => {
        const tempBuild = build.map((b) => {return {...b, start: b.start.toDate(), end: b.end.toDate()}});
        const doc = {
            ...event,
            id,
            build: tempBuild,
        };
        navigate("/venue", {state: doc});
    }

    const onCancelHandler = async() => {
        setModalCancel("");
        setModalCreate("loading");
        const res = await updateEventDoc(id, {status: "Cancelled", cancelledId: currentUser.uid, reason});
        setModalCreate("");
        if (res === "success") {
            setModal("You have successfully cancelled the event booking.");
            setEvent({...event, status: "Cancelled", reason});
        } else {
            alert(res);
        }
    }

    const onRefundHandler = async() => {
        setModalRefund(false);
        setModalCreate("loading");
        const now = new Date();
        const refund = {
            reference,
            mode,
            created: now,
            amount: parseFloat(refundAmount),
        }
        const res = await updateEventDoc(id, {status: "Refunded", refund});
        setModalCreate("");
        if (res === "success") {
            setModal("Successful.");
            refund["offline"] = true;
            setEvent({...event, status: "Refunded", refund});
        } else {
            alert(res);
        }
    }

    const onCompleteHandler = async() => {
        setModalComplete("");
        setModalCreate("loading");
        const res = await updateEventDoc(id, {status: "Completed"});
        setModalCreate("");
        if (res === "success") {
            setModal("Successful.");
            setEvent({...event, status: "Completed"});
        } else {
            alert(res);
        }
    }

    const onSaveHandler = async() => {
        setModalCreate("loading");
        const res = await updateEventDoc(id, {guests});
        setModalCreate("");
        if (res === "success") {
            setModal("Successful.");
        } else {
            alert(res);
        }
    }

    const onAddHandler = () => {
        const max = parseInt(pax);
        if (max == guests.length) {
            setModal("You have reached maximum number of guests to add");
            return;
        }
        guests.push("");
        setEvent({...event});
    }

    const onDownload = () => {
        const now = new Date();
        const options = {
            resolution: Resolution.HIGH,
            page: {
               margin: Margin.LARGE,
            },
            filename: `Event_Contract_${now.getTime()}.pdf`,
         };

         generatePDF(targetRef, options);
        // var printContents = document.getElementById("print");

        // html2canvas(printContents, {scale: 3}).then(function(canvas) {
        //   var myImage = canvas.toDataURL('image/png');
    
        //   var mywindow = window.open('PRINT');
    
        //   mywindow.document.write('<html><head><title>Contract</title>');
        //   mywindow.document.write('</head><body style="margin: 0 0 0 0">');
        //   mywindow.document.write('<img src="' + myImage + '" style="width: 90%; display: block; margin: 0 auto"/>');
        //   mywindow.document.write('</body></html>');
      
        //   setTimeout(() => {
        //     mywindow.document.close();
        //     mywindow.focus();
        
        //     mywindow.print();
        //     mywindow.close();
        //   }, 10)
        // })
    }

    return (
        <Fragment>
        {modalInvoice ? <div className="modal custom-modal is-active">
            <div className="modal-background" onClick={() => setModalInvoice(false)}></div>
            <div className="modal-content" style={{width: "800px", maxWidth: 100 + "%"}}>
                <header className="modal-card-head pt-6 is-borderless px-6">
                    <p className="modal-card-title has-text-weight-bold">Invoice</p>
                </header>
                <section className="modal-card-body px-6">
                    {invoices.length > 0 ? 
                    <table className="table is-fullwidth is-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Method</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((invoice) => {
                                const { amount, paymentMethod, created, id } = invoice;
                                const tempRate = (Math.round(amount * 100) / 100).toFixed(2);
                                const parts = tempRate.toString().split(".");
                                const finalRate = "Php " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
                                return (
                                    <tr key={id}>
                                        <td>{format(created.toDate(), "MMMM dd, yyyy h:mma")}</td>
                                        <td>{paymentMethod}</td>
                                        <td>{finalRate}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table> : <div className="py-6">No Invoice</div>}
                </section>
                <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                </footer>
            </div>
        </div> : ""}

        {modal !== "" ? <div className="modal custom-modal has-text-centered is-active">
            <div className="modal-background" onClick={() => setModal("")}></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">{modal}</p>
                </header>
                <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                </footer>
            </div>
        </div> : ""}

        {modalDelete !== "" ? <div className="modal custom-modal has-text-centered is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">{modalDelete}</p>
                </header>
                <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                    <button className="button" onClick={() => setModalDelete("")}>No</button>
                    <button className="button is-danger" onClick={onDeleteHandler}>Yes</button>
                </footer>
            </div>
        </div> : ""}

        {modalCancel !== "" ? <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title px-5">{modalCancel}</p>
                </header>
                <section className="modal-card-body px-6">
                    {isClient ? <p className="mb-5 notification is-warning">Note: You will only get a refund amounting to P {halfInvoice && finalInvoice || fullInvoice ? rateFormatter(rate/2) : rateFormatter(rate/4)} when you cancel this event.</p> : ""}
                    <FormInput type="text" required value={reason} onChange={(e) => {setReason(e.target.value); if (error) {setError("")}}} placeholder="Type a reason for cancellation"/>
                    {error ? <label className="has-text-danger">{error}</label> : ""}
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless">
                    <button className="button" onClick={() => setModalCancel("")}>No</button>
                    <button className="button is-danger mr-5" onClick={() => {
                        if (!reason) {
                            setError("Required field")
                        } else {
                            onCancelHandler();
                        }
                    }}>Yes</button>
                </footer>
            </div>
        </div> : ""}

        {modalRefund ? <div className="modal custom-modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title px-5">Refund Details</p>
                </header>
                <section className="modal-card-body px-6">
                    <FormInput type="text" required value={reference} onChange={(e) => {setReference(e.target.value); if (refError) {setRefError("")}}} placeholder="Reference No."/>
                    {refError ? <label className="has-text-danger">{refError}</label> : ""}
                    <FormInput additionalClasses="mt-5" type="text" required value={mode} onChange={(e) => {setMode(e.target.value); if (modeError) {setModeError("")}}} placeholder="Mode (Gcash/Cash/Bank Transfer)"/>
                    {modeError ? <label className="has-text-danger">{modeError}</label> : ""}
                    <FormInput additionalClasses="mt-5" type="number" step=".01" required value={refundAmount} onChange={(e) => {setRefundAmount(e.target.value); if (refundAmountError) {setRefundAmountError("")}}} placeholder="Amount"/>
                    {refundAmountError ? <label className="has-text-danger">{refundAmountError}</label> : ""}
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless">
                    <button className="button" onClick={() => {setModalRefund(false); setRefError(""); setModeError(""); setRefundAmount("")}}>Cancel</button>
                    <button className="button with-background-primary has-text-white mr-5" onClick={() => {
                        if (!reference) {
                            setRefError("Required field")
                        } else if (!mode) {
                            setModeError("Required field")
                        } else if (refundAmount == 0) {
                            setRefundAmountError("Required field")
                        } else {
                            onRefundHandler();
                        }
                    }}>Refund</button>
                </footer>
            </div>
        </div> : ""}

        {pay ? <div className="modal custom-modal is-active">
            <div className="modal-background" onClick={() => setPay(false)}></div>
            <div className="modal-content">
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title px-5">Payment Options</p>
                </header>
                <section className="modal-card-body px-6">
                    <div className="columns has-text-centered">
                    {status === "Unpaid" ? <div className="column"><button className="button is-large" onClick={() => onClickHandler("half")}>P {rateFormatter(rate / 2)}</button><p className="mt-2">Pay in half</p></div> : ""}
                    <div className="column">
                    <button className="button is-large with-background-primary has-text-white" onClick={() => onClickHandler(status === "Partial" ? "final" : "full")}>P {rateFormatter(rate)}</button>
                    <p className="mt-2">Pay {status === "Partial" ? "remaining balance" : "in full"}</p>
                    </div>
                    </div>
                    
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless">
                </footer>
            </div>
        </div> : ""}

        {modalComplete !== "" ? <ModalConfirm message={modalComplete} yes="Yes" no="Cancel" onNoHandler={() => {setModalComplete("")}} onYesHandler={onCompleteHandler}/> : ""}

        {modalCreate !== "" ? <div className="modal custom-modal is-active">
        <div className="modal-background"></div>
            <div className="modal-content" style={{width: "400px"}}>
                <header className="modal-card-head pt-6 is-borderless px-6">
                </header>
                <section className="modal-card-body px-6 has-text-centered">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                </footer>
            </div>
        </div> : ""}
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <div className="columns is-vcentered">
                    <div className="column p-0 is-12">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{type} at {businessName}</h1>
                        <span>{status}</span>
                    </div>
                </div>
                <div className="columns mt-6 mb-6 is-multiline">
                <div className="column is-6 mt-5">
                        <label>Category</label>
                        <p className="is-size-4 has-text-weight-semibold">{title}</p>
                    </div>
                    
                    <div className="column is-6 mt-5">
                        <label>Type</label>
                        <p className="is-size-4 has-text-weight-semibold">{type}</p>
                    </div>
                    
                    <div className="column is-6 mt-5">
                        <label>Pax</label>
                        <p className="is-size-4 has-text-weight-semibold">{pax}</p>
                    </div>

                    <div className="column is-16 mt-5">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <label>Venue</label>
                                    <p className="is-size-4 has-text-weight-semibold mb-0">{businessName}</p>
                                    <p className="is-size-5">{address}</p>
                                </div>
                            </div>
                            <div className="media-right">
                                <figure className="is-rounded image is-64x64">
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                            </div>
                        </article>
                    </div>

                    {isClient || !showUser ? "" :
                    <Fragment>
                        <hr className="custom mx-3 my-6"/>
                        {user ?
                            <AccountItem key={user.id} account={user} additionalClasses="column is-desktop is-12" onClickHandler={() => navigate("/clients/" + user.id)}/> :
                            <div className="column is-desktop is-12"><span className="tag is-danger is-block has-text-centered is-size-4" style={{height: 100 + "px", lineHeight: 100 + "px"}}>User no longer exists in the database.</span></div>
                        }
                    </Fragment>}

                    <hr className="custom mx-3 my-6"/>
                    <div className="column is-12">
                        <Button type="button" additionalClasses="is-fullwidth is-medium is-borderless with-text-primary has-text-weight-bold" onClick={() => setShowDetails(!showDetails)}>{showDetails ? "Hide Details" : "Show Details"}</Button>
                    </div>
                    {showDetails ? <Fragment>{build.map((b, index) => {
                    const {start, end, foodItems, talents, foodItemsTotal, hostTotal, hours, packageItem, packageTotal} = b;
                    const venueTotal = venueRate * hours;
                    let subtotal = foodItemsTotal + hostTotal + venueTotal;
                    if (packageTotal) {
                        subtotal = foodItemsTotal + hostTotal + packageTotal;
                    }

                    return (
                            <div key={`i_${index}`} className="columns card is-multiline py-3 px-4 mb-6">
                            <div className="column is-12 is-size-3 has-text-centered has-text-weight-bold pb-0 mt-5">
                                {format(start.toDate(), "MMMM dd, yyyy")}
                            </div>
                            
                            <div className="column is-12 is-size-5 has-text-centered pt-0">
                                {format(start.toDate(), "h:mma")} - {format(end.toDate(), "h:mma")}
                            </div>

                                <div className="column is-12 is-size-5 pb-0 mt-6">
                                <div className="columns is-mobile">
                                    <div className="column is-6 has-text-weight-bold">
                                        EVENT HALL
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold">
                                        {packageTotal ? "" : `P ${rateFormatter(venueTotal)}`}
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12">
                                <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4">
                                    <div className="column is-1">
                                        <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                            <img src={venuePhotoUrl ? venuePhotoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                        </figure>
                                    </div>
                                    <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                        <p>{hallName}</p>
                                        <span className="tag">Max {venuePax} pax</span>
                                    </div>
                                    <div className="column is-2 has-text-right has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(venueRate)}`}</div>
                                    <div className="column is-3 has-text-right has-text-centered-mobile">{packageTotal ? "" : `×${hours} hours`}</div>
                                    <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(packageTotal ? 0 : venueTotal)}`}</div>
                                </div>
                            </div>

                            {packageItem != null ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            PACKAGE
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            {isPartner ? "" : `P ${rateFormatter(packageTotal)}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-1">
                                            <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                <img src={packageItem.photoUrl ? packageItem.photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                            </figure>
                                        </div>
                                        <div className={`column has-text-weight-medium has-text-centered-mobile ${isPartner ? "is-12" : "is-3"}`}>
                                            <p>{packageItem.name}</p>
                                            <span className="tag">Max {packageItem.pax} pax</span>
                                        </div>
                                        {isPartner ? "" : <Fragment>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.price)}</p>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×4 hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageItem.price)}</div>
                                        </Fragment>}

                                        <div className="column is-12 is-size-6" style={{whiteSpace: "pre-line"}}>
                                            {packageItem.description}
                                        </div>
                                    </div>
                                </div>
                                {hours > 4 ? <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-4 has-text-weight-medium has-text-centered-mobile">
                                            <p>Additional Fee</p>
                                        </div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.rate)}</p>
                                            <span className="tag" data-tooltip="Applies to events with total time exceeding 4 hours">
                                                per hour
                                                <FontAwesomeIcon size="xs" className="ml-1 fa-icon" icon={faInfo}/>
                                            </span>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×{hours - 4} hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageTotal - packageItem.price)}</div>
                                    </div>
                                </div> : ""}
                            </Fragment>: ""}
                            {foodItems.length > 0 ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            FOOD
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(foodItemsTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                {foodItems.map((item, i) => {
                                    const {name, quantity, price, photoUrl, pax, servingTime} = item;
                                    const subtotal = price * quantity;
                                    return (
                                        <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`food_${i}`}>
                                            <div className="column is-1">
                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                </figure>
                                            </div>
                                            <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                                <p>{name}</p>
                                                <span className="tag">{pax} pax</span>
                                                <span className="tag ml-3">{servingTime}</span>
                                            </div>
                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(price)}</div>
                                            <div className="column is-3 has-text-right has-text-centered-mobile">×{quantity}</div>
                                            <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment> : ""}

                            {talents.length > 0 ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            HOST
                                        </div>
                                        {isPartner ? "" : <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(hostTotal)}
                                        </div>}
                                    </div>
                                </div>
                                <div className="column is-12">
                                {talents.map((talent, index) => {
                                    const {name, photoUrl, rate, start, end, hours} = talent;
                                    const subtotal = rate * hours;
                                    return (
                                        <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`talent_${index}`}>
                                            <div className="column is-1">
                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                </figure>
                                            </div>
                                            <div className={`column has-text-weight-medium has-text-centered-mobile ${isPartner ? "is-12" : "is-3"}`}>{name}</div>
                                            {isPartner ? "" : <Fragment>
                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(rate)}</div>
                                            <div className="column is-3 has-text-right has-text-centered-mobile">{hours} hours<br/><span className="is-size-6">{formatTime(start)} - {formatTime(end)}</span></div>
                                            <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                            </Fragment>}
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment> : ""}
                            
                            {isPartner ? "" : <div className="column is-12 is-size-4">
                                <div className="columns notification">
                                    <div className="column is-6 has-text-weight-bold has-text-centered-mobile">
                                        TOTAL
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold has-text-centered-mobile">
                                        P {rateFormatter(subtotal)}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    )
                })}
                    {isPartner ? "" : <div className="column is-12 mt-5 px-6">
                        <span className="is-size-4 has-text-weight-bold">TOTAL...</span>
                        <span className="is-size-4 has-text-weight-bold is-pulled-right">Php {rateFormatter(rate)}</span>
                    </div>}
                    </Fragment> : ""}

                    {status === "Refunded" && !isClient || (status === "Unpaid" || status === "Partial" || status === "Paid") && isPartner ? "" : <hr className="custom mx-3 my-6"/>}
                {isClient ? <Fragment>
                    <div className="column is-12">
                        {status === "Partial" ?
                        <div className="notification is-warning px-5 py-4"><span className="has-text-weight-bold">Important:</span> Failure in paying the remaining balance before {format(before, "MMMM dd, yyyy")}, will result in event cancellation.</div>
                        : status === "Unpaid" ? <div className="notification is-danger px-5 py-4"><span className="has-text-weight-bold">Cancellation Policy:</span> In the event that you cancel your booking or failed to pay fully before {format(before, "MMMM dd, yyyy")}, only half of your payment made will be refunded. Pay with caution.</div> : ""
                        }
                        {refund ? <div className="notification is-warning px-5 py-4">Refunded <span className="has-text-weight-bold">P {rateFormatter(refund.amount)}</span> on {refund.offline ? format(refund.created, "MMMM dd, yyyy h:mma") : format(refund.created.toDate(), "MMMM dd, yyyy h:mma")} via <span className="has-text-weight-bold">{refund.mode}</span> with reference #<span className="has-text-weight-bold">{refund.reference}.</span> {isClient || isPartner ? "" : <span className="is-pulled-right is-clickable" onClick={() => {setModalRefund(true); setMode(refund.mode); setReference(refund.reference); setRefundAmount(refund.amount.toString())}}>Edit</span>}</div>
                        : ""}
                    </div>
                </Fragment> : ""}

                    {isClient ? 
                    status === "Unpaid" || status === "Partial" ?
                    <Fragment>
                        <div className="column is-12">
                            <Button type="button" additionalClasses={`is-large is-fullwidth with-background-primary has-text-white ${isLoading ? "is-loading": ""}`} onClick={() => setPay(true)}>
                                Pay now
                            </Button>
                        </div>
                        
                            {status === "Partial" ? 
                            <Fragment>
                                <div className="column is-6">
                                    <Button type="button" additionalClasses="is-medium is-fullwidth" onClick={onViewHandler}>
                                        View Invoice
                                    </Button>
                                </div>
                                <div className="column is-6">
                                    <Button type="button" additionalClasses="is-medium is-fullwidth is-danger" onClick={() => setModalCancel("Would you like to cancel event booking?")}>
                                        Cancel
                                    </Button>
                                </div>
                            </Fragment>
                            : <Fragment>
                                <div className="column is-6">
                                    <Button type="button" additionalClasses="is-medium is-fullwidth" onClick={onUpdateHandler}>Update</Button>
                                </div>
                                <div className="column is-6">
                                    <Button type="button" additionalClasses="is-medium is-fullwidth is-danger" onClick={() => setModalDelete("Would you like to delete event booking?")}>Delete</Button>
                                </div>
                            </Fragment>}
                        </Fragment>
                    : status === "Paid" ?
                    <Fragment>
                    <div className="column is-6">
                        <Button type="button" additionalClasses="is-medium is-fullwidth" onClick={onViewHandler}>
                            View Invoice
                        </Button>
                    </div>
                    <div className="column is-6">
                        <Button type="button" additionalClasses="is-medium is-fullwidth is-danger" onClick={() => setModalCancel("Would you like to cancel event booking?")}>
                            Cancel
                        </Button>
                    </div>
                </Fragment> : ""
                    : ""}
                    
                    {status === "Cancelled" ? <div className="column is-12">
                            <div className="notification is-warning px-5 py-4">
                                <span className="has-text-weight-semibold mr-2">Cancelled due to:</span>{event.reason}
                            </div>
                        </div> : ""}

                    {status === "Refunded" ? "" : <Fragment>
                        {isClient ? "" : status !== "Completed" && status !== "Cancelled" && status !== "Refunded" && currentUser.data.type === "admin" ? 
                            <div className="column">
                                {end !== 0 && currentDate.getTime() >= end.toDate().getTime() ?
                                <div className="column is-12">
                                    <Button type="button" additionalClasses="has-text-white with-background-primary is-medium is-fullwidth" onClick={() => setModalComplete("Would you like to complete event booking?")}>Complete</Button>
                                </div>
                                :
                                <div className="column is-12">
                                <Button type="button" additionalClasses="is-danger is-medium is-fullwidth" onClick={() => setModalCancel("Would you like to cancel event booking?")}>Cancel</Button>
                                </div>
                                }
                            </div> : status === "Cancelled" && currentUser.data.type === "admin" ? 
                                <div className="column is-12">
                                    <Button type="button" additionalClasses="has-text-white with-background-primary is-medium is-fullwidth" onClick={() => setModalRefund(true)}>Refund</Button>
                                </div> : ""
                        }
                    </Fragment>}

                    {!isPartner && status !== "Refunded" && status !== "Cancelled" ? <div className="column is-12">
                        <Button type="button" additionalClasses="is-medium is-fullwidth is-info" onClick={onDownload}>Download Contract</Button>
                    </div> : ""}

                {isClient && (status === "Paid" || status === "Partial") ? <Fragment>
                            <hr className="custom mx-3 my-6"/>
                            <div className="column is-12">
                                <h1 className="is-size-2 has-text-weight-bold">{Enums.Guests} ({guests.length})</h1>
                            </div>
                            {guests.map((guest, index) => {
                                    return <FormInput key={`guest_${index}`} type="text" onRemoveHandler={() => {guests.splice(index, 1); setEvent({...event})}} required value={guest} onChange={(e) => {guests[index] = e.target.value; setEvent({...event})}} label="Guest Name *" additionalClasses="column is-12"/>
                                })}
                            <div className="column is-12 mt-5 has-text-right">
                                <Button type="button" additionalClasses="is-medium mr-3" onClick={onAddHandler}>{Enums.AddGuest}</Button>
                                <Button type="button" additionalClasses="is-medium has-text-white with-background-primary" onClick={onSaveHandler}>{Enums.SaveChanges}</Button>
                            </div>
                            </Fragment> : ""}

                    {!isClient ? <Fragment>
                        <hr className="custom mx-3 my-6"/>
                            <div className="column is-12">
                                <h1 className="is-size-2 has-text-weight-bold">{Enums.Guests} ({guests.length})</h1>
                            </div>
                            {guests.map((guest, index) => {
                                return <p key={`guest_${index}`} className="is-size-4 has-text-weight-medium column is-6">{index + 1}. {guest}</p>
                                })}
                    </Fragment> : ""}
                </div>
            </div>
        </div>

        {created != 0 && user != null ? <div ref={targetRef} id='print' className="is-size-5 has-text-justified" style={{margin: "0 auto", width: 900 + "px"}}>
        <p>This Agreement is made and entered into this <b>{format(created.toDate(), "do")} day of {format(created.toDate(), "MMMM yyyy")}</b>, by and between <b>ALM EVENTS AND TALENT MANAGEMENT</b> and <b>{user.firstName} {user.lastName}</b> at {user.address ? <span className="is-capitalized">{user.address}</span> : "N/A"}.</p><br/>
                    <p className="has-text-centered has-text-weight-bold is-size-4">ACKNOWLEDGEMENTS</p><br/>
                    <p style={{whiteSpace: "pre-line"}}>The parties agree that ALM EVENTS AND TALENTS MANAGEMENT will provide services as described in the attached proposal.<br/><br/>
WHEREAS, ALM EVENTS AND TALENTS MANAGEMENT as an independent contractor, and not as an employee, to perform certain services for the incoming event.<br/><br/>
NOW, THEREFORE, for and in consideration of the mutual covenants contained herein and for other good and valuable consideration, the receipt of which is hereby acknowledged, the ALM EVENTS AND TALENT MANAGEMENT do hereby agree as follows:<br/><br/>
<b>A. PAYMENT:</b><br/>
Payments made will reserve the services. The Services may not be reserved if the Client fails to make the payments. Clients may pay by e-wallet or credit card. If paying by credit card, the client must fill out a Credit Card Authorization Form.<br/><br/>
<b>B. DEPOSIT/CANCELLATION</b><br/>
A deposit of Partial Fifty Percent (50%) of the total proposal is due upon acceptance of the contract by the Client. The final Fifty Percent (50%) payment must be paid 7 days before the event bringing the total deposits to One Hundred Percent (100%) of the total fee. If the client fails to pay 7 days before the event the 50% of the partial payment will go to the client and the other 50% of the partial payment will go to the ALM Events and Talent Management.<br/><br/>
<b>C. DAMAGE:</b><br/>
The client agrees that if guests or other vendors (not associated with ALM Events and Talent Management) cause damage to any services provided by ALM Events and Talent Management, the Client will assume the responsibility of said damages.<br/><br/>
<b>D. PICTURES AND MARKETING:</b><br/>
The client agrees that ALM Events and Talent Management may take pictures of the services provided by ALM Events and Talent Management to be used for Marketing and/or Advertising without prior notice.<br/><br/>
<b>E. Corkage Fee</b><br/>
200 per bottle of wine<br/>
500 per liters of hard drinks<br/>
200 per case of beer <br/>
500 lechon<br/>
Extra putahe 200 <br/><br/>
By Client of this agreement, Client expressly grants consent to ALM Events and Talent Management.<br/><br/>

<p className="has-text-centered">IN WITNESS WHEREOF the parties have executed this agreement.</p>

<div className="columns is-centered mt-6 is-mobile">
    <div className="has-text-centered column">
    <b>{user.firstName.toUpperCase()} {user.lastName.toUpperCase()}</b>
    <p>Client</p>
    </div>
    <div className="has-text-centered" style={{width: 450 + "px", display: "inline-block"}}>
    <b>ALVIN LESTER MAGANA</b>
    <p>CEO</p>
    </div>
</div>
</p>
      </div> : ""}
        </Fragment>
    )
}