import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user-context";
import { signOutUser } from "../utils/firebase";
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Enums } from "../utils/enum"

const SideNavigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [toggle, setToggle] = useState(false);
  const [modalSignOut, setModalSignOut] = useState("");
  const {firstName, lastName, photoUrl, businessName} = currentUser ? currentUser.data : {};
  const isAdmin = currentUser && currentUser.data.type === "admin";
  const isClient = currentUser && currentUser.data.type === "client";
  const isPartner = currentUser && currentUser.data.type === "partner";

  useEffect(() => {
    document.body.parentElement.classList.add("is-clipped");
  }, []);

  const signOutHandler = async() => {
    setModalSignOut("");
    signOutUser();
    navigate('/signin');
  }

  const onMenuToggle = () => {
    setToggle(!toggle);
  }

  const onNavigate = (link) => {
    navigate(link);
    setToggle(false);
  }

  const menu =
    <div className="menu pb-6">
      <p className="menu-label with-text-accent">{Enums.General}</p>
      <ul className="menu-list">
        <li><a className={`${pathname === "/" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/")}><i className="fas fa-home"></i> {Enums.Homepage}</a></li>
        <li><a className={`${pathname === "/dashboard" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/dashboard")}><i className="fas fa-home"></i> {Enums.Dashboard}</a></li>
        {/* {isClient ?
            <li><a className={`${pathname === "/browse" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/browse")}><i className="fas fa-home"></i> {Enums.Browse}</a></li>
        : ""} */}

        {isPartner ? <Fragment>
            <p className="menu-label with-text-accent mt-6">{Enums.EventManagement}</p>
            <li><a className={`${pathname === "/halls" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/halls")}><i className="fas fa-home"></i> {Enums.EventHall}</a></li>
            <li><a className={`${pathname === "/catering" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/catering")}><i className="fas fa-home"></i> {Enums.Catering}</a></li>
            
            <p className="menu-label with-text-accent mt-6">{Enums.ReportMonitoring}</p>
            <li><a className={`${pathname.startsWith("/calendar") ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/calendar")}><i className="fas fa-bell"></i> {Enums.Calendar}</a></li>
            <li><a className={`${pathname === "/analytics" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/analytics")}><i className="fas fa-home"></i> {Enums.Analytics}</a></li>
        </Fragment> : ""}


        {isAdmin ?
          <Fragment>
            <li><a className={`${pathname === "/slideshow" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/slideshow")}><i className="fas fa-home"></i> {Enums.Slideshow}</a></li>
            
            <p className="menu-label with-text-accent mt-6">{Enums.EventManagement}</p>
            <li><a className={`${pathname.startsWith("/categories") ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/categories")}><i className="fas fa-bell"></i> {Enums.Categories}</a></li>
            <li><a className={`${pathname.startsWith("/hosts") ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/hosts")}><i className="fas fa-bell"></i> {Enums.Hosts}</a></li>
            <li><a className={`${pathname === "/packages" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/packages")}><i className="fas fa-home"></i> {Enums.Packages}</a></li>
            
            <p className="menu-label with-text-accent mt-6">{Enums.ReportMonitoring}</p>
            <li><a className={`${pathname.startsWith("/calendar") ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/calendar")}><i className="fas fa-bell"></i> {Enums.Calendar}</a></li>
            <li><a className={`${pathname === "/analytics" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/analytics")}><i className="fas fa-home"></i> {Enums.Analytics}</a></li>
            <li><a className={`${pathname === "/sales" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/sales")}><i className="fas fa-home"></i> {Enums.Sales}</a></li>
            <li><a className={`${pathname === "/system-logs" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/system-logs")}><i className="fas fa-home"></i> {Enums.SystemLogs}</a></li>
            <li><a className={`${pathname === "/reports" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/reports")}><i className="fas fa-home"></i> {Enums.Reports}</a></li>
            
            <p className="menu-label with-text-accent mt-6">{Enums.AccountManagement}</p>
            <li><a className={`${pathname === "/clients" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/clients")}><i className="fas fa-bell"></i> {Enums.Clients}</a></li>
            <li><a className={`${pathname === "/partners" ? "is-active" : ""} has-text-weight-medium`} onClick={() => navigate("/partners")}><i className="fas fa-bell"></i> {Enums.Partners}</a></li>
          </Fragment>
        : ""}
        <li><a className="is-hidden-desktop has-text-weight-medium" onClick={() => setModalSignOut("Would you like to signout?")}><i className="fas fa-bell"></i> {Enums.SignOut}</a></li>
      </ul>
    </div>

  const nav =
    <nav className="navbar mt-3 px-5 mb-6 has-background-transparent" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" onClick={() => onNavigate("/")}>
          {/* <img src={logo} className='mr-6'/> */}
          <div>
            <h1 className="with-text-primary"><span className="is-size-1 is-uppercase has-text-weight-bold">{Enums.AppName}</span>
                <span className='is-size-7 is-uppercase is-pulled-right mt-4 ml-3' style={{lineHeight: 14 + "px"}}>Event<br/>Management<br/>System<br/></span>
                </h1>
          </div>
        </a>
        <a onClick={onMenuToggle} role="button" className="navbar-burger is-hidden-desktop" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu is-hidden-desktop ${toggle ? "is-active" : ""}`}>
        {menu}
      </div>
    </nav>;

  return (
    <Fragment>
      {modalSignOut !== "" ? <div className="modal custom-modal has-text-centered is-active">
        <div className="modal-background"></div>
          <div className="modal-content">
              <header className="modal-card-head pt-6 is-borderless">
                  <p className="modal-card-title">{modalSignOut}</p>
              </header>
              <footer className="modal-card-foot has-text-centered is-block pb-5 is-borderless">
                  <button className="button" onClick={() => setModalSignOut("")}>No</button>
                  <button className="button is-success" onClick={signOutHandler}>Yes</button>
              </footer>
          </div>
      </div> : ""}
      <div className='columns is-desktop is-gapless'>
        <div className='sidebar column is-4-tablet is-3-desktop is-hidden-touch with-background-primary'  style={{height :"100vh", overflow: "auto"}}>
          {nav}
          <aside className="menu mx-5">
            <div style={{minHeight: `calc(100vh - 266px)`}}>
              {menu} 
            </div>

            <article className="media my-4">
              <div className="media-left">
                <figure className="is-rounded image is-64x64">
                  <img className="is-clickable" src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image" onClick={() => onNavigate("/profile")}/>
                </figure>
              </div>
              <div className="media-content">
                <div className="content">
                  <p className="is-uppercase has-text-weight-bold has-text-white is-size-4 m-0 is-clickable ellipsize" onClick={() => onNavigate("/profile")}>{isClient || isAdmin ? `${firstName} ${lastName}` : businessName}</p>
                  <p className="has-text-white is-size-6 is-clickable is-inline-block mt-1" onClick={() => setModalSignOut("Would you like to signout?")}>{Enums.SignOut}</p>
                </div>
              </div>
            </article>
          </aside>
        </div>
        
        <div className='column' style={{height :"100vh", overflowY: "scroll", overflowX: "hidden"}}>
          <div className='is-hidden-desktop'>
            {nav}
          </div>
          <Outlet/>
        </div>
      </div>
    </Fragment>
  )
}

export default SideNavigation;
