import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import { useState } from 'react'

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const MyCalendar = ({events, onSelectSlot, views = ['month', 'week', 'day', 'agenda'], date = new Date(), onSelectEvent = () => {}, height = 800}) => {
  const dayPropGetter = (calendarDate) => {
    if (date.getTime() > calendarDate.getTime()) {
      return {className: "disabled"};
    }
  }
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      selectable={onSelectSlot ? true : false}
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      endAccessor="end"
      step={60}
      defaultDate={date}
      defaultView={views[0]}
      timeslots={1}
      style={{ height: height }}
      views= {views}
      dayPropGetter = {dayPropGetter}
    />
  )
}

export default MyCalendar;