import { initializeApp } from 'firebase/app'
import { getAuth, signInWithRedirect, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, updatePassword } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, addDoc, collection, query, where, getDocs, orderBy, serverTimestamp, deleteDoc, updateDoc, collectionGroup, arrayRemove, arrayUnion, endAt, startAt } from 'firebase/firestore'
import { getDownloadURL, uploadBytes, getStorage, ref, uploadString } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Timestamp } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCKdoN5SNe1X8PHJz3CqwnhqAIfjlohi3U",
    authDomain: "event-wise.firebaseapp.com",
    projectId: "event-wise",
    storageBucket: "event-wise.appspot.com",
    messagingSenderId: "119927235443",
    appId: "1:119927235443:web:f9bb106490a2c8b9c9db06"
  };
  
  const app = initializeApp(firebaseConfig);
  export const storage = getStorage();
  const functions = getFunctions();

  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    prompt: "select_account"
  });

  export const auth = getAuth();
  export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
  export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
  
  export const db = getFirestore();

  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
  }

  export const signInUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
  }

  export const signOutUser = async () => await signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

  export const resetPassword = async (email) => {
    return await sendPasswordResetEmail(auth, email);
  }

  export const updateUserPassword = async(password) => {
    var response = "success";
    try {
      await updatePassword(auth.currentUser, password);
    } catch (error) {
      response = error.message;
    }
    return response;
  }

  export const createUserDocument = async (userAuth, additionalInformation = {}) => {
    if (!userAuth) return;
    
    const userDocRef = doc(db, 'Users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);
    var data = userSnapshot.data();

    if (!userSnapshot.exists()) {
      delete additionalInformation.password;

      data =  {
        ...additionalInformation,
        "created": serverTimestamp(),
      };

        try {
            await setDoc(userDocRef, data)
        } catch (error) {
            console.log("error", error.message);
        }
    }

    userAuth["data"] = data;

    return userDocRef;
  }

  export const updateUserDocument = async(uid, additionalInformation = {}) => {
    const path = `Users/${uid}`;
    const ref = doc(db, path);
    let res = "success";

    try {
      await updateDoc(ref, additionalInformation);
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const getUserDocs = async (type) => {
    const ref = collection(db, "Users");
    const res = [];
    const q = query(ref, where("type", "==", type), orderBy("created"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const createEventDoc = async(additionalInformation = {}) => {
    const ref = collection(db, "Events");
    let response = "success";
    const data =  {
      ...additionalInformation,
      "created": serverTimestamp(),
    };

    try {
      await addDoc(ref, data);
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateEventDoc = async(id, additionalInformation = {}) => {
    const path = `Events/${id}`;
    const ref = doc(db, path);
    let res = "success";

    try {
      await updateDoc(ref, additionalInformation);
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const deleteEventDoc = async (id) => {
    const path =  `Events/${id}`;
    const ref = doc(db, path);
    let response = "success";

    try {
      await deleteDoc(ref);
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const getEventDocs = async () => {
    const ref = collection(db, "Events");
    const res = [];
    const q = query(ref, orderBy("created"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getEventDocsByFieldValue = async (field, value) => {
    const ref = collection(db, "Events");
    const res = [];
    const q = query(ref, where(field, "==", value));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getEventDocsByCategoryAndType = async (category, type) => {
    const ref = collection(db, "Events");
    const res = [];
    const q = query(ref, where("category", "==", category), where("type", "==", type), orderBy("venue"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }
  
  export const getCollectionDocs = async(col, orderField, orderDir, whereArray = []) => {
    const ref = collection(db, col);
    let q;
    if (whereArray.length > 0) {
      q = query(ref, where("deleted", "==", false), orderBy(orderField, orderDir), ...whereArray);
    } else {
      q = query(ref, where("deleted", "==", false), orderBy(orderField, orderDir));
    }

    try {
      const res = [];
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id});
      });

      return ["success", res];
    } catch (error) {
      return ["error", error];
    }
  }

  export const getYearEvents = async(year, whereArray) => {
    const start = new Date(parseInt(year), 0, 1);
    const end = new Date(parseInt(year), 11, 31);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
  
    const ref = collection(db, "Events");
    let q;
    if (whereArray.length > 0) {
      q = query(ref, orderBy("start"), endAt(end), startAt(start), ...whereArray);
    } else {
      q = query(ref, orderBy("start"), endAt(end), startAt(start));
    }

    try {
      const res = [];
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id});
      });

      return ["success", res];
    } catch (error) {
      return ["error", error];
    }
  }

  export const getCollectionDoc = async(col, id) => {
    const ref = doc(db, col, id);

    try {
      const result = await getDoc(ref);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const createCollectionDoc = async(col, additionalInformation = {}) => {
    const ref = collection(db, col);

    try {
      const data =  {
        ...additionalInformation,
        "created": serverTimestamp(),
        "deleted": false,
      };
      if (col === "Catering" || col === "Unavailable" || col === "Halls") {
        data["partnerId"] = auth.currentUser.uid;
      };
      const result = await addDoc(ref, data);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const updateCollectionDoc = async(col, id, additionalInformation = {}) => {
    const ref = doc(db, col, id);

    try {
      const data =  {
        ...additionalInformation,
        "updated": serverTimestamp(),
      };
      const result = await updateDoc(ref, data);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const deleteCollectionDoc = async(col, id) => {
    const ref = doc(db, col, id);

    try {
      const result = await deleteDoc(ref);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const uploadFile = async(path, file) => {
    const date = new Date();
    const storageRef = ref(storage, `${path}/${date.getTime()}_${file.name}`);

    try {
      const upload = await uploadBytes(storageRef, file);
      const result = await getDownloadURL(upload.ref);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const uploadReceipt = async(id, base64) => {
    const storageRef = ref(storage, `Receipts/${id}.png`);

    try {
      const metadata = {
        contentType: 'image/png',
      };
      const upload = await uploadString(storageRef, base64, 'data_url', metadata);
      const result = await getDownloadURL(upload.ref);
      return ["success", result];
    } catch (error) {
      return ["error", error];
    }
  }

  export const getUserDoc = async(uid) => {
    const path = `Users/${uid}`;
    const ref = doc(db, path);
    let response = "error";
    let result;

    try {
      const snapshot = await getDoc(ref);
      const doc = snapshot.data();
      if (doc) {
        result = {...doc, id: uid};
        response = "success";
      } else {
        result = "User does not exist.";
      }
    } catch (error) {
      result = error.message;
    }

    return [response, result];
  }

  export const updateUserDoc = async(uid, additionalInformation = {}) => {
    const path = `Users/${uid}`;
    const ref = doc(db, path);
    let res = "success";

    try {
      await updateDoc(ref, additionalInformation);
    } catch (error) {
      res = error.message;
    }

    return res;
  }
  
  export const getUserEventDocs = async(uid) => {
    const path =  `Users/${uid}/MyEvents`;
    const ref = collection(db, path);
    const res = [];
    const q = query(ref, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getUserEventDoc = async(uid, id) => {
    const path = `Users/${uid}/MyEvents`;
    const ref = doc(db, path, id);
    let response = "error";
    let result;

    try {
      const snapshot = await getDoc(ref);
      const doc = snapshot.data();
      if (doc) {
        result = {...doc, id};
        response = "success";
      } else {
        result = "Event does not exist.";
      }
    } catch (error) {
      result = error.message;
    }

    return [response, result];
  }

  export const createUserEventDoc = async(uid, additionalInformation = {}) => {
    const path =  `Users/${uid}/MyEvents`;
    const ref = collection(db, path);
    let response;
    let result;
    try {
      const data = {
        ...additionalInformation,
        uid,
        "created": serverTimestamp(),
      };
      const doc = await addDoc(ref, data);
      response = "success";
      result = {...data, id: doc.id, docPath: path + "/" + doc.id};
    } catch (error) {
      response = "error";
      result = error.message;
    }

    return [response, result];
  }

  export const deleteUserEventDoc = async (uid, id) => {
    const path =  `Users/${uid}/MyEvents`;
    const ref = doc(db, path, id);
    let response = "success";

    try {
      await deleteDoc(ref);
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const updateUserEventDoc = async(uid, id, additionalInformation = {}) => {
    const path = `Users/${uid}/MyEvents/${id}`;
    const ref = doc(db, path);
    let res = "success";

    try {
      await updateDoc(ref, additionalInformation);
    } catch (error) {
      res = error.message;
    }

    return res;
  }

  export const getAllUserEventDocs = async() => {
    const ref = collectionGroup(db, "MyEvents");
    const res = [];
    const q = query(ref, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getInvoices = async(year) => {
    const start = new Date(parseInt(year), 0, 1);
    const end = new Date(parseInt(year), 11, 31);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);

    const ref = collectionGroup(db, "Invoices");
    const res = [];
    const q = query(ref, orderBy("created"), endAt(end), startAt(start));

    try {
      const docs = await getDocs(q);
      docs.forEach(async(doc) => {
        res.push({...doc.data(), "id": doc.id});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getInvoicesReport = async(start, end) => {
    const ref = collectionGroup(db, "Invoices");
    const res = [];
    const q = query(ref, orderBy("created"), endAt(end), startAt(start));

    try {
      const docs = await getDocs(q);
      docs.forEach(async(doc) => {
        res.push({...doc.data(), "id": doc.id});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getRefunded = async(year) => {
    const start = new Date(parseInt(year), 0, 1);
    const end = new Date(parseInt(year), 11, 31);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);

    const ref = collectionGroup(db, "Events");
    const res = [];
    const q = query(ref, where("status", "==", "Refunded"), orderBy("created"), endAt(end), startAt(start));

    try {
      const docs = await getDocs(q);
      docs.forEach(async(doc) => {
        const {refund: {amount, created, mode, reference}} = doc.data();
        res.push({eventId: doc.id, paymentMethod: mode, payerEmail: "", description: reference, amount: -amount, created});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const getRefundedReport = async(start, end) => {
    const ref = collectionGroup(db, "Events");
    const res = [];
    const q = query(ref, where("status", "==", "Refunded"), orderBy("created"), endAt(end), startAt(start));

    try {
      const docs = await getDocs(q);
      docs.forEach(async(doc) => {
        const {refund: {amount, created, mode, reference}} = doc.data();
        res.push({eventId: doc.id, paymentMethod: mode, payerEmail: "", description: reference, amount: -amount, created});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const createEventInvoiceDoc = async(eventId, data) => {
    const {id, amount, paymentMethod} = data;
    const path =  `Events/${eventId}/Invoices/${id}`;
    const ref = doc(db, path);
    let response = "success";

    try {
      await setDoc(ref, {
        ...data,
        "created": serverTimestamp(),
      });
    } catch (error) {
      response = error.message;
    }

    return response;
  }

  export const getEventInvoiceDocs = async(eventId) => {
    const path =  `Events/${eventId}/Invoices/`;
    const ref = collection(db, path);
    const res = [];
    const q = query(ref, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        res.push({...doc.data(), "id": doc.id, "docPath": doc.ref.path});
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return res;
  }

  export const sendEmail = httpsCallable(functions, 'sendEmail');
  export const getInvoice = httpsCallable(functions, 'getInvoice');
  export const createInvoice = httpsCallable(functions, 'createInvoice');
  export const expireInvoice = httpsCallable(functions, 'expireInvoice');
  export const createPartner = httpsCallable(functions, 'createPartner');
  export const createPartnerDoc = httpsCallable(functions, 'createPartnerDoc');
  export const deletePartner = httpsCallable(functions, 'deletePartner');
  export const deletePartnerDoc = httpsCallable(functions, 'deletePartnerDoc');

  export const getCurrentTime = () => Timestamp.now();