import { Fragment, useEffect, useState } from "react";
import Button from '../components/button';
import {EventItem} from '../components/event-item';
import { Enums } from "../utils/enum";
import { useNavigate } from "react-router-dom";
import { getCollectionDocs, getEventDocs } from "../utils/firebase";

export const Events = () => {
    const navigate = useNavigate();
    const [modalCreate, setModalCreate] = useState("loading");
    const [events, setEvents] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [packages, setPackages] = useState([]);
    const [themes, setThemes] = useState([]);
    const [venues, setVenues] = useState([]);

    useEffect(() => {
        async function get() {
            setEvents(await getEventDocs());
            // setTypes(await getCollectionDocs("Types"));
            // setCategories(await getCollectionDocs("Categories"));
            // setPackages(await getCollectionDocs("Packages"));
            // setThemes(await getCollectionDocs("Themes"));
            // setVenues(await getCollectionDocs("Venues"));
            setModalCreate("");
        };
        get();
    }, []);

    const onNavigate = (path) => {
        navigate(path, {state: {types, categories, packages, themes, venues}});
    }

    const onClickHandler = (event) => {
        navigate("/dashboard/events/new", {replace: true, state: {types, categories, packages, themes, venues, event}});
    }

    return (
        <Fragment>
        {modalCreate !== "" ? <div className="modal custom-modal is-active">
        <div className="modal-background"></div>
            <div className="modal-content" style={{width: "400px"}}>
                <header className="modal-card-head pt-6 is-borderless px-6">
                </header>
                <section className="modal-card-body px-6 has-text-centered">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </section>
                <footer className="modal-card-foot has-text-right is-block pb-5 is-borderless px-6">
                </footer>
            </div>
        </div> : ""}
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <div className="columns is-vcentered">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.Events}</h1>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        <Button type="button" additionalClasses="is-medium mr-3" onClick={() => onNavigate("/dashboard/events/manage")}>{Enums.ManageButtonText}</Button>
                        <Button type="button" additionalClasses="is-medium with-background-primary has-text-white" onClick={() => onNavigate("/dashboard/events/new")}>{Enums.CreateEventButtonText}</Button>
                    </div>
                </div>
                <div className="columns is-multiline mt-6">
                    {events.map((event) => {
                        return <EventItem key={event.id} event={event} onClickHandler={onClickHandler} additionalClasses="column is-4-desktop is-6"/>
                    })}
                </div>
            </div>
        </div>
        </Fragment>
    )
}