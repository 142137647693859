
export const AccountItem = ({account, onClickHandler = () => {}, additionalClasses}) => {
    const { firstName, emailAddress, lastName, photoUrl, id, banned, type, businessName } = account;

    return (
        <div className={`${additionalClasses} is-clickable`} onClick={onClickHandler}>
            <article className="media is-align-content-center item py-5 px-5">
                <figure className="media-left">
                    <p className="image is-rounded is-64x64">
                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"}/>
                    </p>
                </figure>
                <div className="media-content">
                    <div className="content is-clipped">
                    <p className="has-text-weight-bold is-size-4 m-0">{type === "partner" ? businessName : lastName + ", " + firstName}
                        <span className={`is-pulled-right tag ${banned ? "is-danger" : "with-background-primary has-text-white"}`}>{banned ? "Banned" : "Active"}</span>
                    </p>
                    <p className="is-size-6">{emailAddress}</p>
                    </div>
                </div>
            </article>
        </div>
    )
}