import { Fragment, useContext, useEffect, useState } from "react";
import Button from '../components/button';
import { Enums } from "../utils/enum";
import { UserContext } from "../context/user-context";
import { getAllUserEventDocs, getCollectionDocs, getUserEventDocs } from "../utils/firebase";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import FormSelect from "../components/form-select";
import { where } from "firebase/firestore";
import { rateFormatter } from '../utils/utils';

export const Dashboard = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [allEvents, setAllEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const isAdmin = currentUser && currentUser.data.type === "admin";
    const isClient = currentUser && currentUser.data.type === "client";
    const [status, setStatus] = useState("All");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Events", "start", "asc", isClient ? [where("clientId", "==", currentUser.uid)] : isAdmin ? [] : [where("partnerId", "==", currentUser.uid)]);
            if (response === "success") {
                setEvents(result);
                setAllEvents(result);
                return;
            }
            console.log(result);
        };
        if (currentUser) {
            get();
        }
    }, [currentUser]);

    useEffect(() => {
        if (status === "All") {
            setEvents(allEvents);
            return;
        }
        setEvents(allEvents.filter((event) => event.status === status));
    }, [status]);

    const onNavigate = (id) => {
        navigate(`/event/${id}`);
    }

    return (
        <div className="px-5 columns is-desktop is-vcentered is-multiline is-centered">
            <div className="column is-12">
                <div className="columns is-vcentered mb-6">
                    <div className="column is-6 p-0">
                        <h1 className="is-size-3 has-large-text has-text-weight-bold with-text-primary mt-2">{Enums.Dashboard}</h1>
                    </div>
                    <div className="column is-6 p-0 has-text-right">
                        {!isClient ? 
                        <FormSelect options={["All", "Unpaid", "Partial", "Paid", "Completed", "Cancelled", "Refunded"]} type="text" required id="status" value={status} onChange={(e) => setStatus(e.target.value)}/>
                        :
                        <Button type="button" additionalClasses="is-medium with-background-primary has-text-white" onClick={() => navigate("/reserve")}>
                            {Enums.BookEventButtonText}
                            </Button>}
                    </div>
                </div>

                {events.map((event, index) => {
                    const {build, partner: {businessName, photoUrl}, id, type, venue: {name: hallName}, status, rate} = event;

                    return (
                        <div className="card py-3 px-4 columns mb-6 is-clickable" key={id} onClick={() => onNavigate(id)}>
                                <div className='column is-2-desktop'>
                                    <figure className="image is-3by4 is-fullwidth">
                                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/480x640.png"}/>
                                    </figure>
                                </div>
                                <div className="column is-10-desktop">
                                    <div className="columns is-multiline">
                                            <div className="column is-12">
                                                <p><span className="is-size-3 has-text-weight-bold">{type} at {businessName}</span>
                                                <span className={`ml-3 tag ${
                                                    status === "Completed" ? "with-background-primary has-text-white" :
                                                    status === "Paid" ? "is-success" :
                                                    status === "Unpaid" ? "is-danger" :
                                                    status === "Partial" ? "is-warning" :
                                                    ""
                                                }`}>{status}</span>
                                                </p>
                                            </div>
                                            <div className="column is-12 is-size-5 has-text-weight-bold">
                                                {hallName.toUpperCase()} RESERVATIONS:
                                            </div>
                                            
                                            <div className="column is-12">
                                            {build.map((b, index) => {
                                                const {start, end, foodItems, talents, foodItemsTotal, hostTotal, hours, packageItem, packageTotal} = b;
                                                return (
                                                    <Fragment key={`${id}_${index}`}>
                                                        <div className="tag is-medium mr-3">{format(start.toDate(), "MMMM dd, yyyy h:mm")} - {format(end.toDate(), "h:mma")}</div>
                                                    </Fragment>
                                                        
                                                    )
                                                })
                                            }
                                            </div>
                                            <div className="column is-12 is-size-3 has-text-weight-bold has-text-right has-text-centered-mobile">
                                                P {rateFormatter(rate)}
                                            </div>
                                    </div>
                                </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}