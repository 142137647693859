export const rateFormatter = (rate) => {
  const tempRate = rate.toFixed(2);
  const parts = tempRate.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export const formatTime = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}

export const dateOverlaps = (a_start, a_end, b_start, b_end) => {
  if (a_start <= b_start && b_start <= a_end) return true;
  if (a_start <= b_end   && b_end   <= a_end) return true;
  if (b_start <  a_start && a_end   <  b_end) return true;
  return false;
}