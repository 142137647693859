import { Fragment, useContext, useEffect, useState } from "react";
import Button from '../components/button';
import { HallItem } from '../components/hall-item';
import FormInput from "../components/form-input";
import FormTextArea from "../components/form-textarea";
import FormSelect from "../components/form-select";
import { createCollectionDoc, createUserEventDoc, expireInvoice, getCollectionDocs, getEventDocsByCategoryAndType, getUserDoc, getUserDocs, updateCollectionDoc, updateUserEventDoc } from "../utils/firebase";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { EventItem } from "../components/event-item";
import { format } from "date-fns";
import { UserContext } from "../context/user-context";
import { Loading, Modal, ModalConfirm } from "../components/modal";
import { PackageItem } from "../components/package-item";
import { HostItem } from "../components/host-item";
import { endAt, startAfter, startAt, where } from "firebase/firestore";
import MyCalendar from "../components/calendar";
import { dateOverlaps, formatTime, rateFormatter } from "../utils/utils";
import { CateringItem } from "../components/catering-item";
import ReactPlayer from "react-player";
import { ta } from "date-fns/locale";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPencil } from "@fortawesome/free-solid-svg-icons";
import { categoryList } from "./catering-page";

export const Reserve = () => {
    const { state } = useLocation();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const completedStep4 = pathname === "/overview";
    const completedStep3 = pathname === "/date" || completedStep4;
    const completedStep2 = pathname === "/venue" || completedStep3;
    const completedStep1 = pathname === "/type" || completedStep2;

    const onNavigate = (path) => {
        navigate(path, {state: {...state}});
    }

    return (
        <Fragment>
            <div className="column is-10 is-desktop">
                <ol className="c-stepper">
                    <li className={`c-stepper__item ${pathname === "/reserve" ? "is-active" : ""}
                    ${completedStep1 ? "active is-clickable" : ""}`} onClick={completedStep1 ? () => onNavigate("/reserve") : () => {}}>
                        <div className="c-stepper__content">
                            <h3 className="c-stepper__title">Category</h3>
                            <p className="c-stepper__desc">Step 1</p>
                        </div>
                    </li>
                    <li className={`c-stepper__item ${pathname === "/type" ? "is-active" : ""}
                    ${completedStep2 ? "active is-clickable" : ""}`} onClick={completedStep2 ? () => onNavigate("/type") : () => {}}>
                        <div className="c-stepper__content">
                            <h3 className="c-stepper__title">Type of Events</h3>
                            <p className="c-stepper__desc">Step 2</p>
                        </div>
                    </li>
                    <li className={`c-stepper__item ${pathname === "/venue" ? "is-active" : ""}
                    ${completedStep3 ? "active is-clickable" : ""}`} onClick={completedStep3 ? () => onNavigate("/venue") : () => {}}>
                        <div className="c-stepper__content">
                            <h3 className="c-stepper__title">Venue</h3>
                            <p className="c-stepper__desc">Step 3</p>
                        </div>
                    </li>
                    <li className={`c-stepper__item ${pathname === "/date" ? "is-active" : ""}
                    ${completedStep4 ? "active is-clickable" : ""}`} onClick={completedStep4 ? () => onNavigate("/date") : () => {}}>
                        <div className="c-stepper__content">
                            <h3 className="c-stepper__title">Builder</h3>
                            <p className="c-stepper__desc">Step 4</p>
                        </div>
                    </li>
                    <li className={`c-stepper__item ${pathname === "/overview" ? "is-active" : ""}`}>
                        <div className="c-stepper__content">
                            <h3 className="c-stepper__title">Overview</h3>
                            <p className="c-stepper__desc">Step 5</p>
                        </div>
                    </li>
                </ol>
            </div>
        </Fragment>
    )
}

export const StepCategory = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Categories", "title", "asc");
            if (response === "success") {
                setCategories(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    const onClickHandler = (category) => {
        navigate("/type", {state: {...state, category: category}});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                    {categories.map((category) => {
                        const {id, title} = category;
                        return (
                        <div key={id} className="column is-3-desktop is-6 is-clickable" onClick={() => onClickHandler(category)}>
                            <div className="custom-flex columns item with-background-primary has-text-centered is-vcentered">
                                <div className="column"><span className="has-text-weight-bold has-text-white is-size-3">{title}</span></div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const StepType = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const {category: {types}} = state;

    const onClickHandler = (type) => {
        navigate("/venue", {state: {...state, type: type}});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                    {types.map((type, index) => {
                        return (
                        <div key={index} className="column is-3-desktop is-6 is-clickable" onClick={() => onClickHandler(type)}>
                            <div className="custom-flex columns item with-background-primary has-text-centered is-vcentered">
                                <div className="column"><span className="has-text-weight-bold has-text-white is-size-3">{type}</span></div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const StepVenue = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [venues, setVenues] = useState([]);
    const [modal, setModal] = useState("");
    const [pax, setPax] = useState(state && state.pax ? state.pax : "");
    const [modalConfirm, setModalConfirm] = useState(false);
    const [selected, setSelected] = useState(null);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        async function get() {
            const docs = await getUserDocs("partner");
            setAccounts(docs);
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Halls", "pax", "asc", [where("pax", ">=", parseInt(pax))]);
            if (response === "success") {
                for (const res of result) {
                    const index = accounts.map(acc => acc.id).indexOf(res.partnerId);
                    if (index > -1) {
                        res["partner"] = accounts[index];
                    }
                }
                setVenues(result);
                return;
            }
            setModal(result.message);
        };
        if (pax && accounts) {
            get();
        }
    }, [pax, accounts]);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const onClickHandler = (venue) => {
        if (state.venue && state.venue.id != venue.id) {
            setModalConfirm(true);
            setSelected(venue);
        } else {
            navigate("/date", {state: {...state, venue: venue, pax}});
        }
    }

    const onYesHandler = () => {
        delete state.partner;
        delete state.build;
        setModalConfirm(false);
        navigate("/date", {state: {...state, venue: selected, pax}});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {modalConfirm ? <ModalConfirm message="You are changing venue, all builder data will be reset, would you like to continue?" yes="Yes" no="No" onNoHandler={() => {setModalConfirm(false)}} onYesHandler={onYesHandler}/> : ""}
            <Reserve/>
            <div className="column is-9 mt-6">
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <div className="columns is-centered is-vcentered is-multiline">
                            <FormInput type="number" required id="pax" value={pax} onChange={(e) => setPax(e.target.value)} placeholder="Number of Pax" additionalClasses="column is-12"/>
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="columns is-mobile">
                            <div className="column is-3 is-2-desktop">
                                <button className="button is-fullwidth" style={{height: 50 + "px"}} onClick={(e) => setPax("10")}>10</button>
                            </div>
                            <div className="column is-3 is-2-desktop">
                                <button className="button is-fullwidth" style={{height: 50 + "px"}} onClick={(e) => setPax("30")}>30</button>
                            </div>
                            <div className="column is-3 is-2-desktop">
                                <button className="button is-fullwidth" style={{height: 50 + "px"}} onClick={(e) => setPax("50")}>50</button>
                            </div>
                            <div className="column is-3 is-2-desktop">
                                <button className="button is-fullwidth" style={{height: 50 + "px"}} onClick={(e) => setPax("100")}>100</button>
                            </div>
                        </div>
                    </div>
                    
                    {pax == "" ? <div className="column is-12"><div className="notification mx-3 py-4">Add number of pax in the input field.</div></div> : ""}
                </div>
                
                <div className='columns is-multiline'>
                    {venues.map((venue) => {
                        return <HallItem key={venue.id} item={venue} showStatus={false} additionalClasses="column is-3-desktop is-6" onClickHandler={() => onClickHandler(venue)}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export const StepDate = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [modal, setModal] = useState("");
    const [date, setDate] = useState(new Date());
    const [minDate, setMinDate] = useState(new Date());
    const [calendar, setCalendar] = useState(false);
    const [time, setTime] = useState(false);
    const [tempEvents,  setTempEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalConfirm, setModalConfirm] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState(-1);
    const [build, setBuild] = useState(state && state.build ? state.build : []);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [catering, setCatering] = useState([]);
    const [food, setFood] = useState(false);
    const [host, setHost] = useState(false);
    const [menu, setMenu] = useState(false);
    const [packag, setPackage] = useState(false);
    const [slots, setSlots] = useState([]);
    const [foodItem, setFoodItem] = useState(null);
    const [foodItems, setFoodItems] = useState([]);
    const [talent, setTalent] = useState(null);
    const [list, setList] = useState(false);
    const [talents, setTalents] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [search, setSearch] = useState("");
    const [foodItemsTotal, setFoodItemsTotal] = useState(0);
    const [packages, setPackages] = useState([]);
    const [hours, setHours] = useState(0);
    const [load, setLoad] = useState(false);
    const [partner, setPartner] = useState(null);
    const [active, setActive] = useState("Main Course");
    const [update, setUpdate] = useState(-1);
    const [suggested, setSuggested] = useState([]);
    let foodTotal = 0;
    let hostTotal = 0;

    const [foodTot, setFoodTot] = useState(0);
    const [hostTot, setHostTot] = useState(0);

    useEffect(() => {
        async function get() {
            const {venue: {partnerId}} = state;
            const [response, result] = await getUserDoc(partnerId);
            if (response === "success") {
                setPartner(result);
                return;
            }
        };
        if (state) {
            get();
        }
    }, []);

    console.log("wew");
    useEffect(() => {
        if (state && state.id && state.build) {
            for (const b of state.build) {
                if (b.slots == null) {
                    continue;
                }
                b["slots"] = b.slots.map((slot) => {
                    console.log(slot);
                    if (slot.seconds) {
                        return new Date(slot.seconds * 1000);
                    }
                    return slot;
                });
            }
            setBuild([...state.build]);
        }
    }, []);
    console.log("wat");

    useEffect(() => {
        const d = new Date();
        d.setDate(date.getDate() + 30);
        setMinDate(d);
    }, []);

    useEffect(() => {
        async function get() {
            const {category: {title}, type, pax, venue} = state;
            const [response, result] = await getCollectionDocs("Packages", "pax", "asc",
            [where("category", "==", title), where("type", "==", type), where("pax", ">=", parseInt(pax)),
            where("venueId", "==", venue.id)]);
            if (response === "success") {
                setPackages(result);
                return;
            }
        };
        if (state) {
            get();
        }
    }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Hosts", "name", "asc", [where("status", "==", "Active")]);
            if (response === "success") {
                setHosts(result);
            }
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const {venue: {id: venueId}, id: docId} = state;
            date.setHours(23, 59, 59);
            const [response, result] = await getCollectionDocs("Events", "created", "asc", [where("venueId", "==", venueId), where("status", "in", ["Pending", "Unpaid", "Partial", "Paid"])]);
            if (response === "success") {
                const temp = [];
                for (let res of result) {
                    const {build, id} = res;
                    if (docId == id) {
                        continue;
                    }

                    for (const b of build) {
                        const {start, end} = b;
                        const startDate = start.toDate();
                        const endDate = end.toDate();
                        const title = `Reserved ${format(startDate, "ha").toLowerCase()}-${format(endDate, "ha").toLowerCase()}`;

                        const event = {start: startDate, end: endDate, id, title};
                        temp.push(event);
                    }
                  }
                setEvents(temp);
                setLoad(true);
                return;
            }
        };
        if (state) {
            get();
        }
    }, []);

    useEffect(() => {
        async function get() {
            const {venue: {id}} = state;
            const [response, result] = await getCollectionDocs("Unavailable", "created", "asc", [where("venueId", "==", id)]);
            if (response === "success") {
                const temp = [];
                for (let res of result) {
                  const {start, end, id} = res;
                  const event = {start: start.toDate(), end: end.toDate(), id, title: "Unavailable"};
                  temp.push(event);
                }
                setEvents([...events, ...temp]);
                return;
            }
        };
        if (load) {
          get();
        }
    }, [load]);

    useEffect(() => {
        let i = 0;
        const temp = [];
        for (const b of build) {
            if (i === update) {
                i++;
                continue;
            }
            const {start, end} = b;
            const title = `Reserved ${format(start, "ha").toLowerCase()}-${format(end, "ha").toLowerCase()}`

            const event = {start, end, id: i, title};
            temp.push(event);
            i++;
        }

        setAllEvents([...events, ...temp]);
    }, [build, events, update]);

    useEffect(() => {
        async function get() {
            const {venue: {partnerId}} = state;
            const [response, result] = await getCollectionDocs("Catering", "name", "asc", [where("partnerId", "==", partnerId)]);
            if (response === "success") {
                const firstRnd = Math.floor(Math.random() * result.length);
                let secondRnd = firstRnd;
                while(firstRnd === secondRnd) {
                    secondRnd = Math.floor(Math.random() * result.length);
                }
                setSuggested([result[firstRnd], result[secondRnd]]);
                setCatering(result);
            }
        };
        if (state) {
            get();
        }
    }, []);

    useEffect(() => {
        if (update > -1) {
            setStart(build[update].start);
            setEnd(build[update].end);
            setFoodItems(build[update].foodItems);
            setTalents(build[update].talents);
            setHours(build[update].hours);
            setSlots(build[update].slots);
        }
    }, [update]);

    useEffect(() => {
        if (state) {
            let changed = false;
            let booType = true;
            let booCat = true;
            const {type, category: {title}, build} = state;
            if (build) {
                const newBuild = build.filter((b) => {
                    if (b.packageItem != null) {
                        const boo = b.packageItem.type === type && b.packageItem.category === title;
                        if (!boo && !changed) {
                            booType = b.packageItem.type === type;
                            booCat = b.packageItem.category === title;
                            changed = true;
                        }
                        return boo;
                    };
                    return true;
                });
                if (changed) {
                    let message = "Booked events with packages were removed due to change in category.";
                    if (!booType && booCat) {
                        message = "Booked events with packages were removed due to change in type of event.";
                    }
                    setModal(message);
                    setBuild(newBuild);
                }
            }
        }
    }, []);

    useEffect(() => {
        navigate("/date", {state: {...state, build, partner}});
    }, [build]);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const {venue} = state;
    
    const onClickHandler = () => {
        if (build.length === 0) {
            setModal("Add a date");
            return;
        }
        navigate("/overview", {state: {...state, build, partner}});
    }

    const onSelectDate = (slotInfo) => {
        const {start} = slotInfo;
        if (minDate.getTime() > start.getTime()) {
            return;
        }

        const end = new Date(start);
        end.setDate(end.getDate() + 1);

        if (start.getTime() > date.getTime()) {
            const temp = [];
            let i = 0;
            for (const event of allEvents) {
                const isAfterStart = event.start.getTime() >= start.getTime();
                const isBeforeEnd = event.start.getTime() < end.getTime();
                if (isAfterStart && isBeforeEnd) {
                    temp.push(event);

                    const beforeDate = new Date(event.start);
                    beforeDate.setHours(event.start.getHours() - 2);

                    const tit = "Event Preparation";
                    const eve = {start: beforeDate, end: event.start, id: `start_${i}`, title: tit};
                    temp.push(eve);

                    const afterDate = new Date(event.end);
                    afterDate.setHours(event.end.getHours() + 2);

                    const eve2 = {start: event.end, end: afterDate, id: `end_${i}`, title: tit};
                    temp.push(eve2);
                    

                    if (event.end.getDate() != afterDate.getDate()) {
                        const additionalDate = new Date(afterDate);
                        additionalDate.setHours(afterDate.getHours() - 1);
                        additionalDate.setMinutes(59);
                        additionalDate.setSeconds(59);
                        const eve3 = {start: event.end, end: additionalDate, id: `add_${i}`, title: tit};

                        temp.push(eve3);
                    }
                    i++;
                }
            }
            setTempEvents(temp);
        }

        setSelectedDate(start);
        setCalendar(false);
        setTime(true);
    }

    const onSelectTime = (slotInfo) => {
        const {start, end, slots} = slotInfo;
        for (const event of tempEvents) {
            if (event.id === state.id) {
                continue;
            }
            const tempStart = new Date(event.start);
            tempStart.setSeconds(1);
            const tempEnd = new Date(event.end);
            tempEnd.setHours(tempEnd.getHours() - 1);
            tempEnd.setMinutes(59);
            tempEnd.setSeconds(59);

            if (dateOverlaps(start, end, tempStart, tempEnd)) {
                setModal("Overlapping event found, select a different time.");
                return;
            }
        }

        setTime(false);
        setStart(start);
        setEnd(end);
        setSlots(slots);
        const hours = (end.getTime() - start.getTime()) / (60 * 60 * 1000);
        setHours(hours);
        setModalConfirm(`You have selected ${format(start, "MMMM dd, yyyy h:mma")} - ${format(end, "h:mma")}, would you like to proceed?`);
    }

    const reset = () => {
        setCalendar(false);
        setTime(false);
        setModalConfirm("");
        setFood(false);
        setHost(false);
        setPackage(false);
        setMenu(false);
        setSelectedDate(null);
        setTempEvents([]);
        setStart(null);
        setEnd(null);
        setFoodItem(null);
        setFoodItems([]);
        setTalent(null);
        setList(false);
        setTalents([]);
        setSearch("");
        setSlots([]);
        setUpdate(-1);
    }

    const onPackageSelect = (packageItem) => {
        let packageTotal = packageItem.price;
        if (hours > 4) {
            packageTotal += (hours - 4) * packageItem.rate;
        }
        const b = {start, end, foodItems, talents, hours, hostTotal, foodItemsTotal, packageItem, packageTotal};
        if (update === -1) {
            setBuild([...build, b]);
        } else {
            build[update] = b;
            setBuild([...build]);
        }
        reset();
    }

    const onFinishHandler = () => {
        if (update === -1) {
            const b = {start, end, foodItems, talents, hours, hostTotal, foodItemsTotal, packageTotal: 0, slots};
            setBuild([...build, b]);
        } else {
            const pack = build[update].packageItem;
            if (pack != null) {
                onPackageSelect(pack);
                return;
            }

            let tempHost = 0;
            for (const talent of talents) {
                const {rate, hours} = talent;
                const subtotal = rate * hours;
                tempHost += subtotal;
            }

            let tempFood = 0;
            for (const food of foodItems) {
                const {quantity, price} = food;
                const subtotal = price * quantity;
                tempFood += subtotal;
            }

            const b = {start, end, foodItems, talents, hours, hostTotal: tempHost, foodItemsTotal: tempFood, packageTotal: 0, slots};
            build[update] = b;
            setBuild([...build]);
        }
        reset();
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {calendar ? <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-weight-bold">Date</p>
                        <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                    </header>
                    <section className="modal-card-body has-text-centered custom-calendar">
                        <div className="tile notification is-warning">Note: You can make a reservation only for dates starting 30 days from today.</div>
                        <div className="tile notification has-text-danger mt-3 is-hidden-desktop py-0">Press and hold a date to continue.</div>
                        <MyCalendar date={minDate} height={550} events={allEvents} views={['month']} onSelectSlot={onSelectDate}/>
                    </section>
                    <footer className="modal-card-foot is-borderless">
                    </footer>
                </div>
            </div> : ""}
            
            {time ? <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-weight-bold">Time</p>
                        <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                    </header>
                    <section className="modal-card-body has-text-centered custom-calendar hidden-button hidden-header">
                        <div className="tile notification has-text-danger">Press and hold the time slots you want for your event.</div>
                        <MyCalendar height={1000} events={tempEvents} views={['day']} onSelectSlot={onSelectTime} date={selectedDate}/>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-medium" onClick={() => {setTime(false); setCalendar(true)}}>Go Back</button>
                    </footer>
                </div>
            </div> : ""}

            {packag ? <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: "1500px", maxWidth: 100 + "%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-weight-bold">Package</p>
                        <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                    </header>
                    <section className="modal-card-body has-text-centered custom-calendar hidden-button">
                        <div className="columns is-multiline">
                            {packages.map((item) => {
                                return <PackageItem hours={4} key={item.id} onClickHandler={() => onPackageSelect(item)} item={item} additionalClasses="column is-6 has-text-left" showStatus={false}/>
                            })}
                        </div>
                            {packages.length > 0 ? "" : <p className="has-text-centered" style={{lineHeight: 250 + "px"}}>No available package</p>}
                    </section>
                    <footer className="modal-card-foot has-text-right is-block">
                    {update === -1 ? <Fragment>
                        <button className="button is-medium is-pulled-left" onClick={() => {setPackage(false); setTime(true)}}>Go Back</button>
                        <button className="button is-medium is-success" onClick={() => {setPackage(false); setFood(true);}}>Custom</button>
                    </Fragment> : ""}
                    
                    </footer>
                </div>
            </div> : ""}

            {food ? <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                <header className="modal-card-head">
                    <p className="modal-card-title has-text-weight-bold">Food</p>
                    {update === -1 ? <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button> : ""}
                </header>
                <section className="modal-card-body has-text-centered">
                        {slots.map((slot) => {
                            const s = format(slot, "h:mma");
                            const items = foodItems.filter((item) => item.servingTime === s);
                            return (
                                <Fragment key={s} >
                                    {items.length > 0 ? 
                                        <div className="columns is-multiline">
                                            <div className="column is-12 has-text-left">
                                                <h2 className="my-2">Serving at {s}</h2>
                                                {items.map((item, i) => {
                                                    const {name, quantity, price, photoUrl, pax, itemId} = item;
                                                    const subtotal = price * quantity;
                                                    foodTotal += subtotal;
                                                    const index = foodItems.map(e => e.itemId).indexOf(itemId);
                                                    return (
                                                        <div className="card columns is-vcentered is-size-5 mt-5 py-3 px-4" key={`${s}_${i}`}>
                                                            <div className="column is-1">
                                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                                </figure>
                                                            </div>
                                                            <div className="column is-3 has-text-weight-medium has-text-centered-mobile">{name} ({pax} pax)</div>
                                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(price)}</div>
                                                            <div className="column is-3 has-text-right">
                                                                <div className="columns is-vcentered is-mobile">
                                                                <div className="column is-4"><button className="button has-text-weight-bold is-fullwidth" onClick={() => {
                                                                    if (foodItems[index].quantity === 1) {
                                                                        return;
                                                                    }
                                                                    foodItems[index].quantity--; setFoodItems([...foodItems])}}>-</button></div>
                                                                <div className="column is-4 has-text-centered">{quantity}</div>
                                                                <div className="column is-4"><button className="button has-text-weight-bold is-fullwidth" onClick={() => {foodItems[index].quantity++; setFoodItems([...foodItems])}}>+</button></div>
                                                                </div>
                                                            </div>
                                                            <div className="column is-2 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                                            <div className="column is-1 has-text-right has-text-weight-medium has-text-centered-mobile"><button className="delete is-large" aria-label="close" onClick={() => {foodItems.splice(index, 1); setFoodItems([...foodItems])}}></button></div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                    </div> : ""}
                                </Fragment>
                            )
                        })}
                    
                    {foodItems.length > 0 ? <div className="columns is-size-4 mt-5">
                        <div className="column is-6 has-text-weight-bold has-text-left">
                            TOTAL
                        </div>
                        <div className="column is-6 has-text-right has-text-weight-bold">
                            P {rateFormatter(foodTotal)}
                        </div>
                    </div> : <Fragment>
                        <p className="has-text-centered" style={{lineHeight: 250 + "px"}}>Empty</p>

                        <div className="px-3">
                        <p className="has-text-left">SUGGESTIONS</p>
                        {suggested.map((item, index) => {
                            const {name,  price, photoUrl} = item;
                            const quantity = Math.round(state.pax / item.pax);
                            const subtotal = price * quantity;
                            return (
                                <div className="card columns is-vcentered is-size-5 mt-5 py-3 px-4" key={`suggestion_${index}`}>
                                <div className="column is-1">
                                    <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                        <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                    </figure>
                                </div>
                                <div className="column is-3 has-text-weight-medium has-text-centered-mobile">{name} ({item.pax} pax)</div>
                                <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(price)}</div>
                                <div className="column is-2 has-text-centered">×{quantity}</div>
                                <div className="column is-2 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                <div className="column is-2 has-text-right has-text-weight-medium has-text-centered-mobile"><button className="button" onClick={() => {setFood(false); setFoodItem({...item, quantity})}}>Add</button></div>
                            </div>
                            )
                        })}
                        </div>
                    </Fragment>}
                </section>
                <footer className="modal-card-foot has-text-right is-block">
                    {update === -1 ? <button className="button is-medium is-pulled-left" onClick={() => {setFood(false); setPackage(true)}}>Go Back</button> : ""}
                    <button className="button is-medium" onClick={() => {setFood(false); setMenu(true)}}>Add Food</button>
                    {update === -1 ?
                    <button className="button is-medium is-success" onClick={() => {setFood(false); setHost(true); setFoodItemsTotal(foodTotal)}}>Next</button> :
                    <button className="button is-medium is-success" onClick={onFinishHandler}>Done</button>
                    }
                </footer>
            </div>
        </div> : ""}
            
        {host ? <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-weight-bold">Host</p>
                        {update === -1 ? <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button> : ""}
                    </header>
                    <section className="modal-card-body has-text-centered custom-calendar hidden-button">
                        {talents.map((talent, index) => {
                            const {name, photoUrl, rate, start, end, hours} = talent;

                            const subtotal = rate * hours;
                            hostTotal += subtotal;
                            return (
                                <div className="card columns is-vcentered is-size-5 mt-5 py-3 px-4" key={`talent_${index}`}>
                                    <div className="column is-1">
                                        <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                            <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                        </figure>
                                    </div>
                                    <div className="column is-3 has-text-weight-medium has-text-centered-mobile">{name}</div>
                                    <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(rate)}</div>
                                    <div className="column is-3 has-text-right has-text-centered-mobile">{hours} hours<br/><span className="is-size-6">{formatTime(start)} - {formatTime(end)}</span></div>
                                    <div className="column is-2 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                    <div className="column is-1 has-text-right has-text-weight-medium has-text-centered-mobile"><button className="delete is-large" aria-label="close" onClick={() => {talents.splice(index, 1); setTalents([...talents])}}></button></div>
                                </div>
                            )
                        })}

                    {talents.length > 0 ? <div className="columns is-size-4 mt-5">
                        <div className="column is-6 has-text-weight-bold has-text-left">
                            TOTAL
                        </div>
                        <div className="column is-6 has-text-right has-text-weight-bold">
                            P {rateFormatter(hostTotal)}
                        </div>
                    </div> : <p className="has-text-centered" style={{lineHeight: 250 + "px"}}>Empty</p>}
                    </section>
                    <footer className="modal-card-foot has-text-right is-block">
                        {update === -1 ? <button className="button is-medium is-pulled-left" onClick={() => {setHost(false); setFood(true)}}>Go Back</button> : ""}
                        <button className="button is-medium" onClick={() => {setHost(false); setList(true)}}>Add Host</button>
                        <button className="button is-medium is-success" onClick={onFinishHandler}>{update === -1 ? "Finish" : "Done"}</button>
                    </footer>
                </div>
            </div> : ""}
            
        {list ? <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                        <header className="modal-card-head">
                            <p className="modal-card-title has-text-weight-bold">Hosts</p>
                            <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                        </header>
                        <section className="modal-card-body">
                            <div className="columns is-multiline">
                            {hosts.filter((item) => !talents.map((talent) => talent.id).includes(item.id)).map((item) => {
                                return <HostItem key={item.id} item={item} additionalClasses="column is-3-desktop is-6" onClickHandler={() => {setList(false); setTalent({...item, start: format(slots[0], "HH:mm"), end: format(slots[slots.length - 1], "HH:mm")})}} showStatus={false}/>
                            })}
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                        <button className="button is-medium" onClick={() => {setList(false); setHost(true);}}>Go Back</button>
                        </footer>
                    </div>
                </div> : ""}

        {talent !== null ? <div className="modal has-text-centered is-active">
            <div className="modal-background" onClick={() => {setHost(true); setTalent(null)}}></div>
            <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    
                    const {start, end} = talent;
                    const startTime = start.split(":");
                    const startDate = new Date();
                    startDate.setHours(parseInt(startTime[0]));
                    startDate.setMinutes(parseInt(startTime[1]));
                    startDate.setSeconds(0);
                    
                    const endTime = end.split(":");
                    const endDate = new Date();
                    endDate.setHours(parseInt(endTime[0]));
                    endDate.setMinutes(parseInt(endTime[1]));
                    endDate.setSeconds(0);
                    
                    const hours = (endDate.getTime() - startDate.getTime()) / (60 * 60 * 1000);
                    talent["hours"] = hours;

                    setTalents([...talents, talent]); setTalent(null); setHost(true);
                }}>
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">{talent.name}</p>
                </header>
                <section className="modal-card-body px-6 has-text-centered">
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <h2 className="has-text-left is-size-5 has-text-medium">Start Time</h2>
                            <FormInput min={format(slots[0], "HH:mm")} max={format(slots[slots.length - 1], "HH:mm")} type="time" value={talent.start} onChange={(e) => setTalent({...talent, start: e.target.value})}/>
                        </div>
                        <div className="column is-6">
                            <h2 className="has-text-left is-size-5 has-text-medium">End Time</h2>
                            <FormInput min={talent.start} max={format(slots[slots.length - 1], "HH:mm")} type="time" value={talent.end} onChange={(e) => setTalent({...talent, end: e.target.value})}/>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot has-text-centered is-block pb-6 px-6 is-borderless">
                    <button className="button is-medium" onClick={() => {setList(true); setTalent(null)}}>Go Back</button>
                    <button className="button is-medium is-success" type="submit">Next</button>
                </footer>
                </form>
            </div>
        </div> : ""}

        {menu ? <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%", height: 100 + "%"}}>
                <header className="modal-card-head">
                    <p className="modal-card-title has-text-weight-bold">Menu</p>
                    <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                </header>
                <section className="modal-card-body has-text-centered">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <FormInput  type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search"/>
                        </div>
                        <div className="column is-12">
                            <div className="columns is-multiline is-centered">
                                {categoryList.map((category, index) => {
                                    return (
                                        <div onClick={() => setActive(category)} className={`tag column is-large is-clickable ${active === category ? "with-background-primary has-text-white" : ""}`} key={`category_${index}`}>
                                            {category}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {catering.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).filter((item) => active === item.category).map((item) => {
                            return <CateringItem showStatus={false} vertical={false} key={item.id} item={item} additionalClasses="column is-desktop is-6" onClickHandler={() => {setMenu(false); setSearch(""); setFoodItem({...item, quantity: 1})}}/>
                        })}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-medium" onClick={() => {setMenu(false); setFood(true); setSearch("")}}>Go Back</button>
                </footer>
            </div>
        </div> : ""}

        {foodItem !== null ? <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                <header className="modal-card-head">
                    <p className="modal-card-title has-text-weight-bold">{foodItem.name} ({foodItem.pax} pax)</p>
                    <button className="delete is-medium" aria-label="close" onClick={() => reset()}></button>
                </header>
                <section className="modal-card-body">
                    <div className="columns is-multiline">
                        <div className="column is-12 mb-5">
                            <h2 className="is-size-4 has-text-medium">Serving Time</h2>
                        </div>
                        {slots.map((slot) => {
                            const s = format(slot, "h:mma");
                            return <span className={`tag is-large is-clickable mr-3 mb-3 ${foodItem.servingTime === s ? "is-success" : "has-background-white"}`} onClick={() => setFoodItem({...foodItem, servingTime: s})}>{s}</span>
                        })}
                    </div>
                </section>
                <footer className="modal-card-foot is-block">
                    <button className="button is-medium" onClick={() => {setMenu(true); setFoodItem(null)}}>Go Back</button>
                    <button className="button is-success is-medium is-pulled-right" onClick={() => {
                        if (foodItem.servingTime === undefined) {
                            setModal("Select serving time");
                        } else if (foodItem.quantity === undefined || foodItem.quantity === 0) {
                            setModal("Add a quantity");
                        } else {
                            const date = new Date();
                            setFoodItems([...foodItems, {...foodItem, itemId: date.getTime()}]); setFoodItem(null); setFood(true);
                        }
                        }}>Add</button>
                </footer>
            </div>
        </div> : ""}

            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {modalConfirm ? <ModalConfirm message={modalConfirm} yes="Yes" no="Go Back" onNoHandler={() => {setTime(true); setModalConfirm("")}} onYesHandler={update === -1 ? () => {setModalConfirm(""); setPackage(true)} : onFinishHandler}/> : ""}
            {deleteConfirm > -1 ? <ModalConfirm danger={true} message="Would you like to remove item?" yes="Yes" no="No" onNoHandler={() => {setDeleteConfirm(-1)}} onYesHandler={() => {build.splice(deleteConfirm, 1); setBuild([...build]); setDeleteConfirm(-1);}}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                {build.sort((a, b) => a.start.getTime() - b.start.getTime()).map((b, index) => {
                    const {start, end, foodItems, talents, foodItemsTotal, hostTotal, hours, packageItem, packageTotal} = b;
                    const venueTotal = venue.rate * hours;
                    let total = foodItemsTotal + hostTotal + venueTotal;
                    if (packageTotal) {
                        total = foodItemsTotal + hostTotal + packageTotal;
                    }

                    return (
                        <div key={`i_${index}`} className="columns card is-multiline py-3 px-4 mb-6">
                            <div className="column is-12 is-size-3 has-text-centered has-text-weight-bold pb-0 mt-5">
                                {format(start, "MMMM dd, yyyy")}
                            </div>
                            
                            <div className="column is-12 is-size-5 has-text-centered py-0">
                                {format(start, "h:mma")} - {format(end, "h:mma")}
                            </div>
                            <div className="column is-12 has-text-centered pt-0">
                                <span className="tag is-danger is-clickable" onClick={() => {setUpdate(index); setCalendar(true)}}><FontAwesomeIcon className="mr-1 fa-icon" icon={faPencil}/> Edit</span>
                            </div>

                            <div className="column is-12 is-size-5 pb-0 mt-6">
                                <div className="columns is-mobile">
                                    <div className="column is-6 has-text-weight-bold">
                                        VENUE
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold">
                                        {packageTotal ? "" : `P ${rateFormatter(venueTotal)}`}
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12">
                                <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4">
                                    <div className="column is-1">
                                        <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                            <img src={venue.photoUrl ? venue.photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                        </figure>
                                    </div>
                                    <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                        <p>{venue.name}</p>
                                        <span className="tag">Max {venue.pax} pax</span>
                                    </div>
                                    <div className="column is-2 has-text-right has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(venue.rate)}`}</div>
                                    <div className="column is-3 has-text-right has-text-centered-mobile">{packageTotal ? "" : `×${hours} hours`}</div>
                                    <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(packageTotal ? 0 : venueTotal)}`}</div>
                                </div>
                            </div>

                            {packageItem != null ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            PACKAGE <span className="tag is-danger ml-1 is-clickable" onClick={() => {setUpdate(index); setPackage(true)}}><FontAwesomeIcon className="mr-1 fa-icon" icon={faPencil}/> Edit</span>
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(packageTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-1">
                                            <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                <img src={packageItem.photoUrl ? packageItem.photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                            </figure>
                                        </div>
                                        <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                            <p>{packageItem.name}</p>
                                            <span className="tag">Max {packageItem.pax} pax</span>
                                        </div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.price)}</p>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×4 hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageItem.price)}</div>
                                    </div>
                                </div>
                                {hours > 4 ? <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-4 has-text-weight-medium has-text-centered-mobile">
                                            <p>Additional Fee</p>
                                        </div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.rate)}</p>
                                            <span className="tag" data-tooltip="Applies to events with total time exceeding 4 hours">
                                                per hour
                                                <FontAwesomeIcon size="xs" className="ml-1 fa-icon" icon={faInfo}/>
                                            </span>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×{hours - 4} hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageTotal - packageItem.price)}</div>
                                    </div>
                                </div> : ""}
                            </Fragment>: <Fragment>
                            <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            FOOD <span className="tag is-danger ml-1 is-clickable" onClick={() => {setUpdate(index); setFood(true)}}><FontAwesomeIcon className="mr-1 fa-icon" icon={faPencil}/> Edit</span>
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(foodItemsTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                {foodItems.length === 0 ? <p className="has-text-centered py-6">No food</p> : ""}
                                {foodItems.map((item, i) => {
                                    const {name, quantity, price, photoUrl, pax, servingTime} = item;
                                    const subtotal = price * quantity;
                                    return (
                                        <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`food_${i}`}>
                                            <div className="column is-1">
                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                </figure>
                                            </div>
                                            <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                                <p>{name}</p>
                                                <span className="tag">{pax} pax</span>
                                                <span className="tag ml-3">{servingTime}</span>
                                            </div>
                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(price)}</div>
                                            <div className="column is-3 has-text-right has-text-centered-mobile">×{quantity}</div>
                                            <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="column is-12 is-size-5 pb-0">
                                <div className="columns is-mobile">
                                    <div className="column is-6 has-text-weight-bold">
                                        HOST <span className="tag is-danger ml-1 is-clickable" onClick={() => {setUpdate(index); setHost(true)}}><FontAwesomeIcon className="mr-1 fa-icon" icon={faPencil}/> Edit</span>
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold">
                                        P {rateFormatter(hostTotal)}
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12">
                            {talents.length === 0 ? <p className="has-text-centered py-6">No host</p> : ""}
                            {talents.map((talent, index) => {
                                const {name, photoUrl, rate, start, end, hours} = talent;
                                const subtotal = rate * hours;
                                return (
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`talent_${index}`}>
                                        <div className="column is-1">
                                            <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                            </figure>
                                        </div>
                                        <div className="column is-3 has-text-weight-medium has-text-centered-mobile">{name}</div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(rate)}</div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">{hours} hours<br/><span className="is-size-6">{formatTime(start)} - {formatTime(end)}</span></div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>}

                            <div className="column is-12 is-size-4">
                                <div className="columns notification">
                                    <div className="column is-6 has-text-weight-bold has-text-centered-mobile">
                                        TOTAL
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold has-text-centered-mobile">
                                        P {rateFormatter(total)}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="column is-12 mb-5">
                                <button className="button is-fullwidth has-text-weight-semibold is-size-5 has-text-danger" aria-label="close" onClick={() => setDeleteConfirm(index)}>Remove</button>
                            </div>
                        </div>
                    )
                })}


                <div className="columns my-6 is-multiline">
                    <div className="column is-6 mt-6">
                        <Button type="button" additionalClasses="is-medium block is-fullwidth" onClick={() => setCalendar(true)}>Add Date</Button>
                    </div>
                    <div className="column is-6 mt-6">
                        <Button type="button" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth" onClick={onClickHandler}>Book Now</Button>
                    </div>
                    {build.length == 0 ? <div className="column is-12 mt-3"><div className="notification py-4">Click the add date button to select a date for your booking.</div></div> : ""}
                </div>
            </div>
        </div>
    )
}
 
export const StepTime = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [modalConfirm, setModalConfirm] = useState("");
    const [selectedTime, setSelectedTime] = useState(0);
    const [maxTime, setMaxTime] = useState(0);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const {events, selectedDate, options, maxTime: prevMax} = state;

    const onSelectSlot = (slotInfo) => {
        const {start} = slotInfo;
        let nearest = 0;
        for (const event of events) {
            if (event.id === state.id) {
                continue;
            }
            const startBooked = start.getHours() === event.start.getHours();
            const endBooked = start.getHours() === event.end.getHours() - 1;
            const inBetween = start.getHours() > event.start.getHours() && start.getHours() < event.end.getHours();

            if (startBooked || endBooked || inBetween) {
                setModal("Selected time is already booked");
                return;
            }

            const difference = event.start.getHours() - start.getHours();
            if (difference > 0) {
                if (nearest === 0 || nearest > difference) {
                    nearest = difference;
                }
            }
        }

        if (nearest > 0 && nearest < 4) {
            setMaxTime(nearest);
            setSelectedTime(start.getHours());
            setModalConfirm(`There is an event next to your selected time. You may only book your own event for ${nearest} ${nearest > 1 ? "hours" : "hour"} maximum, would you like to continue?`)
        } else {
            navigate("/package", {state: {...state, selectedTime: start.getHours(), maxTime: nearest}});
        }
    }

    const onYesHandler = () => {
        if (options && prevMax > maxTime) {
            state["options"] = {...options, hourCount: 0};
        }
        navigate("/package", {state: {...state, selectedTime, maxTime}});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            {modalConfirm ? <ModalConfirm message={modalConfirm} yes="Proceed" no="Cancel" onNoHandler={() => setModalConfirm("")} onYesHandler={onYesHandler}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6 custom-calendar red">
                <MyCalendar events={events} views={['day']} onSelectSlot={onSelectSlot} date={selectedDate}/>
            </div>
        </div>
    )
}

export const StepPackage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const [catering, setCatering] = useState([]);
    const [modal, setModal] = useState("");
    const [days, setDays] = useState([]);
    const [food, setFood] = useState(state && state.food ? state.food : []);
    const [index, setIndex] = useState(-1);
    const [i, setI] = useState(-1);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const {dates: {endDate, startDate}} = state;
        const end = new Date(endDate);
        const start = new Date(startDate);
        const diff = Math.ceil((end - start) / 86400000) + 1;
        const tempDays = [];
        const tempFood = [];
        for (let i = 0; i < diff; i++) {
            const date = new Date(start);
            date.setDate(start.getDate() + i);
            tempDays.push(date);
            tempFood.push([[], [], [], [], []]);
        }
        setDays(tempDays);
        if (state && state.food) {

        } else {
            setFood(tempFood);
        }
    }, []);

    
    useEffect(() => {
        async function get() {
            const {venue: {id}} = state;
            const [response, result] = await getCollectionDocs("Catering", "name", "asc", [where("partnerId", "==", id)]);
            if (response === "success") {
                setCatering(result);
                return;
            }
            setModal(result.message);
        };
        if (state) {
            get();
        }
    }, []);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const onClickHandler = (_package) => {
        navigate("/host", {state: {...state, food}});
    }

    const onAddHandler = (item) => {
        food[index][i].push(item);
        setFood([...food]);
        setShow(false);
    }

    let subtotal = 0;

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {show ? <div className="modal has-text-centered is-active">
            <div className="modal-background" onClick={() => setShow(false)}></div>
            <div className="modal-card" style={{width: "1200px", maxWidth: 100 + "%"}}>
                <header className="modal-card-head pt-6 is-borderless">
                    <p className="modal-card-title">Menu</p>
                </header>
                <section className="modal-card-body px-6 has-text-centered">
                    <div className="columns is-multiline">
                        {catering.map((item) => {
                            return <CateringItem vertical={false} key={item.id} item={item} additionalClasses="column is-desktop is-6" onClickHandler={() => onAddHandler(item)}/>
                        })}
                    </div>
                </section>
                <footer className="modal-card-foot has-text-centered is-block pb-6 px-6 is-borderless">
                </footer>
            </div>
        </div> : ""}
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                    {/* {packages.map((item) => {
                            return <PackageItem guests={guests} showStatus={false} key={item.id} item={item} additionalClasses="column is-6-desktop" onClickHandler={() => onClickHandler(item)}/>
                        })}
                        
                    <div className="column is-6-desktop is-clickable" onClick={() => navigate("/custom", {state})}>
                        <div className="columns item with-background-primary has-text-centered is-vcentered" style={{height: 100 +"%", minHeight: 233 + "px"}}>
                            <div className="column"><span className="has-text-weight-bold has-text-white is-size-3">Customize</span></div>
                        </div>
                    </div> */}

                    {days.map((day, index) => {
                        return (
                            <div className="column is-12">
                                <h2 className="is-size-4">{format(day, "MMMM dd, yyyy")}</h2>
                                    <div className="column is-12">
                                        Breakfast
                                        
                                        <Button additionalClasses="is-pulled-right" type="button" onClick={() => {setIndex(index); setI(0); setShow(true)}}>
                                            Add
                                        </Button>
                                        <div className="columns is-multiline">
                                        {food[index][0].map((item, index) => {
                                            return <CateringItem vertical={false} showStatus={false} key={item.id} item={item} additionalClasses="column is-4-desktop is-6"/>
                                        })}
                                        </div>
                                    </div>
                                    <div className="column is-12">
                                        AM Snack
                                        <Button additionalClasses="is-pulled-right" type="button" onClick={() => {setIndex(index); setI(1); setShow(true)}}>
                                            Add
                                        </Button>
                                        <div className="columns is-multiline">
                                        {food[index][1].map((item, index) => {
                                            return <CateringItem vertical={false} showStatus={false} key={item.id} item={item} additionalClasses="column is-4-desktop is-6"/>
                                        })}
                                        </div>
                                    </div>
                                    <div className="column is-12">
                                        Lunch
                                        <Button additionalClasses="is-pulled-right" type="button" onClick={() => {setIndex(index); setI(2); setShow(true)}}>
                                            Add
                                        </Button>
                                        <div className="columns is-multiline">
                                        {food[index][2].map((item, index) => {
                                            return <CateringItem vertical={false} showStatus={false} key={item.id} item={item} additionalClasses="column is-4-desktop is-6"/>
                                        })}
                                        </div>
                                    </div>
                                    <div className="column is-12">
                                        PM Snack
                                        <Button additionalClasses="is-pulled-right" type="button" onClick={() => {setIndex(index); setI(3); setShow(true)}}>
                                            Add
                                        </Button>
                                        <div className="columns is-multiline">
                                        {food[index][3].map((item, index) => {
                                            return <CateringItem vertical={false} showStatus={false} key={item.id} item={item} additionalClasses="column is-4-desktop is-6"/>
                                        })}
                                        </div>
                                    </div>
                                    <div className="column is-12">
                                        Dinner
                                        <Button additionalClasses="is-pulled-right" type="button" onClick={() => {setIndex(index); setI(4); setShow(true)}}>
                                            Add
                                        </Button>
                                        <div className="columns is-multiline">
                                        {food[index][4].map((item, index) => {
                                            return <CateringItem vertical={false} showStatus={false} key={item.id} item={item} additionalClasses="column is-4-desktop is-6"/>
                                        })}
                                        </div>
                                    </div>
                            </div>
                        )
                    })}

                    <hr className="custom mx-3 my-6"/>

                    {food.map((arr) => {
                        return (<Fragment>
                        {arr.map((foods, i) => {
                            return (<Fragment>
                                {foods.map((food, index) => {
                                    const {name, price} = food;
                                    subtotal += price;
                                    return <div className="column is-12 is-size-5" key={`food_${i}_${index}`}>{name}... <span className="is-pulled-right">P {rateFormatter(price)}</span></div>
                                })}
                            </Fragment>)})}
                        </Fragment>)
                    })}

                    <div className="column is-12 is-size-5 has-text-weight-medium">Sub Total... <span className="is-pulled-right">P {rateFormatter(subtotal)}</span></div>

                    <Button type="submit" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth mx-3 mt-6 mb-5" onClick={onClickHandler}>Next</Button>
                </div>
            </div>
        </div>
    )
}

const defaultOptions = {
    guestCount: 0,
    hourCount: 0,
    foodIncluded: false,
    packageTotal: 0,
}
export const StepOptions = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [modal, setModal] = useState("");
    const [options, setOptions] = useState(state && state.options ? state.options : defaultOptions);

    if (!state) {
        navigate("/reserve");
        return "";
    }
    
    const {maxTime, _package} = state;
    const {guests, hours, description} = _package;
    const {hourCount, guestCount, foodIncluded, packageTotal} = options;

    const onClickHandler = (_package) => {
        if (hourCount === 0) {
            setModal("Select number of hours");
            return;
        }
        if (guestCount === 0) {
            setModal("Select number of guests");
            return;
        }

        navigate("/host", {state: {...state, options: options}});
    }

    const onChangeHandler = (value) => {
        // const {hourCount: newHourCount, guestCount: newGuestCount, foodIncluded: newFoodIncluded} = value;
        // let total = newHourCount !== undefined ? newHourCount * venueRate : hourCount * venueRate;

        // setOptions({...options, packageTotal: total, ...value});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                <div className="column is-12 has-text-centered mb-6">
                    <h2 className="is-size-1 has-text-weight-bold mb-2">Php {rateFormatter(packageTotal)}</h2>
                    <span>Sub Total</span>
                </div>
                <div className="column is-12 my-6">
                    <h2 className="is-size-5 mb-3">Description</h2>
                    <p style={{whiteSpace: "pre"}}>{description}</p>
                </div>

                <div className="column is-4-desktop is-12">
                    <h2 className="is-size-5">Number of Hours</h2>
                    <div className="columns is-12 is-multiline mt-4">
                        {hours.map((hour, index) => {
                            const disallow = maxTime > 0 && hour > maxTime;
                            return (
                                <div className="column is-3-desktop is-4 mr-3">
                                    <Button key={`hour_${index}`} disabled={disallow} onClick={() => onChangeHandler({hourCount: hour})} type="button" additionalClasses={`is-medium is-fullwidth ${hour === hourCount ? "with-background-primary has-text-white" : ""}`}>
                                        {hour}
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                </div>

                    <div className="column is-4-desktop is-12">
                        <h2 className="is-size-5">Number of Guests</h2>
                        <div className="columns is-12 is-multiline mt-4">
                            {guests.map((guest, index) => {
                                return (
                                    <div className="column is-3-desktop is-4 mr-3">
                                        <Button key={`guest_${index}`} onClick={() => onChangeHandler({guestCount: guest})} type="button" additionalClasses={`is-medium is-fullwidth ${guest === guestCount ? "with-background-primary has-text-white" : ""}`}>
                                            {guest}
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    
                <div className="column is-4-desktop is-12">
                    <h2 className="is-size-5">Include Food</h2>
                    <div className="columns is-12 is-multiline mt-4">
                        <div className="column is-3-desktop is-4 mr-3">
                            <Button key="yes" type="button" onClick={() => onChangeHandler({foodIncluded: true})} additionalClasses={`is-medium is-fullwidth ${foodIncluded ? "with-background-primary has-text-white" : ""}`}>
                                Yes
                            </Button>
                        </div>
                        
                        <div className="column is-3-desktop is-4 mr-3">
                            <Button key="no" type="button" onClick={() => onChangeHandler({foodIncluded: false})} additionalClasses={`is-medium is-fullwidth ${!foodIncluded ? "with-background-primary has-text-white" : ""}`}>
                                No
                            </Button>
                        </div>
                    </div>
                </div>
                </div>
                    
                <Button onClick={onClickHandler} type="button" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth mx-3 mt-5 mb-6">
                    Next
                </Button>
            </div>
        </div>
    )
}

export const StepHost = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [modal, setModal] = useState("");
    const [hours, setHours] = useState(4);
    // const [days, setDays] = useState([]);
    // const [hosts, setHosts] = useState(state && state.hosts ? state.hosts : []);

    // useEffect(() => {
    //     const {dates: {endDate, startDate}} = state;
    //     const end = new Date(endDate);
    //     const start = new Date(startDate);
    //     const diff = Math.ceil((end - start) / 86400000) + 1;
    //     const tempDays = [];
    //     const tempHosts = [];
    //     for (let i = 0; i < diff; i++) {
    //         const date = new Date(start);
    //         date.setDate(start.getDate() + i);
    //         tempDays.push(date);
    //         tempHosts.push([[], []]);
    //     }
    //     setDays(tempDays);
    //     setHosts(tempHosts);
    // }, []);

    useEffect(() => {
        async function get() {
            const [response, result] = await getCollectionDocs("Hosts", "name", "asc", [where("status", "==", "Active")]);
            if (response === "success") {
                setUsers(result);
                return;
            }
            setModal(result.message);
        };
        get();
    }, []);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const onClickHandler = (host) => {
        if (hours < 4) {
            setModal("Number of hours must be greater than or equal to 4.");
            return;
        }
        navigate("/overview", {state: {...state, host, hours}});
    }

    return (
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                    <FormInput type="number" min="4" required id="hours" value={hours} onChange={(e) => setHours(e.target.value)} label="Number of Hours" additionalClasses="column is-12 mb-6"/>
                    
                    {users.map((item) => {
                        return <HostItem hours={hours} key={item.id} item={item} additionalClasses="column is-3-desktop is-6" onClickHandler={() => onClickHandler(item)} showStatus={false}/>
                    })}
                    <div className="column is-3-desktop is-6 is-clickable" onClick={() => navigate("/overview", {state})}>
                        <div className="columns item with-background-primary has-text-centered is-vcentered" style={{height: 100 +"%", minHeight: 530 + "px"}}>
                            <div className="column"><span className="has-text-weight-bold has-text-white is-size-3">No Host</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const StepOverview = () => {
    const { currentUser } = useContext(UserContext);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [submit, setSubmit] = useState(false);
    const [modal, setModal] = useState("");
    const [contract, setContract] = useState(false);
    const [now, setNow] = useState(new Date());
    const [agree, setAgree] = useState(false);

    if (!state) {
        navigate("/reserve");
        return "";
    }

    const {partner: {businessName, address, photoUrl}, id, category: {title}, type, venue: {description, name: hallName, photoUrl: venuePhotoUrl, id: venueId, partnerId, rate: venueRate, pax: venuePax}, build, pax, fullInvoice, halfInvoice, finalInvoice} = state;
    let total = 0;
   
    const onClickHandler = async() => {
        if (currentUser) {
            if (id) {
                onBoook();
            } else {
                setContract(true);
            }
        } else {
            navigate("/signin", {state: {...state}});
        }
    }

    const onBoook = async() => {
        if (submit) {
            return;
        }
        setSubmit(true);

        if (fullInvoice) {
            const {data: [response, result]} = await expireInvoice({invoiceId: fullInvoice});
            if (response !== "success") {
                setSubmit(false);
                setModal(result.errorMessage);
            }
        }

        if (halfInvoice) {
            const {data: [response, result]} = await expireInvoice({invoiceId: halfInvoice});
            if (response !== "success") {
                setSubmit(false);
                setModal(result.errorMessage);
            }
        }

        if (finalInvoice) {
            const {data: [response, result]} = await expireInvoice({invoiceId: finalInvoice});
            if (response !== "success") {
                setSubmit(false);
                setModal(result.errorMessage);
            }
        }

        const title = currentUser.data.type === "partner" ? businessName : `${currentUser.data.firstName} ${currentUser.data.lastName.substring(0, 1)}.`;
        const data = {
            ...state,
            status: "Unpaid",
            partnerId,
            venueId,
            clientId: currentUser.uid,
            title,
            rate: total,
            guests: [],
            pax,
            start: build[0]["start"],
            end: build[build.length - 1]["end"],
            finalInvoice: null,
            halfInvoice: null,
            fullInvoice: null,
        };
        delete data.created;
        delete data.id;

        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(0, 0, 0);
        endDate.setHours(23, 59, 59);
        const [respo, resu] = await getCollectionDocs("Events", "created", "asc", [where("venueId", "==", venueId), where("status", "in", ["Pending", "Unpaid", "Partial", "Paid"]), startAt(startDate), endAt(endDate)]);
        if (respo !== "success") {
            setSubmit(false);
            setModal(resu.message);
        }
        const builds = [];
        for (const res of resu) {
            if (id === res.id) {
                continue;
            }

            builds.push(...res.build);
        }
        for (const build of builds) {
            const {start, end} = build;
            for (const b of data.build) {
                if (dateOverlaps(b.start, b.end, start.toDate(), end.toDate())) {
                    setSubmit(false);
                    setModal(`Sorry, someone booked an event first on ${format(start.toDate(), "MMMM dd, yyyy h:mma")} -  ${format(end.toDate(), "MMMM dd, yyyy h:mma")}`);
                    return;
                }
            }
        }
        
        if (id) {
            // UPDATE EVENT DOC
            const [response, result] = await updateCollectionDoc("Events", id, data);
            if (response === "success") {
                navigate(`/event/${id}`);
                return;
            }
        
            setSubmit(false);
            setModal(result.message);
            return;
        } else {
            // CREATE EVENT DOC
            const [response, result] = await createCollectionDoc("Events", data);
            if (response === "success") {
                navigate(`/event/${result.id}`);
                return;
            }
        
            setSubmit(false);
            setModal(result.message);
            return;
        }
    }

    return (
        <Fragment>
        {submit ? <Loading/> : ""}
        {contract ? <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title has-text-weight-bold">CONTRACT OF AGREEMENT</p>
                    <button className="delete is-medium" aria-label="close" onClick={() => setContract(false)}></button>
                </header>
                <section className="modal-card-body has-text-justified">
                    <p>This Agreement is made and entered into this <b>{format(now, "do")} day of {format(now, "MMMM yyyy")}</b>, by and between <b>ALM EVENTS AND TALENT MANAGEMENT</b> and <b>{currentUser.data.firstName} {currentUser.data.lastName}</b> at {currentUser.data.address ? <span className="is-capitalized">{currentUser.data.address}</span> : "N/A"}.</p><br/>
                    <p className="has-text-centered has-text-weight-bold is-size-4">ACKNOWLEDGEMENTS</p><br/>
                    <p style={{whiteSpace: "pre-line"}}>The parties agree that ALM EVENTS AND TALENTS MANAGEMENT will provide services as described in the attached proposal.<br/><br/>
WHEREAS, ALM EVENTS AND TALENTS MANAGEMENT as an independent contractor, and not as an employee, to perform certain services for the incoming event.<br/><br/>
NOW, THEREFORE, for and in consideration of the mutual covenants contained herein and for other good and valuable consideration, the receipt of which is hereby acknowledged, the ALM EVENTS AND TALENT MANAGEMENT do hereby agree as follows:<br/><br/>
<b>A. PAYMENT:</b><br/>
Payments made will reserve the services. The Services may not be reserved if the Client fails to make the payments. Clients may pay by e-wallet or credit card. If paying by credit card, the client must fill out a Credit Card Authorization Form.<br/><br/>
<b>B. DEPOSIT/CANCELLATION</b><br/>
A deposit of Partial Fifty Percent (50%) of the total proposal is due upon acceptance of the contract by the Client. The final Fifty Percent (50%) payment must be paid 7 days before the event bringing the total deposits to One Hundred Percent (100%) of the total fee. If the client fails to pay 7 days before the event the 50% of the partial payment will go to the client and the other 50% of the partial payment will go to the ALM Events and Talent Management.<br/><br/>
<b>C. DAMAGE:</b><br/>
The client agrees that if guests or other vendors (not associated with ALM Events and Talent Management) cause damage to any services provided by ALM Events and Talent Management, the Client will assume the responsibility of said damages.<br/><br/>
<b>D. PICTURES AND MARKETING:</b><br/>
The client agrees that ALM Events and Talent Management may take pictures of the services provided by ALM Events and Talent Management to be used for Marketing and/or Advertising without prior notice.<br/><br/>
<b>E. Corkage Fee</b><br/>
200 per bottle of wine<br/>
500 per liters of hard drinks<br/>
200 per case of beer <br/>
500 lechon<br/>
Extra putahe 200 <br/><br/>

<label className="checkbox">
  <input type="checkbox" className="mr-3" checked={agree} onChange={() => setAgree(!agree)}/>
  By Client of this agreement, Client expressly grants consent to ALM Events and Talent Management.
</label>
</p>
                </section>
                <footer className="modal-card-foot has-text-right is-block">
                    {agree ? <button className="button is-success" onClick={() => {setContract(false); onBoook()}}>I Agree</button> : <button className="button is-success" disabled>I Agree</button>}
                </footer>
            </div>
        </div> : ""}
        {modal !== "" ? <Modal modal={modal} setModal={setModal}/> : ""}
        <div className="px-5 columns is-vcentered is-centered is-multiline">
            <Reserve/>
            <div className="column is-9 is-desktop mt-6">
                <div className='columns is-multiline'>
                    <div className="column is-6 mt-5">
                        <label>Category</label>
                        <p className="is-size-4 has-text-weight-semibold">{title}</p>
                    </div>
                    <div className="column is-6 mt-5">
                        <label>Type</label>
                        <p className="is-size-4 has-text-weight-semibold">{type}</p>
                    </div>
                    
                    <div className="column is-6 mt-5">
                        <label>Pax</label>
                        <p className="is-size-4 has-text-weight-semibold">{pax}</p>
                    </div>
                    <div className="column is-6 mt-5">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <label>Venue</label>
                                    <p className="is-size-4 has-text-weight-semibold mb-0">{businessName}</p>
                                    <p className="is-size-5">{address}</p>
                                </div>
                            </div>
                            <div className="media-right">
                                <figure className="is-rounded image is-64x64">
                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/128x128.png"} alt="Image"/>
                                </figure>
                            </div>
                        </article>
                    </div>

                    <hr className="custom mx-3 my-6"/>
                    {build.sort((a, b) => a.start.getTime() - b.start.getTime()).map((b, index) => {
                    const {start, end, foodItems, talents, foodItemsTotal, hostTotal, hours, packageItem, packageTotal} = b;
                    const venueTotal = venueRate * hours;
                    let subtotal = foodItemsTotal + hostTotal + venueTotal;
                    if (packageTotal) {
                        subtotal = foodItemsTotal + hostTotal + packageTotal;
                    }
                    total += subtotal;

                    return (
                        <div key={`i_${index}`} className="columns card is-multiline py-3 px-4 mb-6">
                            <div className="column is-12 is-size-3 has-text-centered has-text-weight-bold pb-0 mt-5">
                                {format(start, "MMMM dd, yyyy")}
                            </div>
                            
                            <div className="column is-12 is-size-5 has-text-centered pt-0">
                                {format(start, "h:mma")} - {format(end, "h:mma")}
                            </div>

                            <div className="column is-12 is-size-5 pb-0 mt-6">
                                <div className="columns is-mobile">
                                    <div className="column is-6 has-text-weight-bold">
                                        EVENT HALL
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold">
                                        {packageTotal ? "" : `P ${rateFormatter(venueTotal)}`}
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12">
                                <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4">
                                    <div className="column is-1">
                                        <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                            <img src={venuePhotoUrl ? venuePhotoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                        </figure>
                                    </div>
                                    <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                        <p>{hallName}</p>
                                        <span className="tag">Max {venuePax} pax</span>
                                    </div>
                                    
                                    <div className="column is-2 has-text-right has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(venueRate)}`}</div>
                                    <div className="column is-3 has-text-right has-text-centered-mobile">{packageTotal ? "" : `×${hours} hours`}</div>
                                    <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">{packageTotal ? "" : `P ${rateFormatter(packageTotal ? 0 : venueTotal)}`}</div>
                                </div>
                            </div>

                            {packageItem != null ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            PACKAGE
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(packageTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-1">
                                            <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                <img src={packageItem.photoUrl ? packageItem.photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                            </figure>
                                        </div>
                                        <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                            <p>{packageItem.name}</p>
                                            <span className="tag">{packageItem.pax} pax</span>
                                        </div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.price)}</p>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×4 hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageItem.price)}</div>
                                    </div>
                                </div>
                                {hours > 4 ? <div className="column is-12">
                                    <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4 is-multiline">
                                        <div className="column is-4 has-text-weight-medium has-text-centered-mobile">
                                            <p>Additional Fee</p>
                                        </div>
                                        <div className="column is-2 has-text-right has-text-centered-mobile">
                                            <p>P {rateFormatter(packageItem.rate)}</p>
                                            <span className="tag" data-tooltip="Applies to events with total time exceeding 4 hours">
                                                per hour
                                                <FontAwesomeIcon size="xs" className="ml-1 fa-icon" icon={faInfo}/>
                                            </span>
                                        </div>
                                        <div className="column is-3 has-text-right has-text-centered-mobile">×{hours - 4} hours</div>
                                        <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(packageTotal - packageItem.price)}</div>
                                    </div>
                                </div> : ""}
                            </Fragment>: ""}
                            {foodItems.length > 0 ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            FOOD
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(foodItemsTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                {foodItems.map((item, i) => {
                                    const {name, quantity, price, photoUrl, pax, servingTime} = item;
                                    const subtotal = price * quantity;
                                    return (
                                        <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`food_${i}`}>
                                            <div className="column is-1">
                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                </figure>
                                            </div>
                                            <div className="column is-3 has-text-weight-medium has-text-centered-mobile">
                                                <p>{name}</p>
                                                <span className="tag">{pax} pax</span>
                                                <span className="tag ml-3">{servingTime}</span>
                                            </div>
                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(price)}</div>
                                            <div className="column is-3 has-text-right has-text-centered-mobile">×{quantity}</div>
                                            <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment> : ""}

                            {talents.length > 0 ? <Fragment>
                                <div className="column is-12 is-size-5 pb-0">
                                    <div className="columns is-mobile">
                                        <div className="column is-6 has-text-weight-bold">
                                            HOST
                                        </div>
                                        <div className="column is-6 has-text-right has-text-weight-bold">
                                            P {rateFormatter(hostTotal)}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                {talents.map((talent, index) => {
                                    const {name, photoUrl, rate, start, end, hours} = talent;
                                    const subtotal = rate * hours;
                                    return (
                                        <div className="card columns is-vcentered is-size-5 mb-5 py-3 px-4" key={`talent_${index}`}>
                                            <div className="column is-1">
                                                <figure className="image is-rounded is-64x64" style={{margin: "0 auto"}}>
                                                    <img src={photoUrl ? photoUrl : "https://bulma.io/images/placeholders/64x64.png"} alt="Image"/>
                                                </figure>
                                            </div>
                                            <div className="column is-3 has-text-weight-medium has-text-centered-mobile">{name}</div>
                                            <div className="column is-2 has-text-right has-text-centered-mobile">P {rateFormatter(rate)}</div>
                                            <div className="column is-3 has-text-right has-text-centered-mobile">{hours} hours<br/><span className="is-size-6">{formatTime(start)} - {formatTime(end)}</span></div>
                                            <div className="column is-3 has-text-right has-text-weight-medium has-text-centered-mobile">P {rateFormatter(subtotal)}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment> : ""}
                            

                            <div className="column is-12 is-size-4">
                                <div className="columns notification">
                                    <div className="column is-6 has-text-weight-bold has-text-centered-mobile">
                                        TOTAL
                                    </div>
                                    <div className="column is-6 has-text-right has-text-weight-bold has-text-centered-mobile">
                                        P {rateFormatter(subtotal)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                    <div className="column is-12 mt-5">
                        <span className="is-size-4 has-text-weight-bold">TOTAL...</span>
                        <span className="is-size-4 has-text-weight-bold is-pulled-right">Php {rateFormatter(total)}</span>
                    </div>

                    <Button onClick={onClickHandler} type="button" additionalClasses="is-medium with-background-primary has-text-white block is-fullwidth mx-3 my-6">
                        {id ? "Save Changes" : "Book Event"}
                    </Button>
                </div>
            </div>
        </div>
        </Fragment>
    )
}